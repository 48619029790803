import styled from '@emotion/styled';
import {
  Box,
  Grid,
  useMediaQuery as useMaterialUiMediaQuery,
} from '@mui/material';
import React from 'react';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { AuditReportView } from './audit-report-view';
import { AuditInputForm } from './audit-input-form';
import { AuditViewHeader } from './audit-view-header';
import DesktopAuditReportViewSkeleton from '../../assets/desktop-audit-report-view-skeleton.svg';
import MobileAuditReportViewSkeleton from '../../assets/mobile-audit-report-view-skeleton.svg';
import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';

interface AuditViewProps {
  handleAudit: (
    url: string,
    cb?: (url: string, issues: Issue[]) => void,
  ) => void;
  loading: boolean;
  report: ReportData;
  scanImageUrl: string;
}

export const AuditView = (props: AuditViewProps) => {
  const { handleAudit, loading, report, scanImageUrl } = props;
  const isMD = useMaterialUiMediaQuery((theme: any) =>
    theme.breakpoints.up('md'),
  );
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  const onAudit = async (url: string) => {
    await handleAudit(url);
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        wrap="nowrap"
        style={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Grid item sm={12} md style={{ width: '100%', overflow: 'hidden' }}>
          <LayoutMain>
            <AuditViewWrapper>
              <AuditInputForm
                onAudit={onAudit}
                label={'Url'}
                loading={loading}
              />
              <Box mt={isMD ? '32px' : '24px'} />
              <AuditViewHeader
                report={report}
                loading={loading}
                scanImageUrl={scanImageUrl}
              />
            </AuditViewWrapper>

            {loading && (
              <AuditReportViewSkeletonWrapper className="skeleton skeleton-text">
                <AuditReportViewSkeletonImg
                  src={
                    isMobile
                      ? MobileAuditReportViewSkeleton
                      : DesktopAuditReportViewSkeleton
                  }
                  alt=""
                />
              </AuditReportViewSkeletonWrapper>
            )}

            {!loading && report && (
              <AuditReportView
                auditReport={report}
                url={report.websiteUrl || ''}
              />
            )}
          </LayoutMain>
        </Grid>
      </Grid>
    </>
  );
};

const LayoutMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AuditViewWrapper = styled.div`
  background-color: #f5f7fa;
  border-radius: 16px;
  padding: 5vh 0;
  width: 100%;
  max-width: 85%;
  position: relative;
`;

const AuditReportViewSkeletonWrapper = styled.div`
  margin-top: 5vh;
  max-width: 85%;
`;

const AuditReportViewSkeletonImg = styled.img`
  width: 100%;
  height: auto;
`;
