import { Box } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import React, { useCallback } from 'react';
import { GenericInput } from '../../../components/accessibility-components/generic-input';
import { loginFormValidationSchema as validationSchema } from '../../../lib/validators';
import { useAuth } from '../../../providers/auth-context';
import { GoogleAuthButton } from './google-auth-button';
import {
  ActionButton,
  BottomSection,
  Heading,
  ORText,
  PageContent,
  StyledLink,
  Wrapper,
} from './styled';

const INITIAL_VALUE = {
  email: '',
  password: '',
};

type LoginFormValues = {
  email: string;
  password: string;
};

export const LoginForm = () => {
  const { loginWithEmailAndPassword } = useAuth();

  const onSubmit = useCallback(
    async (
      { email, password }: LoginFormValues,
      { setSubmitting }: FormikHelpers<LoginFormValues>,
    ) => {
      await loginWithEmailAndPassword(email, password);
      setSubmitting(false);
    },
    [loginWithEmailAndPassword],
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUE,
    validationSchema: validationSchema,
    onSubmit,
  });

  const disabled =
    formik.isSubmitting || (formik.submitCount > 0 && !formik.isValid);

  return (
    <Wrapper>
      <PageContent>
        <Heading>Login to your account</Heading>
        <Box mt="30px" />
        <GoogleAuthButton fullWidth label="Login with Google" />
        <ORText>OR</ORText>

        <form>
          <GenericInput
            required
            aria-required
            InputLabelProps={{ shrink: true }}
            id="email"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            ariaProps={{}}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email ? formik.errors.email : ''}
          />
          <Box mt="24px" />
          <GenericInput
            required
            aria-required
            InputLabelProps={{ shrink: true }}
            id="password"
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            fullWidth
            ariaProps={{}}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password ? formik.errors.password : ''}
          />
        </form>

        <Box mt="8px" />
        <StyledLink to="../reset-password">Forgot password?</StyledLink>

        <Box mt="38px" />

        <ActionButton fullWidth onClick={formik.submitForm} disabled={disabled}>
          Login with Email
        </ActionButton>
        <Box mt="18px" />
        <BottomSection>
          Don’t have an account?&nbsp;
          <StyledLink to="../signup">Create your account!</StyledLink>
        </BottomSection>
      </PageContent>
    </Wrapper>
  );
};
