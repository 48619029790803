import axios from 'axios';
import { ProductTypes, SubTypes } from '../redux/user-products-slice/types';
import { DevDomain, Domain, DomainSummary } from '../types/domains';
import { ApiResponse } from '../utils/axios';
import { HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';

class DomainsAPI {
  static async getDomains(
    businessId?: number,
  ): Promise<ApiResponse<DomainSummary[] | []>> {
    let result;
    let error;
    try {
      result = await axios.get(
        HTTP_CLIENT_ENDPOINT + `/api/v1/admin/a/${businessId}/domains`,
      );
    } catch (err) {
      console.error('Error getting domains', err);
      error = err;
    }
    const { data } = result || {};
    return {
      data: (data?.data || []) as DomainSummary[],
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async getUserDomains(): Promise<ApiResponse<Domain[] | []>> {
    let result;
    let error;
    try {
      result = await axios.get(
        HTTP_CLIENT_ENDPOINT + `/api/v1/admin/a/domains?page=undefined`,
      );
    } catch (err) {
      console.error('Error getting domains', err);
      error = err;
    }
    const { data } = result || {};
    return {
      data: (data?.message || []) as Domain[],
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async createDomains(
    domains: string[],
    businessId: number,
    type: ProductTypes,
    subType?: SubTypes,
    description?: string,
  ): Promise<ApiResponse<any>> {
    let result;
    let error;
    try {
      result = await axios.post(
        HTTP_CLIENT_ENDPOINT + `/api/v1/admin/a/${businessId}/domain`,
        {
          domains,
          type,
          subType,
          description,
        },
      );
    } catch (err) {
      console.error('Error while creating domains', err);
      error = 'Could not create domain, please contact customer support.';
    }
    const { data } = result || {};
    let resultData;

    if (!error) {
      if (type === 'widget') {
        resultData = (data?.message?.widget || []) as Domain[];
      } else if (type === 'developer') {
        resultData = data?.message?.developer || [];
      } else if (type === 'flowy') {
        resultData =
          {
            developer: data?.message?.developer || [],
            widget: data?.message?.widget || [],
          } || [];
      }
    }

    return {
      data: resultData,
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async getUserDevDomains(): Promise<ApiResponse<DevDomain[]>> {
    let result;
    let error;
    try {
      result = await axios.get(
        HTTP_CLIENT_ENDPOINT + '/api/v1/admin/a/devdomains',
      );
    } catch (err) {
      console.error('Error getting domains', err);
      error = 'Error getting domains';
    }
    const { data } = result || {};
    return {
      data: (data?.message || []) as DevDomain[],
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async getDevDomains(
    businessId?: number,
  ): Promise<ApiResponse<DevDomain[]>> {
    let result;
    let error;
    try {
      result = await axios.get(
        HTTP_CLIENT_ENDPOINT + `/api/v1/admin/a/${businessId}/devdomains`,
      );
    } catch (err) {
      console.error('Error getting domains', err);
      error = 'Error getting domains';
    }
    const { data } = result || {};
    return {
      data: (data?.message || []) as DevDomain[],
      isSuccess: data?.code === 200,
      error: error,
    };
  }
  static async updateDomain(
    businessId: number,
    domain: string,
    archiveDomain: boolean,
  ): Promise<ApiResponse<null>> {
    let result;
    let error;
    try {
      result = await axios.post(
        HTTP_CLIENT_ENDPOINT + `/api/v1/admin/a/${businessId}/domain/update`,
        {
          domain,
          archived: archiveDomain,
        },
      );
    } catch (err) {
      console.error('Error getting domains', err);
      error = 'Error updating domain';
    }
    const { data } = result || {};
    return {
      data: null,
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async getConfig(endpoint: string): Promise<ApiResponse<any>> {
    let result;
    let error;
    try {
      result = await axios.get(
        HTTP_CLIENT_ENDPOINT + '/api/v1/admin/a/' + endpoint,
      );
    } catch (e) {
      console.error('Error getting domains configs', e);
      error = e;
    }
    const { data, status } = result || {};

    return {
      data: (Array.isArray(data) ? data : []) as any,
      isSuccess: status === 200,
      error: error,
    };
  }

  static async saveConfig(
    endpoint: string,
    widgetConfig: any,
  ): Promise<ApiResponse<any>> {
    let result;
    let error;
    try {
      result = await axios.post(
        HTTP_CLIENT_ENDPOINT + '/api/v1/admin/a/' + endpoint,
        widgetConfig,
      );
    } catch (e) {
      console.error('Error getting domains configs', e);
      error = e;
    }
    // return get(data, "message");
    const { data } = result || {};
    return {
      data: widgetConfig,
      isSuccess: data?.success ?? false,
      error: error,
    };
  }

  static async getSampleCode(
    name: string,
    businessId: number,
  ): Promise<ApiResponse<any>> {
    let code = '';
    let err;

    try {
      const response = await axios.post(
        `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/domainkeys`,
        { domain: name },
      );
      let result = [];

      if (response && response.status === 200) {
        result = response.data.message;
      }

      if (result.length > 0) {
        const sampleCodeResponse = await axios.post(
          `${HTTP_CLIENT_ENDPOINT}/api/v1/admin/a/${businessId}/samplecode`,
          {
            domain: name,
            key: result[0].key,
          },
        );

        if (sampleCodeResponse && sampleCodeResponse.status === 200) {
          code = sampleCodeResponse.data.message;
        }
      }
    } catch (error) {
      err = error;
    }

    return {
      data: code,
      isSuccess: !err,
      error: err,
    };
  }

  static async getDomainAppearanceSettings(
    domain: string,
    businessId: number,
  ): Promise<ApiResponse<any>> {
    //   HTTP_CLIENT_ENDPOINT,
    // `/api/v1/admin/a/${value.businessId}/widget-site-settings/${value.domain}`,
    let result;
    let error;
    try {
      result = await axios.get(
        HTTP_CLIENT_ENDPOINT +
          `/api/v1/admin/a/${businessId}/widget-site-settings/${domain}`,
      );
    } catch (err) {
      console.error('Error getting appearance config', err);
      error = 'Error getting appearance config';
    }
    const { data } = result || {};
    return {
      data: data,
      isSuccess: data?.code === 200,
      error: error,
    };
  }

  static async updateDomainAppearanceSettings(
    businessId: number,
    domain: string,
    appearanceConfig: any,
  ): Promise<ApiResponse<any>> {
    let result;
    let error;
    try {
      result = await axios.post(
        HTTP_CLIENT_ENDPOINT +
          `/api/v1/admin/a/${businessId}/widget-site-settings/${domain}`,
        { ...appearanceConfig },
      );
    } catch (err) {
      console.error('Error updating appearance config', err);
      error = 'Error updating appearance config';
    }
    const { data } = result || {};
    return {
      data: data,
      isSuccess: data?.code === 200,
      error: error,
    };
  }
}

export default DomainsAPI;
