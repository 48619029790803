import React from 'react';
import styled from 'styled-components';
import RocketLaunch from '../../assets/rocket-launch.svg';
import CheckMark from '../../assets/check-blue.svg';
import { PrimaryButton } from '../styled-components';

interface InfoSectionItem {
  itemHeader: string;
  itemText: string;
}

const INFO_SECTION_LIST: InfoSectionItem[] = [
  {
    itemHeader: 'Fast',
    itemText: 'easy installation, 2 minutes, supports all platforms.',
  },
  {
    itemHeader: 'Routine',
    itemText: 'compliance checks and remediation, powered by AI.',
  },
  {
    itemHeader: 'Compliance',
    itemText: 'certifications and accessibility statement.',
  },
];

interface AddWidgetBannerProps {
  onStartFreeTrialClick: () => void;
}
export const AddWidgetBanner = (props: AddWidgetBannerProps) => {
  const { onStartFreeTrialClick } = props;

  return (
    <Wrapper>
      <Container>
        <Content>
          <ContentInner>
            <InfoSection>
              <IconWrapper>
                <Icon src={RocketLaunch} alt="rocket launch image icon" />
              </IconWrapper>
              <InfoSectionContent>
                <InfoSectionTitle>Not sure where to begin?</InfoSectionTitle>
                <InfoSectionList>
                  {INFO_SECTION_LIST.map(({ itemHeader, itemText }, idx) => (
                    <InfoSectionListItem key={idx}>
                      <InfoSectionListItemIcon src={CheckMark} alt="" />
                      <InfoSectionListItemText>
                        <InfoSectionListItemTextHeading>
                          {itemHeader}
                        </InfoSectionListItemTextHeading>
                        <InfoSectionListItemTextContent>
                          {itemText}
                        </InfoSectionListItemTextContent>
                      </InfoSectionListItemText>
                    </InfoSectionListItem>
                  ))}
                </InfoSectionList>
                <InfoTextMobile>
                  Install our widget—it's fast, automated, and provides an
                  accessibility statement.
                </InfoTextMobile>
              </InfoSectionContent>
            </InfoSection>
            <ActionSection>
              <Button
                color="primary"
                variant="contained"
                onClick={onStartFreeTrialClick}
              >
                Start for free
              </Button>
              <ActionText>No credit card required.</ActionText>
            </ActionSection>
          </ContentInner>
        </Content>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #000a14;
  position: fixed;
  bottom: 0;
  width: 100%;

  @media (pointer: none), (pointer: coarse) {
    border-radius: 4px;
  }
`;

const Container = styled.div`
  position: relative;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }

  @media (pointer: none), (pointer: coarse) {
    padding: 13px 0;
  }
`;

const IconWrapper = styled.div`
  margin-right: 20px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const Icon = styled.img``;

const Content = styled.div`
  padding: 20px 35px;
  width: 100%;
  max-width: 80%;
  margin: auto;

  @media screen and (max-width: 1200px) and (min-width: 960px) {
    padding: 20px 0;
    margin: 0 100px;
  }

  @media (pointer: none), (pointer: coarse) {
    padding: 0 13px;
    max-width: 100%;
  }
`;

const ContentInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoSection = styled.div`
  display: flex;
  align-items: center;

  @media (pointer: none), (pointer: coarse) {
    width: 50%;
  }
`;

const InfoSectionContent = styled.div``;

const InfoSectionTitle = styled.h4`
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;

  @media (pointer: none), (pointer: coarse) {
    font-size: 14px;
    margin-bottom: 3px;
  }
`;

const InfoSectionList = styled.ul`
  list-style: none;

  @media (pointer: none), (pointer: coarse) {
    display: none;
  }
`;

const InfoSectionListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoSectionListItemIcon = styled.img`
  margin-right: 6px;
`;

const InfoSectionListItemText = styled.span`
  font-size: 16px;
  font-weight: 300;
`;

const InfoTextMobile = styled(InfoSectionListItemText)`
  display: none;

  @media (pointer: none), (pointer: coarse) {
    display: block;
    font-size: 12px;
  }
`;

const InfoSectionListItemTextHeading = styled.span`
  font-weight: 500;
  margin-right: 5px;
`;

const InfoSectionListItemTextContent = styled.span``;

const ActionSection = styled.div`
  margin-top: 10px;
`;

const Button = styled(PrimaryButton)`
  width: 100%;
  border-radius: 12px;
  margin-bottom: 10px;

  @media (pointer: none), (pointer: coarse) {
    margin-bottom: 0;
    height: 32px;
    padding: 0 10px;
    font-weight: 400;
    font-size: 14px;
  }
`;

const ActionText = styled.p`
  font-weight: 300;
  font-size: 16px;

  @media (pointer: none), (pointer: coarse) {
    display: none;
  }
`;
