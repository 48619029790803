import axios from 'axios';
import { GRAPH_QL_API_KEY, GRAPH_QL_ENDPOINT } from './magicValues';
import { getAccessToken } from './jwt';

interface ExecuteFunction {
  functionName: string;
  query: string;
  payload: object;
}

interface GraphqlResponse {
  data: object;
  isSuccess: boolean;
  message: string;
}
const execute = async ({
  functionName,
  query,
  payload,
}: ExecuteFunction): Promise<GraphqlResponse> => {
  let result;

  try {
    result = await axios.post(
      GRAPH_QL_ENDPOINT,
      {
        query,
        variables: {
          payload,
        },
      },
      {
        headers: {
          'x-api-key': GRAPH_QL_API_KEY,
          'equally-auth-provider': 'default',
          'equally-client-id':
            process.env.REACT_APP_FIREBASE_CONFIG_APP_ID ||
            process.env.REACT_APP_FIREBASE_APP_ID,
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
  } catch (err) {
    console.error('Error executing graphql function', err);
  }

  const response = result?.data?.data?.[functionName] || {};
  const { statusCode, message } = response;
  const isSuccess = statusCode === 200;

  return {
    data: JSON.parse(response?.data || '{}'),
    isSuccess,
    message,
  };
};
const graphqlClient = {
  execute,
};
export default graphqlClient;
