import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

export enum NotificationType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}
interface NotificationProps {
  message: string;
  type: NotificationType;
}

const NOTIFICATION_COLORS = {
  error: 'rgba(252,177,177,0.5)',
  success: 'rgba(182,236,216,0.5)',
  warning: 'rgba(182,236,216,0.5)',
};

const NOTIFICATION_ICONS = {
  error: <ErrorOutlineIcon />,
  success: <CheckCircleOutlineIcon />,
  warning: <WarningIcon />,
};

export const Notification = ({ message, type }: NotificationProps) => {
  return (
    <div
      className="modal-panel-notification"
      style={{ backgroundColor: `${NOTIFICATION_COLORS[type]}` }}
    >
      <div
        className="modal-panel-notification-icon"
        style={{ background: 'transparent' }}
      >
        {NOTIFICATION_ICONS[type]}
      </div>
      <div className="modal-panel-notification-text">{message}</div>
    </div>
  );
};
