import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import {
  ActionButton,
  StyledLink,
  Wrapper,
  PageContent,
  Heading,
} from './styled';

import { GenericInput } from '../../../components/accessibility-components/generic-input';
import { resetPassowordFormValidationSchema } from '../../../lib/validators';
import { useAuth } from '../../../providers/auth-context';

const INITIAL_VALUE = {
  email: '',
};

type ResetPasswordFormValues = {
  email: string;
};

export const ResetPassword = () => {
  const { resetPassword } = useAuth();
  const onSubmit = useCallback(
    async (
      values: ResetPasswordFormValues,
      { setSubmitting, resetForm }: FormikHelpers<ResetPasswordFormValues>,
    ) => {
      await resetPassword(values.email);
      setSubmitting(false);
      resetForm();
    },
    [resetPassword],
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUE,
    validationSchema: resetPassowordFormValidationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Wrapper>
      <PageContent>
        <Heading>Reset Your Password</Heading>
        <p>If you signed up with Google, you can&#39;t reset your password.</p>

        <Box mt="50px" />
        <form>
          <GenericInput
            required
            aria-required
            InputLabelProps={{ shrink: true }}
            ariaProps={{}}
            id="resetPassword"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email ? formik.errors.email : undefined}
          />
        </form>
        <Box mt="10px" />
        <StyledLink to="/login">Back to Login</StyledLink>
        <Box mt="50px" />
        <ActionButton
          aria-label="Reset Password"
          onClick={formik.submitForm}
          disabled={formik.isSubmitting}
          fullWidth
        >
          Reset Password
        </ActionButton>
      </PageContent>
    </Wrapper>
  );
};

export default ResetPassword;
