import React from 'react';
import styled from 'styled-components';
import { TypographyH5 } from '../../../components/common/styled';
import SettingsIcon from '@material-ui/icons/Settings';
import ExtensionIcon from '@material-ui/icons/Extension';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';

interface FlowyKnowledgeBaseProps {
  title: string;
  text: string;
  icon: JSX.Element;
  link: string;
}

const FLOWY_KNOWLEDGE_BASE: FlowyKnowledgeBaseProps[] = [
  {
    title: 'Monitor your website',
    text: 'Learn how to monitor your website with Flowy.',
    icon: <SettingsIcon style={{ width: '15px', height: '15px' }} />,
    link: 'https://docs.equally.ai/flowy/use-cases/training#flowy-monitor',
  },
  {
    title: 'Our Chrome extension',
    text: 'Start using our Chrome extension to fix issues and create manual audit.',
    icon: <ExtensionIcon style={{ width: '15px', height: '15px' }} />,
    link: 'https://docs.equally.ai/flowy/use-cases/training#flowy-audit',
  },
];

export const FlowyKnowledgeBase = () => {
  const { track } = useAnalytics();
  const handleCardClick = (link: string) => {
    track('Flowy Knowledge Card Clicked', { link });
    window.open(link);
  };
  return (
    <Wrapper>
      <Container>
        <HeaderWrapper>
          <Title>Knowledge base</Title>
        </HeaderWrapper>
        <ContentWrapper>
          {FLOWY_KNOWLEDGE_BASE.map((cardDetails, index) => {
            const { title, text, icon, link } = cardDetails;

            return (
              <FlowyKnowledgeBaseCard
                key={`knowledge-base-card-${index}`}
                onClick={() => handleCardClick(link)}
              >
                <FlowyKnowledgeBaseCardHeader>
                  <FlowyKnowledgeBaseCardIconWrapper>
                    {/* <FlowyKnowledgeBaseCardIcon src="/bucket.svg" alt="" /> */}
                    {icon}
                  </FlowyKnowledgeBaseCardIconWrapper>
                </FlowyKnowledgeBaseCardHeader>
                <FlowyKnowledgeBaseCardContent>
                  <Title>{title}</Title>
                  <Text>{text}</Text>
                </FlowyKnowledgeBaseCardContent>
              </FlowyKnowledgeBaseCard>
            );
          })}
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 7vh 0 3vh 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 510px;
`;

const HeaderWrapper = styled.div`
  margin: 10px 0 10px 15px;
`;

const Title = styled(TypographyH5)`
  font-weight: 500;
  font-size: 13px;
  color: rgba(0, 10, 20, 1);
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
  }
`;

const FlowyKnowledgeBaseCard = styled.div`
  border: 1.5px solid #f1f1f1;
  border-radius: 13px;
  width: 250px;
  padding: 13px;
  cursor: pointer;

  @media (pointer: none), (pointer: coarse) {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const FlowyKnowledgeBaseCardHeader = styled.div`
  margin-bottom: 15px;
`;

const FlowyKnowledgeBaseCardIconWrapper = styled.div`
  display: flex;
  background-color: #f0f0f0;
  width: fit-content;
  padding: 10px;
  border-radius: 50%;
`;

const FlowyKnowledgeBaseCardIcon = styled.img``;

const FlowyKnowledgeBaseCardContent = styled.div`
  margin-top: 4px;
`;

const Text = styled.p`
  margin: 0;
  font-size: 12px;
  margin-top: 8px;
  color: #7b7b7b;
`;
