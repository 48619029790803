import {
  AppBar,
  Button,
  CircularProgress,
  Container,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { verifyCompliance } from '../../store/actions';
import { ComplianceCard } from './ComplianceCard';
import { TabPanel } from './TabPanel';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(2)}px 0`,
  },
  form: {
    marginBottom: theme.spacing(2),
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  button: {
    color: 'white',
  },
  loading: {
    marginTop: theme.spacing(4),
  },
}));

export const Compliance = () => {
  const classes = useStyles();
  const [urlForSearch, setUrlForSearch] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const compliance = useSelector((state) => state.compliance.compliance);
  const loading = useSelector((state) => state.compliance.loading);

  const dispatch = useDispatch();

  const onGetErrorsClick = async () => {
    dispatch(verifyCompliance(urlForSearch));
  };
  const errors = compliance.filter((c) => c.type === 'error');
  const warnings = compliance.filter((c) => c.type === 'warning');
  return (
    <Container className={classes.container}>
      <Grid container alignItems="center" className={classes.form}>
        <TextField
          size="small"
          label="website url"
          variant="outlined"
          value={urlForSearch}
          onChange={(e) => setUrlForSearch(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onGetErrorsClick}
        >
          VERIFY
        </Button>
        {loading && <CircularProgress />}
      </Grid>
      {!!compliance.length && (
        <AppBar position="static" color="default">
          <Tabs
            indicatorColor="primary"
            value={activeTab}
            onChange={(_, value) => setActiveTab(value)}
            aria-label="simple tabs example"
          >
            <Tab
              label={
                <span style={{ color: '#c33131' }}>
                  {`Errors (${errors.length})`}
                </span>
              }
            />
            <Tab
              label={
                <span style={{ color: 'orange' }}>
                  {`Warnings (${warnings.length})`}
                </span>
              }
            />
          </Tabs>
        </AppBar>
      )}
      <TabPanel value={activeTab} index={0}>
        {errors.map((el, index) => (
          <ComplianceCard
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            code={el.code}
            message={el.message}
            context={el.context}
            color="#c33131"
          />
        ))}
        {!!compliance.length && !errors.length && (
          <Typography variant="body1" component="p">
            No errors found
          </Typography>
        )}
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        {warnings.map((el, index) => (
          <ComplianceCard
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            code={el.code}
            message={el.message}
            context={el.context}
            color="orange"
          />
        ))}
        {!!compliance.length && !warnings.length && (
          <Typography variant="body1" component="p">
            No warnings found
          </Typography>
        )}
      </TabPanel>
    </Container>
  );
};
