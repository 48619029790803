import { selectDismissedIssues } from '@equally-ai-front/common/src/redux/developer-slice/selector';
import {
  IssueSeverity,
  IssueStatusEnum,
  IssueStatuses,
} from '@equally-ai-front/common/src/types/issues';
import { getIssuesCount } from '@equally-ai-front/common/src/utils';
import { IssueType } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {
  setIssueSeverityFilter,
  setIssueStatuses,
  setReportType,
} from '../../../store/dashboard-slice/dashboard';
import {
  DashboardFilterStatus,
  DashboardReportType,
} from '../../../store/dashboard-slice/types';
import { FilterOption, FilterSelect } from './filter-select';

const severityOptions: FilterOption[] = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'basic',
    label: 'Critical',
  },
  {
    value: 'advanced',
    label: 'Potential',
  },
  {
    value: 'potential',
    label: 'Best practice',
  },
];

const groupByOptions: FilterOption[] = [
  {
    value: 'html',
    label: 'HTML Elements',
  },
  {
    value: 'wcag',
    label: 'WCAG',
  },
  {
    value: 'recommendation',
    label: 'Recommendations',
  },
];
const statusesOptions: FilterOption[] = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'dismissed',
    label: 'Dismissed',
  },
];

interface FiltersProps {
  activeFilter: DashboardFilterStatus;
  severity: IssueSeverity | 'all';
  reportType: DashboardReportType;
  disableFilterSelect: boolean;
  currentIssueStatus: IssueStatuses;
}

export const Filters = (props: FiltersProps) => {
  const { currentIssueStatus, severity, reportType, disableFilterSelect } =
    props;

  const dispatch = useDispatch();
  const selectActiveFilter = (filterType: DashboardFilterStatus): void => {
    dispatch(setIssueStatuses(filterType));
  };
  const handleSeverityChange = (filterType: IssueType): void => {
    dispatch(setIssueSeverityFilter(filterType));
  };
  const handleReportTypeChange = (newReportType: DashboardReportType): void => {
    dispatch(setIssueStatuses('all'));
    dispatch(setIssueSeverityFilter('all'));
    dispatch(setReportType(newReportType));
  };

  return (
    <Wrapper>
      <FilterSelectDiv>
        {reportType === 'html' && (
          <FilterSelect
            id="issue-statuses"
            options={statusesOptions}
            value={currentIssueStatus}
            setValue={selectActiveFilter}
            formInputLabel="Status"
            marginRight="15px"
            disabled={false}
          />
        )}
        {reportType === 'html' && (
          <FilterSelect
            id="severity"
            options={severityOptions}
            value={severity}
            setValue={handleSeverityChange}
            formInputLabel="Severity"
            marginRight="15px"
            disabled={disableFilterSelect || reportType !== 'html'}
          />
        )}
        <FilterSelect
          id="groupBy"
          options={groupByOptions}
          value={reportType}
          setValue={handleReportTypeChange}
          formInputLabel="Group By"
          marginRight="0"
          disabled={disableFilterSelect}
        />
      </FilterSelectDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.5px solid #e4e7eb;
  padding: 7px 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    align-items: center;
  }
`;

const FilterSelectDiv = styled.div`
  @media screen and (max-width: 768px) {
    margin-top: 4%;
    display: flex;
    flex-direction: column;

    > div {
      margin: 0 0 10px 0 !important;
    }
  }
`;
