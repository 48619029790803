import { useMemo, useState } from 'react';

export enum OrderOptionsEnum {
  ASC = 'asc',
  DESC = 'desc',
}
export type SortOption = Record<string, OrderOptionsEnum>;

interface UsePaginationResult<T> {
  pageCount: number;
  currentPage: number;
  currentData: T[];
  searchTerm: string;
  sortOptions: SortOption;
  handlePageChange: (selectedPage: { selected: number }) => void;
  handleSearch: (query: string) => void;
  handleSort: (key: string) => void;
}

export const usePagination = <T, K extends keyof T>(
  data: T[],
  PAGE_LIMIT: number,
  searchPropertyKey?: K,
  sortFields?: SortOption,
): UsePaginationResult<T> => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortOptions, setSortOptions] = useState<SortOption>(sortFields || {});

  const offset = currentPage * PAGE_LIMIT;

  const filteredData = useMemo(() => {
    const filtered =
      searchPropertyKey && searchTerm
        ? data.filter((item) => {
            const itemValue = item[searchPropertyKey] as string;
            return itemValue.toLowerCase().includes(searchTerm.toLowerCase());
          })
        : data;
    return filtered;
  }, [data, searchPropertyKey, searchTerm]);

  const pageCount = Math.ceil(filteredData.length / PAGE_LIMIT);

  const currentData = useMemo(() => {
    const sortedData = [...filteredData];

    for (const key in sortOptions) {
      if (sortOptions.hasOwnProperty(key) && sortOptions[key]) {
        sortedData.sort((a, b) => {
          const valueA = (a as Record<string, string>)[key];
          const valueB = (b as Record<string, string>)[key];

          if (typeof valueA === 'number' && typeof valueB === 'number') {
            if (sortOptions[key] === OrderOptionsEnum.ASC) {
              return valueA - valueB;
            } else {
              return valueB - valueA;
            }
          }

          if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
            if (sortOptions[key] === OrderOptionsEnum.ASC) {
              return valueA === valueB ? 0 : valueA ? 1 : -1;
            } else {
              return valueA === valueB ? 0 : valueA ? -1 : 1;
            }
          }

          if (sortOptions[key] === OrderOptionsEnum.ASC) {
            return valueA.localeCompare(valueB);
          } else {
            return valueB.localeCompare(valueA);
          }
        });
      }
    }
    return sortedData.slice(offset, offset + PAGE_LIMIT);
  }, [filteredData, offset, sortOptions]);

  const moveToTop = () => {
    window.scrollTo(0, 0);
  };

  const handlePageChange = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
    moveToTop();
  };

  const handleSearch = (query: string) => {
    setSearchTerm(query);
    setCurrentPage(0);
  };

  const handleSort = (key: string) => {
    setSortOptions((prevSortOptions) => {
      const currentSortOrder = prevSortOptions[key]
        ? prevSortOptions[key]
        : 'asc';
      const newSortOptions: SortOption = {
        [key]:
          currentSortOrder === OrderOptionsEnum.ASC || !currentSortOrder
            ? OrderOptionsEnum.DESC
            : OrderOptionsEnum.ASC,
      };
      return newSortOptions;
    });
  };

  return {
    pageCount,
    currentData,
    currentPage,
    searchTerm,
    sortOptions,
    handleSearch,
    handlePageChange,
    handleSort,
  };
};
