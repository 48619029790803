import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from 'formik-material-ui';
import React, { useEffect } from 'react';

import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getBillingProfile, updateBillingProfile } from '../store/actions';

const useStyles = makeStyles(() => ({
  billingAddress: {
    border: '1px solid #E6E7E8',
    borderRadius: '8px',
    width: '100%',
    marginRight: '27px',
  },
  creditCardDetails: {
    border: '1px solid #E6E7E8',
    borderRadius: '8px',
    width: '100%',
  },
  formElement: {
    paddingBottom: '30px',
  },
  formElementContainer: {
    paddingBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  warningElement: {
    marginTop: '30px',
    padding: '15px 18px',
    color: '#FFFFFF',
    fontFamily: 'Muli',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '17px',
    backgroundColor: '#FFA000',
    boxShadow: '0 1px 6px 1px rgba(0,0,0,0.2)',
    borderRadius: '5px',
    display: 'flex',
  },
}));

export const BillingInfo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBillingProfile());
  }, [dispatch]);

  const billingProfile = useSelector(
    (state) => state.billingProfile.billingProfile,
  );

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      dispatch(updateBillingProfile(values));
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <div className="billing-info-container">
      <Typography className={classes.billingAddress} component="div">
        <Box p={5}>
          <div className="my-plan-tab-panel-header-text">Billing Address</div>
          <Formik
            initialValues={billingProfile}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ submitForm }) => {
              return (
                <>
                  <Field
                    className={classes.formElement}
                    component={TextField}
                    required
                    fullWidth
                    name="full_name"
                    label="Full Name or Company Name"
                    variant="outlined"
                  />
                  <Field
                    className={classes.formElement}
                    fullWidth
                    component={TextField}
                    name="street_address"
                    required
                    label="Street Address"
                    variant="outlined"
                  />
                  <div className={classes.formElementContainer}>
                    <Field
                      required
                      name="city"
                      component={TextField}
                      style={{ width: '65%' }}
                      label="City"
                      variant="outlined"
                    />
                    <Field
                      required
                      component={TextField}
                      style={{ width: '30%' }}
                      label="ZIP"
                      name="zip"
                      variant="outlined"
                    />
                  </div>
                  <Field
                    className={classes.formElement}
                    required
                    fullWidth
                    name="country"
                    component={TextField}
                    label="Country"
                    variant="outlined"
                  />
                  <Field
                    className={classes.formElement}
                    fullWidth
                    name="vat_number"
                    component={TextField}
                    label="VAT Number (if you have one)"
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    style={{ width: '182px', height: '54px' }}
                  >
                    Save Changes
                  </Button>
                </>
              );
            }}
          </Formik>
        </Box>
      </Typography>
    </div>
  );
};
