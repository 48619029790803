import {
  AccessibilityAccordion,
  AccordionState,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { AccessibilityIssueDetails } from '@equally-ai-front/common/src/components/accessiblity-issue-details/AccessibilityIssueDetails';
import { groupAccessibilityIssuesByTitle } from '@equally-ai-front/common/src/lib';
import { GetSavedGptSuggestionsArgType } from '@equally-ai-front/common/src/redux/developer-slice/types';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { getAssetReactAppUrl } from '@equally-ai-front/common/src/utils';
import { Divider } from './scan-report-section-item';
import {
  DevtoolsSolution,
  IssueType,
  ReportData,
} from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { Paper } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  ScanReportSectionItem,
  ScanReportItemDetailsProps,
} from './scan-report-section-item';

interface ScanReportSectionProps {
  reportSectionTitle: string;
  scanItems: DevtoolsSolution[];
  activeItemId: string;
  devDomain?: DevDomain;
  wcagReport: ReportData;
  issueType: IssueType;
  toggleSectionItem: (
    id: string,
    payloadToGetSavedGptSuggestion: GetSavedGptSuggestionsArgType,
  ) => void;
  accordionState: AccordionState;
  handleAccordionToggle: (accordionId: string) => void;
  accordionId: string;
  scanReportItemDetails?: React.ComponentType<ScanReportItemDetailsProps>;
  setSuccessMessage: (message: string) => void;
  hideHowToFix?: boolean;
  selectedIssuesId: Record<number, boolean>;
  setSelectedIssuesId: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
}

export const ScanReportSection = ({
  reportSectionTitle,
  scanItems,
  devDomain,
  wcagReport,
  issueType,
  toggleSectionItem,
  activeItemId,
  accordionState,
  handleAccordionToggle,
  accordionId,
  scanReportItemDetails,
  setSuccessMessage,
  hideHowToFix,
  selectedIssuesId,
  setSelectedIssuesId,
}: ScanReportSectionProps) => {
  // const calculateImpactLevelColors = (
  //   impactLevel: string
  // ): ImpactLevelColors => {
  //   let impactLevelBg: string;
  //   let impactLevelColor: string;

  //   if (impactLevel === "high") {
  //     impactLevelBg = StyleConstants.COLOR_ERROR_1;
  //     impactLevelColor = StyleConstants.COLOR_ERROR_2;
  //   } else if (impactLevel === "warning") {
  //     impactLevelBg = StyleConstants.COLOR_WARNING_1;
  //     impactLevelColor = StyleConstants.COLOR_WARNING_2;
  //   } else {
  //     impactLevelBg = StyleConstants.COLOR_SUCCESS_1;
  //     impactLevelColor = StyleConstants.COLOR_SUCCESS_2;
  //   }

  //   return {
  //     bgColor: impactLevelBg,
  //     color: impactLevelColor,
  //   };
  // };

  const handleToggleAccordion = () => {
    handleAccordionToggle(accordionId);
  };

  const issuesGroupedByTitle = useMemo(
    () => groupAccessibilityIssuesByTitle(scanItems),
    [scanItems],
  );

  return (
    <Container variant="outlined">
      <AccessibilityAccordion
        title={reportSectionTitle}
        accordionId={accordionId}
        accordionState={accordionState}
        handleToggleAccordion={handleToggleAccordion}
        accordionHeader={
          <>
            <ArrowBox>
              <ArrowIcon
                src={getAssetReactAppUrl('/chevron-down-black.svg')}
                alt="arrow down icon"
                shouldRotate={accordionState[accordionId]}
              />
            </ArrowBox>
            <HeaderTextDiv>
              <HeaderText>{reportSectionTitle}</HeaderText>
            </HeaderTextDiv>
          </>
        }
      >
        <Divider />
        {Object.entries(issuesGroupedByTitle).map(
          ([groupTitle, issues], index) => {
            const {
              issueDescription,
              message,
              howToFixTheIssue,
              relatedWCAGLink,
            } = issues[0];
            const handleToggleGroupTitleAccordion = () => {
              handleAccordionToggle(groupTitle);
            };

            return (
              <div key={index}>
                <AccessibilityAccordion
                  title={groupTitle}
                  accordionId={groupTitle}
                  accordionState={accordionState}
                  handleToggleAccordion={handleToggleGroupTitleAccordion}
                  accordionHeader={
                    <HeaderWrapper>
                      <TitleAndIcon>
                        <ArrowBox>
                          <ArrowIcon
                            src="/chevron-down-black.svg"
                            alt="arrow down icon"
                            shouldRotate={accordionState[groupTitle]}
                          />
                        </ArrowBox>
                        <HeaderTitle>{groupTitle}</HeaderTitle>
                      </TitleAndIcon>
                    </HeaderWrapper>
                  }
                >
                  <Divider />
                  <AccessibilityIssueDetails
                    issueDescriptionId={index.toString()}
                    issueDescriptionContent={
                      <>
                        <IssueDescriptionDiv>
                          <IssueDescription>
                            <BodyContent>{issueDescription}</BodyContent>
                          </IssueDescription>
                        </IssueDescriptionDiv>
                      </>
                    }
                    howToFixContent={
                      <IssueDetailsDiv>
                        <HowToFix>
                          <ul>
                            {message !== howToFixTheIssue[0] && (
                              <li>
                                <BodyContent>{message}</BodyContent>
                              </li>
                            )}
                            {howToFixTheIssue.length > 0 &&
                              howToFixTheIssue.map((fix, howToFixIdx) => {
                                const howToFixKey = `${fix}-${howToFixIdx}`;
                                return (
                                  <li key={howToFixKey}>
                                    <BodyContent>{fix}</BodyContent>
                                  </li>
                                );
                              })}
                          </ul>
                          <WcagRule href={relatedWCAGLink} target="_blank">
                            {relatedWCAGLink}
                          </WcagRule>
                        </HowToFix>
                      </IssueDetailsDiv>
                    }
                    hideHowToFix={hideHowToFix}
                  />
                  <RelatedIssues>
                    <IssuesTitle>Elements</IssuesTitle>
                    <IssueWrapper>
                      {issues.map((item, idx) => (
                        <ScanReportSectionItem
                          key={idx}
                          title={item.selector ? item.selector : item.title}
                          accordionId={item.id.toString()}
                          accordionState={accordionState}
                          handleAccordionToggle={handleAccordionToggle}
                          level={item.level}
                          sectionItemId={item.id.toString()}
                          activeItemId={activeItemId}
                          sectionItem={item}
                          identifier={idx === 0 ? 'firstIssue' : idx.toString()}
                          wcagReport={wcagReport}
                          issueIndex={idx}
                          issueType={issueType}
                          devDomain={devDomain}
                          toggleSectionItem={toggleSectionItem}
                          scanReportItemDetails={scanReportItemDetails}
                          setSuccessMessage={setSuccessMessage}
                          selectedIssuesId={selectedIssuesId}
                          setSelectedIssuesId={setSelectedIssuesId}
                        />
                      ))}
                    </IssueWrapper>
                  </RelatedIssues>
                </AccessibilityAccordion>
                {Object.entries(issuesGroupedByTitle).length - 1 > index && (
                  <Divider />
                )}
              </div>
            );
          },
        )}
      </AccessibilityAccordion>
    </Container>
  );
};

const Container = styled(Paper)`
  border: 2px solid #e4e7eb;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 20px 20px;

  @media (pointer: none), (pointer: coarse) {
    margin: 0 0 20px;
  }
`;

const HeaderTextDiv = styled.div`
  width: calc(100% - 44px);
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

const HeaderText = styled.span`
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
  text-align: left;
`;

const HeaderTitle = styled(HeaderText)`
  font-size: 14px;
  margin-left: 12px;
  max-width: calc(100% - 44px);
  width: 100%;
`;

export const ArrowBox = styled.div`
  border: 2px solid #e4e7eb;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ArrowIconProps {
  shouldRotate: boolean;
}

export const ArrowIcon = styled.img<ArrowIconProps>`
  width: 80%;
  transform: ${(props) => (props.shouldRotate ? 'rotate(180deg)' : 'none')};
  transition: transform 100ms;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleAndIcon = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const IssueDescriptionDiv = styled.div`
  margin-bottom: 3vh;

  @media screen and (max-width: 650px) {
    margin-bottom: 4vh;
  }
`;

const IssueDescription = styled.div``;

const BodyContent = styled.div`
  font-size: 13px;
  color: rgba(0, 10, 20, 0.85);
`;

const HowToFix = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 5vh;

  @media screen and (max-width: 650px) {
    margin-left: 0;
    margin-top: 2vh;
  }
`;

const WcagRule = styled.a`
  font-weight: 500;
  color: rgba(0, 10, 20, 0.85);
  font-size: 12px;
  text-decoration: none;
  border-bottom: 2px solid #c2f4d7;
  cursor: pointer;
  margin-top: 20px;
  width: fit-content;

  @media screen and (max-width: 650px) {
    margin-top: 5vh;
    word-wrap: break-word;
    width: 100%;
  }
`;

const IssueDetailsDiv = styled.div``;

const IssueWrapper = MuiStyled(Paper)(() => ({
  [`&.MuiPaper-root`]: {
    boxSizing: 'border-box',
    borderRadius: 12,
    overflow: 'hidden',
    margin: '10px 0 30px 0',
  },
  '& > div': {
    borderBottom: '1px solid #e4e7eb',
  },
}));

const IssuesTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  padding-top: 16px;
`;

const RelatedIssues = styled.div`
  padding: 0 32px;
`;
