import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as GoogleIcon } from '../../../img/google.svg';
import { useAuth } from '../../../providers/auth-context';
import { setApiError } from '../../../store/actions';
import { AuthUtils } from '../../../utils/auth.utils';

interface GoogleAuthButtonProps {
  fullWidth?: boolean;
  label: string;
}

export const GoogleAuthButton = ({
  fullWidth,
  label,
}: GoogleAuthButtonProps) => {
  const { loginWithGoogle } = useAuth();
  const dispatch = useDispatch();

  const handleClick = async () => {
    try {
      await loginWithGoogle();
    } catch (error) {
      dispatch(setApiError(AuthUtils.getErrorMessage(error?.code || '')));
    }
  };
  return (
    <LoginButton fullWidth={fullWidth} onClick={handleClick}>
      <GoogleIcon />
      <Label>{label}</Label>
    </LoginButton>
  );
};

const Label = styled.span`
  flex-grow: 1;
  text-align: center;
`;

const LoginButton = styled(Button)`
  white-space: nowrap;
  text-transform: initial;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  margin: 0 !important;
  box-shadow:
    0px 5px 10px rgba(0, 18, 38, 0.08),
    0px 3px 6px rgba(0, 18, 38, 0.04);
  border-radius: 12px;
  padding: 11px 21px;
  height: auto;

  & > div > div:last-of-type {
    text-align: center !important;
    margin-right: ${({ fullWidth }) => (fullWidth ? '26px' : 'auto')};
  }

  &:hover {
    cursor: pointer;
  }

  /* Font */
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;

  @media screen and (max-width: 600px) {
    padding: 18px 32px;
    width: 100%;
  }
`;
