import styled from '@emotion/styled';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setApiSuccessMessage } from '../../store/actions';
import { IconButton, PrimaryButton } from './Controls';

import CopyIcon from '../../img/copy-btn.png';
import Drupal from '../../img/Drupal.png';
import Duda from '../../img/Duda.png';
import GTM from '../../img/GTM.png';
import Magento from '../../img/Magento.png';
import Shopify from '../../img/Shopify.png';
import Webflow from '../../img/Webflow.png';
import Weebly from '../../img/Weebly.png';
import Wix from '../../img/Wix.png';
import Wordpress from '../../img/Wordpress.png';

const DomainInstallationCode = ({ domain, code }) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const copyCodeToClipboard = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(code);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = code;
      textArea.style.widgetConfigSitePosition = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      textArea.remove();
    }

    if (domain) {
      analytics.track('Installation Pop Up Option Clicked', {
        option: 'copy-code-to-clipboard',
        url: domain,
        id: '',
      });
    }
  };

  const sendInstructionsClick = () => {
    const instructions = `Hello,

Please paste the code below right before the end of your sites body.
${code}

Or use one of the integration guides to implement Equally AI
https://equally.ai/integrations

Thank you`;

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(instructions);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = instructions;
      textArea.style.widgetConfigSitePosition = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      dispatch(setApiSuccessMessage('Instructions copied to clipboard!'));
      document.execCommand('copy');
      textArea.remove();
    }

    dispatch(setApiSuccessMessage('Instructions copied to clipboard!'));
  };

  const handleCopyToClipboard = () => {
    copyCodeToClipboard(domain);
  };

  const integrationButton = (icon, link, text, style = undefined) => (
    <Link
      to={`${process.env.REACT_APP_HTTP_INTEGRATIONS_ENDPOINT}/${link}`}
      target="_blank"
      className="website-integration-button"
      style={style}
    >
      <>
        {icon && <img alt={`${text} integration`} src={icon} />}
        {text}
      </>
    </Link>
  );

  return (
    <>
      <div className="modal-panel-sub-title">
        Paste the code below right before the end of your sites body, the URL
        must match your domain so the widget will be displayed instantly:
      </div>
      <div className="add-website-fields">
        <div className="copy-website-code-container">
          <div className="copy-website-code">
            {code && <div className="copy-website-code-text">{code}</div>}
            {!code && (
              <CenterBlock style={{ width: '100%' }}>
                <CircularProgress />
              </CenterBlock>
            )}
          </div>
          <PrimaryButton
            color="primary"
            variant="contained"
            onClick={handleCopyToClipboard}
            style={{ width: '100%', borderRadius: '0 0 8px 8px' }}
          >
            <div className="copy-code-button-container">
              <img
                className="copy-website-code-ico-btn"
                alt="copy code"
                src={CopyIcon}
              />
              <div>Copy HTML code</div>
            </div>
          </PrimaryButton>
        </div>
      </div>
      <div
        className="modal-panel-send-instructions"
        style={{ marginBottom: '5px' }}
      >
        <div>
          <div>What&apos;s next?</div>
          <div className="modal-panel-send-instructions-line-2">
            Please allow up to 48 working hours for an email confirmation that
            the process is complete.
          </div>
        </div>
      </div>
      <div className="modal-panel-send-instructions">
        <div>
          <div>Need help?</div>
          <div className="modal-panel-send-instructions-line-2">
            Send these instructions to your website admin.
          </div>
        </div>
        <StyledIconButton
          variant="outlined"
          color="primary"
          disabled={!code}
          onClick={sendInstructionsClick}
        >
          <div className="website-card-controls-btn-text-no-icon">
            Copy instructions
          </div>
        </StyledIconButton>
      </div>
      <div className="website-integration-buttons-line">Or</div>
      <div className="website-integration-buttons-line">
        {integrationButton(Wordpress, 'wordpress', 'Wordpress')}
        {integrationButton(Wix, 'wix', 'Wix')}
        {integrationButton(GTM, 'tagmanager', 'GTM')}
      </div>
      <div className="website-integration-buttons-line">
        {integrationButton(Weebly, 'weebly', 'Weebly')}
        {integrationButton(Shopify, 'shopify', 'Shopify')}
        {integrationButton(Drupal, 'drupal', 'Drupal')}
      </div>
      <div className="website-integration-buttons-line">
        {integrationButton(Magento, 'magento', 'Magento')}
        {integrationButton(Webflow, 'webflow', 'Webflow')}
        {integrationButton(Duda, 'duda', 'Duda')}
      </div>

      {integrationButton(undefined, '', 'All Integrations', {
        width: '95%',
        margin: '20px auto',
      })}
    </>
  );
};

export default DomainInstallationCode;

DomainInstallationCode.propTypes = {
  code: PropTypes.any.isRequired,
  domain: PropTypes.string.isRequired,
  // sendInstructionsClick: PropTypes.func.isRequired,
  // verifyInstallationClick: PropTypes.func.isRequired,
  // copyCodeToClipboard: PropTypes.func.isRequired,
};

const CenterBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  width: 160px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;

  @media (pointer: none), (pointer: coarse) {
    height: 40px;
    width: 100%;
  }
`;
