import { IssueType } from '../utils/devToolsTypeConfig';

export const EXTENSION_CONTAINER = 'flowy-extension-container';

export const TABINDEXEDELEMENTS =
  'a[href]:not([disabled]):not([tabindex="-1"]), button:not([disabled]), input:not([disabled]):not([type="hidden"]):not([tabindex="-1"]), select:not([disabled]):not([tabindex="-1"]), textarea:not([disabled]):not([tabindex="-1"]), [tabindex]:not([disabled]):not([tabindex="-1"]), area[href]:not([tabindex="-1"]), embed:not([tabindex="-1"]), summary:not([tabindex="-1"]), [contentEditable=true]:not([tabindex="-1"])';

export type HeatMapElement = {
  name: string;
  selector: string;
  attributes: string[];
};

export const ONLY_ISSUES_ITEMS: IssueType[] = [
  'basic',
  'advanced',
  'potential',
  'all',
];
export const ELEMENTS: HeatMapElement[] = [
  {
    name: 'ARIA',
    selector:
      '[aria-label],[aria-labelledby],[aria-expanded],[aria-live],[aria-atomic]',
    attributes: [],
  },
  {
    name: 'Images',
    selector: 'img,[role="img"],[role="presentation"]',
    attributes: ['alt', 'presentation', 'aria-hidden', 'title', 'img'],
  },
  {
    name: 'Headings',
    selector: 'h1,h2,h3,h4,h5,h6,[role="heading"]',
    attributes: ['aria-level', 'tabindex', 'heading'],
  },
  {
    name: 'Buttons',
    selector: 'button,[role="button"]',
    attributes: [
      'aria-label',
      'title',
      'aria-describedby',
      'aria-labelledby',
      'tabindex',
      'aria-controls',
    ],
  },
  {
    name: 'Links',
    selector: 'a,[role="link"]',
    attributes: [
      'aria-label',
      'title',
      'aria-describedby',
      'aria-labelledby',
      'tabindex',
    ],
  },
  {
    name: 'Landmarks',
    selector:
      'main,nav,footer,form,[role="main"],[role="navigation"],[role="form"],[role="contentinfo"]',
    attributes: ['aria-label', 'title', 'main', 'navigation', 'region'],
  },
  {
    name: 'Forms',
    selector: 'form,[role="form"]',
    attributes: [
      'aria-label',
      'aria-labelledby',
      'tabindex',
      'aria-describedby',
    ],
  },
  {
    name: 'Iframes',
    selector: 'iframe',
    attributes: ['title', 'aria-label', 'aria-labelledby', 'tabindex'],
  },
  {
    name: 'Inputs and Labels',
    selector: 'input,[role="input"],[role="checkbox"],[role="radio"],label',
    attributes: [
      'aria-describedby',
      'aria-label',
      'aria-labelledby',
      'tabindex',
    ],
  },
];

export const LANDMARKS_ROLES = [
  'banner',
  'navigation',
  'main',
  'contentinfo',
  'search',
  'form',
  'aside',
  'complementary',
];

export const ARIA_ATTRIBUTE_STRINGS = [
  'aria-label',
  'aria-placeholder',
  'aria-level',
  'aria-readonly',
  'aria-errormessage',
  'aria-labelledby',
  'aria-describedby',
];

export enum AriaAttributes {
  ariaHidden = 'aria-hidden',
  ariaExpanded = 'aria-expanded',
  ariaSelected = 'aria-selected',
  ariaCurrent = 'aria-current',
  ariaInvalid = 'aria-invalid',
  ariaModal = 'aria-modal',
  ariaReadonly = 'aria-readonly',
  ariaRequired = 'aria-required',
  ariaHaspopup = 'aria-haspopup',
  ariaAtomic = 'aria-atomic',
  ariaChecked = 'aria-checked',
  dataEquallyClick = 'data-equally-click',
  ariaLive = 'aria-live',
  ariaPressed = 'aria-pressed',
}

export const ARIA_ATTRIBUTE_STATE = [
  AriaAttributes.ariaHidden,
  AriaAttributes.ariaExpanded,
  AriaAttributes.ariaSelected,
  AriaAttributes.ariaCurrent,
  AriaAttributes.ariaInvalid,
  AriaAttributes.ariaModal,
  AriaAttributes.ariaLive,
  AriaAttributes.ariaReadonly,
  AriaAttributes.ariaRequired,
  AriaAttributes.ariaHaspopup,
  AriaAttributes.ariaAtomic,
  AriaAttributes.ariaChecked,
  AriaAttributes.dataEquallyClick,
  AriaAttributes.ariaPressed,
];

export const ARIA_ATTRIBUTE_STATE_STRING = [AriaAttributes.ariaLive];

export const MENU_NAVIGATION = [
  'data-equally-menu',
  'data-equally-menuitem',
  'data-equally-menuitem-controls',
  'data-equally-menu-managed',
  'data-equally-position-left',
  'data-equally-dropdown-content',
  'data-equally-modal-close',
];

export const CONTRAST = [
  'color',
  'backgroundColor',
  'borderColor',
  'data-equally-custom-focus',
];

export const IMAGE = ['alt'];
export const OTHERS = ['lang', 'title', 'dir', 'role'];

export const ABSTRUCT_ROLES_AREA = Object.freeze([
  'command',
  'composite',
  'input',
  'landmark',
  'range',
  'roletype',
  'section',
  'sectionhead',
  'select',
  'structure',
  'widget',
  'window',
  'switch',
]);

export const WIDGET_ROLE_AREA = Object.freeze([
  'alert',
  'alertdialog',
  'button',
  'checkbox',
  'dialog',
  'gridcell',
  'link',
  'log',
  'marquee',
  'menuitem',
  'menuitemcheckbox',
  'menuitemradio',
  'option',
  'progressbar',
  'radio',
  'scrollbar',
  'slider',
  'spinbutton',
  'status',
  'tab',
  'tabpanel',
  'textbox',
  'timer',
  'tooltip',
  'treeitem',
]);

export const COMPOSITE_USER_INTERFACE_WIDGETS_AREA = Object.freeze([
  'combobox',
  'grid',
  'listbox',
  'menu',
  'menubar',
  'radiogroup',
  'tablist',
  'tree',
  'treegrid',
]);

export const DOCUMENT_STRUCTURE_AREA = Object.freeze([
  'article',
  'columnheader',
  'definition',
  'directory',
  'document',
  'group',
  'heading',
  'paragraph',
  'img',
  'list',
  'listitem',
  'math',
  'note',
  'presentation',
  'region',
  'row',
  'rowgroup',
  'rowheader',
  'separator',
  'toolbar',
]);

export const LIST_OF_RELATIONS = Object.freeze([
  'aria-activedescendant',
  'aria-colcount',
  'aria-colindex',
  'aria-colspan',
  'aria-controls',
  'aria-describedby',
  'aria-details',
  'aria-errormessage',
  'aria-flowto',
  'aria-labelledby',
  'aria-owns',
  'aria-posinset',
  'aria-rowcount',
  'aria-rowindex',
  'aria-rowspan',
  'aria-setsize',
  'data-equally-controls',
  'data-equally-submenu',
]);

export const DYNAMIC_ELEMENTS_ROLES = Object.freeze([
  'alert',
  'status',
  'log',
  'timer',
  'marque',
]);

export const POLITENESS_SETTING = Object.freeze(['off', 'polite', 'assertive']);

export const MANDATORY_ELEMENT_ATTRIBUTES: Record<string, string[]> = {
  img: ['alt'],
};
