import { WHITELIST } from '../helpers';
import axios from 'axios';
import { HTTP_CLIENT_ENDPOINT } from '../utils/magicValues';

class UserApi {
  static async getPersonalDetails() {
    const result = await axios.get(
      HTTP_CLIENT_ENDPOINT + '/api/v1/admin/a/personal-details',
    );
    return result;
  }

  static async updatePersonalDetails({
    token,
    fullName,
    phoneNumber,
  }: {
    fullName?: string;
    phoneNumber?: string;
    token?: string;
  }) {
    let result;
    let error;
    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
    try {
      result = await axios.post(
        HTTP_CLIENT_ENDPOINT + '/api/v1/admin/a/personal-details',
        { fullName, phoneNumber },
        config,
      );
    } catch (err) {
      error = err;
      result = err?.response;
    }
    const { data } = result || {};
    return {
      data: data?.data || null,
      isSuccess: result?.status === 200,
      error:
        error?.response?.data?.message || 'Error updating personal details.',
    };
  }

  static async isAdmin(accessToken: string) {
    const { data } = await axios.get(
      HTTP_CLIENT_ENDPOINT + '/api/v1/adminpanel/a/isadmin',
    );
    const result = data.message;
    if (
      JSON.parse(atob(accessToken.split('.')[1])).email &&
      WHITELIST.includes(JSON.parse(atob(accessToken.split('.')[1])).email)
    ) {
      result.accessLevel = 'admin';
    } else {
      result.accessLevel = 'support';
    }
    return result;
  }
}

export default UserApi;
