import styled from '@emotion/styled';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import TextLink from '../../../components/common/TextLink';
import { setApiError, setApiSuccessMessage } from '../../../store/actions';
import { AuthUtils } from '../../../utils/auth.utils';
import { PageContent, StyledLink, Wrapper } from './styled';

export const EmailVerification = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email') || '';
  const dispatch = useDispatch();

  const handleResendVerificationEmail = async () => {
    const response = await AuthUtils.resendVerificationEmail();

    if (!response) {
      dispatch(setApiError('Oops!, unable to resend email'));
      return;
    }

    dispatch(setApiSuccessMessage('Veriftication email has been resent!'));
  };
  return (
    <Wrapper alignItems="center">
      <PageContent>
        <Grid container>
          <Title>Please verify your email</Title>

          <Grid item xs={12} sm={12} md>
            <Text>
              You&apos;re almost there. We&apos;ve sent an email to&nbsp;
              {email && <EmailLink href={`mailto:${email}`}>{email}</EmailLink>}
              <br />
              <br />
              Click the link in the email to confirm your address and activate
              your account.
              <br />
              <br />
              Didn&apos;t receive the email?
              <br />
              <br />
              Check your spam folder or&nbsp;
              <TextLink onClick={handleResendVerificationEmail}>
                click here
              </TextLink>
              &nbsp;to resend the verification email.
              <br />
              Need help?&nbsp;
              <Link href="mailto:support@equally.ai">Contact us</Link>
            </Text>
          </Grid>
        </Grid>
      </PageContent>
    </Wrapper>
  );
};

const Title = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 130%;

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

const Text = styled.div`
  color: rgba(9, 60, 43, 0.9);

  font-weight: 300;
  font-size: 20px;
  line-height: 155%;
`;

const Link = styled(StyledLink)`
  border-bottom-color: rgba(9, 60, 43, 0.32);
  word-wrap: break-word;
`;

const EmailLink = styled(StyledLink)`
  display: inline;
`;
