import React, { useState, ReactNode } from 'react';
import { Collapse } from '@mui/material';
import styled from 'styled-components';

export interface AccordionState {
  [x: string]: boolean;
}

export const useAccessibilityAccordion = () => {
  const [accordionState, setAccordionState] = useState<AccordionState>({});

  const handleAccordionToggle = (accordionId: string) => {
    setAccordionState((prevState) => {
      const newState = {
        ...prevState,
        [accordionId]: !prevState[accordionId],
      };
      return newState;
    });
  };

  return {
    accordionState,
    handleAccordionToggle,
  };
};

interface AccessibilityAccordionProps {
  title: string;
  accordionId: string;
  accordionState: AccordionState;
  handleToggleAccordion: () => void;
  accordionHeader: ReactNode;
  children: ReactNode;
}

export const AccessibilityAccordion = ({
  title,
  accordionId,
  accordionState,
  handleToggleAccordion,
  accordionHeader,
  children,
}: AccessibilityAccordionProps) => {
  const accordionButtonId = `${title}-accordion`;

  const handleAccordionKeyDown = (
    evt: React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    if (evt.key === 'Enter' || evt.key === 'Space') {
      evt.preventDefault();
      handleToggleAccordion();
    }
  };

  return (
    <Wrapper>
      <Header>
        <AccordionButton
          type="button"
          aria-expanded={accordionState[accordionId]}
          aria-controls={accordionButtonId}
          id={accordionButtonId}
          onClick={handleToggleAccordion}
          onKeyDown={handleAccordionKeyDown}
        >
          {accordionHeader}
        </AccordionButton>
      </Header>
      <Collapse
        in={accordionState[accordionId]}
        timeout="auto"
        unmountOnExit
        role="region"
        id={accordionButtonId}
        aria-hidden={!accordionState[accordionId]}
      >
        {children}
      </Collapse>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Header = styled.h3`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AccordionButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
`;
