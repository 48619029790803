import React from 'react';
import { EmailVerified } from './components/redesign/EmailVerified';
import { Logout } from './containers/logout/Logout';
import { AppRedirect } from './containers/app-redirect';
import { BusinessFormContainer } from './containers/on-boarding-container/on-boarding-container';
import { ProtectedRoute } from './containers/protected-route';

export const routes = [
  {
    element: <Logout />,
    path: '/logout',
  },
  {
    element: <AppRedirect />,
    path: '/*',
  },
  {
    element: (
      <ProtectedRoute>
        <BusinessFormContainer />
      </ProtectedRoute>
    ),
    path: '/on-boarding/*',
  },
  {
    element: <EmailVerified />,
    path: '/verify-email',
  },
];
