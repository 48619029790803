import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { BillingInfo } from './BillingInfo';
import { MyPlan } from './MyPlan';
// import { EmailNotifications } from "./EmailNotifications";
// import { Invoice } from "./Invoice";
// import { Password } from "./Password";
// import { Support } from "./Support";
import { TabInfo } from './TabInfo';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={index > 0 ? 8 : 0}>{children}</Box>}
    </Typography>
  );
}

function BillingTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      style={{ border: 'none' }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

BillingTabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '32px',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    // height: 224
  },
  tabs: {
    border: '1px solid #E6E7E8',
    marginRight: '26px',
    borderRadius: '8px',
    width: '269px !important',
    height: 'fit-content',
  },
  tabPanel: {
    border: '1px solid #E6E7E8',
    borderRadius: '8px',
    width: '100%',
  },
}));

export const Account = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TabInfo
        title="Account Settings"
        description="Manage your account settings and subscription parameters"
      />
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          textColor="primary"
          indicatorColor="primary"
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab
            label="My Plan"
            classes={{
              root: 'account-nav-panel-tab',
              wrapper: 'account-nav-panel-tab-label',
              selected: 'account-nav-panel-tab-selected',
            }}
            {...a11yProps(0)}
          />
          <Tab
            label="Billing Info"
            classes={{
              root: 'account-nav-panel-tab',
              wrapper: 'account-nav-panel-tab-label',
              selected: 'account-nav-panel-tab-selected',
            }}
            {...a11yProps(1)}
          />
          {/* <Tab
            label="Invoice"
            classes={{
              root: "account-nav-panel-tab",
              wrapper: "account-nav-panel-tab-label",
              selected: "account-nav-panel-tab-selected"
            }}
            {...a11yProps(2)}
          />
          <Tab
            label="Notifications"
            classes={{
              root: "account-nav-panel-tab",
              wrapper: "account-nav-panel-tab-label",
              selected: "account-nav-panel-tab-selected"
            }}
            {...a11yProps(3)}
          /> */}
          {/* <Tab */}
          {/*  label="Password" */}
          {/*  classes={{ */}
          {/*    root: "account-nav-panel-tab", */}
          {/*    wrapper: "account-nav-panel-tab-label", */}
          {/*    selected: "account-nav-panel-tab-selected" */}
          {/*  }} */}
          {/*  {...a11yProps(4)} */}
          {/* /> */}
          {/* <Tab */}
          {/*  label="Support" */}
          {/*  classes={{ */}
          {/*    root: "account-nav-panel-tab", */}
          {/*    wrapper: "account-nav-panel-tab-label", */}
          {/*    selected: "account-nav-panel-tab-selected" */}
          {/*  }} */}
          {/*  {...a11yProps(5)} */}
          {/* /> */}
        </Tabs>
        <TabPanel value={value} className={classes.tabPanel} index={0}>
          <MyPlan />
        </TabPanel>
        <BillingTabPanel value={value} className={classes.tabPanel} index={1}>
          <BillingInfo />
        </BillingTabPanel>
        {/* <TabPanel value={value} className={classes.tabPanel} index={2}>
          <Invoice />
         </TabPanel>
         <TabPanel value={value} className={classes.tabPanel} index={3}>
          <EmailNotifications />
         </TabPanel> */}
        {/* <TabPanel value={value} className={classes.tabPanel} index={2}> */}
        {/*  <Password /> */}
        {/* </TabPanel> */}
        {/* <TabPanel value={value} className={classes.tabPanel} index={3}> */}
        {/*  <Support /> */}
        {/* </TabPanel> */}
      </div>
    </>
  );
};
