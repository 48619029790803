import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
// import { IconButton, InputAdornment } from '@material-ui/core';
import { addDomainToPlan } from '../../../../common/helpers';
import useAddDomainFormActions from '../../../../hooks/use-add-domain-form-actions';
import {
  getActivePlans,
  setApiError,
  setApiSuccessMessage,
} from '../../../../store/actions';
import { PrimaryButton } from '../../Controls';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
    marginRight: '16px',
    width: '100%',
    fontWeight: 'normal',
    '& input:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#E4E7EB',
    },
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginRight: '16px',
  },
  links: {
    marginTop: '50px',
    marginBottom: '20px',
  },
  option: {
    textTransform: 'uppercase',
  },
}));

const NewProject = ({ options }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [option, setOption] = useState('');
  const [domain, setDomain] = useState('');
  const [description, setDescription] = useState('');
  const { addDomains: addService, isLoading } = useAddDomainFormActions();
  const handleOptionChange = (event) => {
    setOption(event.target.value);
  };

  const handleLinkChange = (event) => {
    setDomain(event.target.value);
  };

  const handleCreationSuccess = () => {
    dispatch(getActivePlans());
    dispatch(setApiSuccessMessage('Services Added Successfully'));
    setDomain('');
    dispatch(setApiError('Something went wrong!'));
  };

  const handleSubmit = async () => {
    if (!option || isLoading) {
      return;
    }
    await addService([], 'service', option, description, handleCreationSuccess);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  return (
    <>
      {/*<div className="customize-website-content-fields-line">*/}
      {/*  <TextField*/}
      {/*    label="Website URL"*/}
      {/*    variant="outlined"*/}
      {/*    className={classes.input}*/}
      {/*    InputLabelProps={{*/}
      {/*      shrink: true,*/}
      {/*    }}*/}
      {/*    value={domain}*/}
      {/*    onChange={handleLinkChange}*/}
      {/*  />*/}
      {/*</div>*/}
      <div className="customize-website-content-fields-line">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="project-type">Project Type</InputLabel>
          <Select
            labelId="project-type"
            label="Project Type"
            value={option}
            onChange={handleOptionChange}
            className={classes.option}
          >
            {options.map((opt) => (
              <MenuItem value={opt} key={opt} className={classes.option}>
                {opt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="customize-website-content-fields-line">
        <TextField
          label="Project Details"
          multiline
          minRows={3}
          maxRows={5}
          variant="outlined"
          className={classes.input}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleDescriptionChange}
        />
      </div>
      {/* <p className={classes.links}>
        For files or media projects, you can add cloud storage links to the
        resources. For audits and testing projects, add links to your websites.
      </p>
      <div className="customize-website-content-fields-line">
        <TextField
          label="Project Link"
          variant="outlined"
          className={classes.input}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <CloseRoundedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value=""
        />
      </div>
      <div className="customize-website-content-fields-line">
        <TextField
          label="Project Link"
          variant="outlined"
          className={classes.input}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <CloseRoundedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value=""
        />
      </div> */}
      {/* <div className="customize-website-content-fields-line">
        <PrimaryMobileButton variant="outlined">
          Add another link
        </PrimaryMobileButton>
      </div> */}
      <div className="customize-website-content-fields-line">
        <PrimaryButton
          variant="contained"
          color="primary"
          disabled={isLoading}
          className={classes.links}
          onClick={handleSubmit}
        >
          Add Project
        </PrimaryButton>
      </div>
    </>
  );
};

NewProject.propTypes = {
  options: PropTypes.array.isRequired,
};

export default NewProject;
