import styled from '@emotion/styled';
import { useAccessibilityAccordion } from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import {
  DevtoolsSolution,
  IssueReportItem,
  ReportData,
  SuccessCriteriaRules,
} from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { Divider as MuiDivider } from '@mui/material';
import React from 'react';
import { IssueReportItemDetails } from './issue-report-item-details';
import { IssueReportLayout } from './issue-report-layout';
import { IssueReportSection } from './issue-report-section';
import { IssueReportSectionItem } from './issue-report-section-item';

interface IssueReportProps {
  list: IssueReportItem[];
  wcagReport: ReportData;
  devDomain?: DevDomain;
  setSuccessMessage: (message: string) => void;
  selectedIssuesId: Record<number, boolean>;
  setSelectedIssuesId: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
}

export const IssueReport = ({
  list,
  wcagReport,
  devDomain,
  setSuccessMessage,
  selectedIssuesId,
  setSelectedIssuesId,
}: IssueReportProps) => {
  const { accordionState, handleAccordionToggle } = useAccessibilityAccordion();

  return (
    <IssueReportLayout>
      {list
        .filter(
          ({ noticeIssueCount, warningIssueCount, criticalIssueCount }) =>
            noticeIssueCount !== 0 ||
            warningIssueCount !== 0 ||
            criticalIssueCount !== 0,
        )
        .map((section) => (
          <IssueReportSection
            key={section.id}
            title={`${section.num} ${section.title}`}
            accordionId={section.id}
            accordionState={accordionState}
            handleAccordionToggle={handleAccordionToggle}
            noticeIssueCount={section.noticeIssueCount || 0}
            warningIssueCount={section.warningIssueCount || 0}
            criticalIssueCount={section.criticalIssueCount || 0}
          >
            <Divider />
            {section.successcriteria
              ?.filter((sectionIssue) => sectionIssue.status !== 'not-found')
              .map((criteria, idx) => {
                const noticeIssues = section.noticeIssues || [];
                const warningIssues = section.warningIssues || [];
                const criticalIssues = section.criticalIssues || [];

                const criteriaRulesMapping = criteria.rules.reduce(
                  (acc: { [x: string]: SuccessCriteriaRules }, rule) => {
                    const { code } = rule;
                    acc[code] = rule;
                    return acc;
                  },
                  {},
                );

                const noticeIssuesRelatedToRules: DevtoolsSolution[] = [];
                noticeIssues.forEach((issue) => {
                  const { principle } = issue;
                  if (
                    Object.keys(criteriaRulesMapping).some((mappedCode) =>
                      principle.includes(mappedCode),
                    )
                  ) {
                    noticeIssuesRelatedToRules.push(issue);
                  }
                });

                const warningIssuesRelatedToRules: DevtoolsSolution[] = [];
                warningIssues.forEach((issue) => {
                  const { principle } = issue;
                  if (
                    Object.keys(criteriaRulesMapping).some((mappedCode) =>
                      principle.includes(mappedCode),
                    )
                  ) {
                    warningIssuesRelatedToRules.push(issue);
                  }
                });

                const criticalIssuesRelatedToRules: DevtoolsSolution[] = [];
                criticalIssues.forEach((issue) => {
                  const { principle } = issue;
                  if (
                    Object.keys(criteriaRulesMapping).some((mappedCode) =>
                      principle.includes(mappedCode),
                    )
                  ) {
                    criticalIssuesRelatedToRules.push(issue);
                  }
                });

                const status = 'error';

                return (
                  <React.Fragment key={criteria.id}>
                    <IssueReportSectionItem
                      title={`${criteria.num} ${criteria.title}`}
                      accordionId={criteria.id}
                      accordionState={accordionState}
                      handleAccordionToggle={handleAccordionToggle}
                      level={criteria.level}
                      status={status}
                    >
                      <IssueReportItemDetails
                        title={criteria.fullTitle}
                        accordionState={accordionState}
                        handleAccordionToggle={handleAccordionToggle}
                        description={criteria.description}
                        noticeIssuesRelatedToRules={noticeIssuesRelatedToRules}
                        warningIssuesRelatedToRules={
                          warningIssuesRelatedToRules
                        }
                        criticalIssuesRelatedToRules={
                          criticalIssuesRelatedToRules
                        }
                        wcagReport={wcagReport}
                        devDomain={devDomain}
                        setSuccessMessage={setSuccessMessage}
                        selectedIssuesId={selectedIssuesId}
                        setSelectedIssuesId={setSelectedIssuesId}
                      />
                    </IssueReportSectionItem>
                    {idx < section.successcriteria.length - 1 && <Divider />}
                  </React.Fragment>
                );
              })}
          </IssueReportSection>
        ))}
    </IssueReportLayout>
  );
};

/** Styled Components */

const Divider = styled(MuiDivider)`
  height: 2px;
`;
