import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { styled as MuiStyled } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material';
import { TaskAdditionalInfo } from '@equally-ai-front/common/src/types/business';
import { IssueCreationType } from './popup';

interface AdditionalInfoFieldsProps {
  index: number;
  issueCreationType: IssueCreationType;
  tasksAdditionalInfo: TaskAdditionalInfo[];
  setTasksAdditionalInfo: React.Dispatch<
    React.SetStateAction<TaskAdditionalInfo[]>
  >;
}

export const AdditionalInfoFields = (props: AdditionalInfoFieldsProps) => {
  const {
    index,
    issueCreationType,
    tasksAdditionalInfo,
    setTasksAdditionalInfo,
  } = props;
  const taskAdditionalInfo =
    tasksAdditionalInfo[issueCreationType === 'group_issues' ? 0 : index];

  const handleChangeTaskAdditionalInfo = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = evt.target;
    setTasksAdditionalInfo((prev) =>
      prev.map((current) => {
        if (current.issueGroupId !== index) return current;
        return { ...current, [name]: value };
      }),
    );
  };

  return (
    <AdditionalInfoWrapper>
      <Title>Additional Task Info</Title>
      <FieldWrapper>
        <FieldLabel htmlFor="summary">Name</FieldLabel>
        <CustomTextField
          id="summary"
          name="summary"
          value={taskAdditionalInfo.summary}
          required
          onChange={handleChangeTaskAdditionalInfo}
          size="small"
          variant="outlined"
        />
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel htmlFor="description">Description</FieldLabel>
        <CustomTextField
          id="description"
          name="description"
          value={taskAdditionalInfo.description}
          onChange={handleChangeTaskAdditionalInfo}
          multiline
          minRows={4}
          variant="outlined"
        />
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel htmlFor="moreInfo">Additional Info</FieldLabel>
        <CustomTextField
          id="moreInfo"
          name="moreInfo"
          value={taskAdditionalInfo.moreInfo}
          onChange={handleChangeTaskAdditionalInfo}
          multiline
          minRows={4}
          variant="outlined"
        />
      </FieldWrapper>
    </AdditionalInfoWrapper>
  );
};

const AdditionalInfoWrapper = styled.div`
  padding: 20px 55px;
`;

const Title = styled.h5`
  margin-bottom: 20px;
  font-size: 18px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

const FieldLabel = styled.label`
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
`;

const CustomTextField = MuiStyled(TextField)(() => ({
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: '#ebebeb',
    borderWidth: '1.5px',
    borderRadius: 6,
  },
}));
