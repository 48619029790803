import styled from '@emotion/styled';
import { Box, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { CenterBlock, TypographySubtitle1 } from '../../common/styled';

import PresentationModal from '../../common/PresentationModal';

/** value 0-100 */
const ProgressView = ({ open, value }) => {
  return (
    <PresentationModal open={open}>
      <ProgressBar variant="determinate" value={value} />
      <Box mt="16px" />
      <CenterBlock>
        <Description component="span">Fetching your results</Description>
      </CenterBlock>
    </PresentationModal>
  );
};

ProgressView.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.number,
};

ProgressView.defaultProps = {
  open: false,
  value: 0,
};

export default ProgressView;

/** Styled Components */

const ProgressBar = styled(LinearProgress)`
  height: 8px;
  border-radius: 5px;
  width: min(512px, 70vw);
`;

const Description = styled(TypographySubtitle1)`
  color: ${({ theme }) => theme.palette.on.darkLow};
`;
