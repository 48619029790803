import React, { useState } from 'react';

import styled from '@emotion/styled';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { ProductType } from '../../common/product-type-chip';
import { AddDomainsForm } from './components/add-domains-form';
import { SelectProduct } from './components/select-product';

interface StartFreeTrialProps {
  open: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleInputChange: (value: string, index: number) => void;
  addDomains: () => void;
  domainsToAdd: string[];
  domains: any[];
  type: ProductType;
  setProductType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  modalStep?: 0 | 1;
}

const StartFreeTrial = (props: StartFreeTrialProps) => {
  const {
    open,
    handleClose,
    domains,
    handleInputChange,
    type,
    addDomains,
    domainsToAdd,
    isLoading,
    setProductType,
    modalStep,
  } = props;
  const isMobile = useMediaQuery('(max-width: 600px)');

  const [step, setStep] = useState(modalStep || 0);

  const onNextStep = () => setStep(1);

  const isFullScreen = isMobile && step === 0;

  return (
    // @ts-ignore
    <StyledDialog open={open} onClose={handleClose} fullScreen={isFullScreen}>
      <CloseButton
        aria-label="close"
        onClick={handleClose}
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </CloseButton>

      {step === 0 && (
        <SelectProduct
          onSelectProduct={setProductType}
          selectedProduct={type}
          onSubmitType={onNextStep}
        />
      )}

      {step === 1 && type && (
        <AddDomainsForm
          addDomains={addDomains}
          domains={domains}
          domainsToAdd={domainsToAdd}
          handleInputChange={handleInputChange}
          isLoading={isLoading}
          type={type}
          setModalStep={() => setStep(0)}
        />
      )}

      <LoadingView open={isLoading} loadingText="Getting your domain ready" />
    </StyledDialog>
  );
};

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 900px;

    @media (pointer: none), (pointer: coarse) {
      max-width: 95%;
      max-height: 93vh;
      border-radius: 8px;
      justify-content: center;
    }

    @media only screen and (max-width: 600px) {
      margin: 0;
    }
  }
`;

const CloseButton = styled(IconButton)`
  &:focus {
    border: 2px solid #3e3c77;
  }
`;

// @ts-ignore
export default StartFreeTrial;
// @ts-ignore
