import { AriaContainer } from '@equally-ai/audit-ui/src/containers/aria-container';
import { Box, CircularProgress } from '@material-ui/core';
import React, { Suspense, useCallback } from 'react';
import { CenterBlock } from '../common/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import useMessage from '../../hooks/useMessage';
import { useAutomaticScan } from '@equally-ai-front/common/src/hooks/use-automatic-scan';

const AriaLazy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { error, success } = useMessage();

  const onAuditError = useCallback(
    (err) => {
      error(err);
    },
    [error],
  );

  const {
    performSingleAudit,
    isLoading: loading,
    scans,
    getIssues,
  } = useAutomaticScan({
    onError: onAuditError,
  });
  const startFreeTrial = () => {
    const businessSlug = location.pathname.split('/')[1];
    navigate(`/${businessSlug}?start_free_trial=true`);
  };

  const createNotification = (message, variant) => {
    if (variant === 'error') {
      error(message);
    } else {
      success(message);
    }
  };
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <AriaContainer
        performAudit={performSingleAudit}
        allowNonBusiness
        loading={loading}
        getIssues={getIssues}
        scans={scans}
        onStartFreeTrialClick={startFreeTrial}
        createNotification={createNotification}
      />
    </Suspense>
  );
};

export default AriaLazy;

const LoadingIndicator = () => (
  <Box marginY={4}>
    <CenterBlock>
      <CircularProgress />
    </CenterBlock>
  </Box>
);
