import React from 'react';
import styled from 'styled-components';

interface AccessibilityIssueDetailsProps {
  issueDescriptionId: string;
  issueDescriptionContent: JSX.Element;
  howToFixContent: JSX.Element;
  isExtension?: boolean;
  hideHowToFix?: boolean;
}

export const AccessibilityIssueDetails = (
  props: AccessibilityIssueDetailsProps,
) => {
  const {
    issueDescriptionId,
    issueDescriptionContent,
    howToFixContent,
    isExtension,
    hideHowToFix,
  } = props;

  return (
    <Wrapper>
      <SectionDiv isFirst isExtension={isExtension}>
        <TitleHeader id={issueDescriptionId}>Requirements</TitleHeader>
        <>{issueDescriptionContent}</>
      </SectionDiv>
      {!hideHowToFix && (
        <SectionDiv isExtensionHowToFix={isExtension}>
          <TitleHeader isExtensionHowToFix={isExtension}>
            How To Fix
          </TitleHeader>
          <>{howToFixContent}</>
        </SectionDiv>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 32px;
  position: relative;
`;

const SectionDiv = styled.div<{
  isFirst?: boolean;
  isExtension?: boolean;
  isExtensionHowToFix?: boolean;
}>`
  padding-top: ${(p) =>
    p.isExtensionHowToFix ? '18px' : p.isExtension ? '0' : '16px'};
  margin-bottom: 0;

  &:after {
    content: '';
    border-bottom: 1.5px solid #e4e7eb;
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
  }
`;

const TitleHeader = styled.h5<{
  isExtension?: boolean;
  isExtensionHowToFix?: boolean;
}>`
  font-weight: 500;
  font-size: ${(p) => (p.isExtension ? '15px' : '14px')};
  color: rgba(0, 10, 20, 0.85);
  margin-bottom: 1vh;
  margin-top: ${(p) => (p.isExtension ? '0' : '')};
  ${(p) =>
    p.isExtensionHowToFix &&
    `
    margin: 0;
  `}

  @media screen and (max-width: 650px) {
    margin-bottom: ${(p) => (p.isExtensionHowToFix ? '' : '2vh')};
  }
`;
