import styled from 'styled-components';

export const Container = styled.div``;

export const SvgWrapper = styled.div`
  width: 100%;
  max-width: 5.5vw;

  @media screen and (min-width: 1300px) {
    display: flex;
    justify-content: flex-end;
  }

  @media (pointer: none), (pointer: coarse) {
    max-width: 100vw;
    display: flex;
    justify-content: center;
  }
`;

export const TypographyContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  @media screen and (max-width: 1030px) {
    margin-left: 2vw;
  }

  @media screen and (max-width: 768px) {
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
  }

  @media (pointer: none), (pointer: coarse) {
    margin-left: 0;
  }
`;

export const SkeletonImageWrapper = styled.div``;
export const SkeletonImage = styled.img`
  @media (pointer: none), (pointer: coarse) {
    width: 100%;
  }
`;

export const IssueSummaryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const IssueSummary = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 90%;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column-reverse;
  }
`;

export const IssueSummaryContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-basis: 62%;

  @media (pointer: none), (pointer: coarse) {
    max-width: 100%;
  }
`;

export const IssueSummaryContent = styled.div`
  display: flex;

  @media (pointer: none), (pointer: coarse) {
    margin-left: 0;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;
  }
`;

export const PdfDownloadButtonContainer = styled.div`
  margin-left: 5.5vw;

  @media screen and (max-width: 1030px) {
    margin-left: 7.5vw;
  }

  @media (pointer: none), (pointer: coarse) {
    margin-left: 0;
    position: absolute;
    bottom: 5.5vh;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
