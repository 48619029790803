import { Issue } from '@equally-ai-front/common/src/types/issues';
import React, { useMemo } from 'react';
import styled from 'styled-components';

interface IssuesCountInfoProps {
  label: string;
  value: number;
}
interface IssuesCountProps {
  issues: Issue[];
}
type IssueType = 'notice' | 'warning' | 'error';

const calculateIssuesCount = (issues: Issue[]) => {
  if (issues.length === 0) {
    return ['Total Issues', 'Critical', 'Potential', 'Best Practice'].map(
      (label) => ({ label, value: 0 }),
    );
  }

  const counts: { notice: number; warning: number; error: number } = {
    notice: 0,
    warning: 0,
    error: 0,
  };
  issues.forEach((issue: Issue) => {
    // @ts-ignore
    const issueType: IssueType = issue.type;
    counts[issueType] += 1;
  });

  const { error, warning, notice } = counts;
  return [
    {
      label: 'Total Issues',
      value: error + warning + notice,
    },
    { label: 'Critical', value: error },
    { label: 'Potential', value: warning },
    { label: 'Best Practice', value: notice },
  ];
};

export const IssuesCount = (props: IssuesCountProps) => {
  const { issues } = props;
  const issuesCount: IssuesCountInfoProps[] = useMemo(() => {
    return calculateIssuesCount(issues);
  }, [issues]);

  return (
    <Wrapper>
      <IssueCountWrapper>
        {issuesCount.map(({ label, value }) => (
          <IssueCount key={`${label}-${value}`}>
            <IssueCountHeader>{label}</IssueCountHeader>
            <IssueCountText>{value}</IssueCountText>
          </IssueCount>
        ))}
      </IssueCountWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const IssueCountWrapper = styled.div`
  margin: 16px 0;
  display: flex;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
  }
`;

const IssueCount = styled.div`
  @media screen and (min-width: 1300px) {
    width: 250px;
    height: 120px;
  }

  width: 30%;
  height: 100px;
  border: 1.5px solid #e0e0e0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  @media (pointer: none), (pointer: coarse) {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const IssueCountHeader = styled.h3`
  color: #9ea6b4;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  line-height: 15px;
  height: 25px;
  display: flex;
  align-items: center;
`;

const IssueCountText = styled.p`
  font-size: 38px;
  color: #000a14;
  font-weight: 700;
  margin-top: 5px;
  line-height: 38px;
`;
