import styled from '@emotion/styled';
import {
  currentBusinessUpdated,
  getBusinesses,
} from '@equally-ai-front/common/src/redux/business-slice/business';
import { Business } from '@equally-ai-front/common/src/types/business';
import { Paper } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { getPersonalDetails } from '../../common/helpers';
import { setPersonalDetails } from '../../store/actions';
import { BusinessStep } from './components/business-step';
import { TeamMemberStep } from './components/team-member-step';

export const BusinessFormContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (newBusiness: Business) => {
    const accessToken = localStorage.getItem('accessToken');

    // update personal details
    if (accessToken) {
      const personalDetailsResponse = await getPersonalDetails(accessToken);
      dispatch(setPersonalDetails(personalDetailsResponse.message));
    }

    dispatch(currentBusinessUpdated(newBusiness));
    navigate(`team-member`);
  };
  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));

  useEffect(() => {
    if (!currentBusiness) {
      return;
    }
    // @ts-ignore
    dispatch(getBusinesses());
  }, [currentBusiness, dispatch]);

  const navigateToWidgetPage = useCallback(() => {
    if (!currentBusiness) {
      return;
    }
    navigate(`/${currentBusiness.slug}/?from_onboarding=true`);
  }, [currentBusiness, navigate]);

  return (
    <PageContainer>
      <PageContent>
        <StyledPaper>
          <Routes>
            <Route index element={<BusinessStep onSubmit={handleSubmit} />} />
            <Route
              path={'team-member'}
              element={
                <TeamMemberStep
                  onSubmit={() => {}}
                  onComplete={navigateToWidgetPage}
                />
              }
            />
          </Routes>
        </StyledPaper>
      </PageContent>
    </PageContainer>
  );
};

const StyledPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  max-width: 600px;
  padding: 3vh 3vw;
`;

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //justify-content: center;
`;
const PageContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
