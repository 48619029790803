import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { TypographySmall } from '../../../../components/common/Pill';
import moment from 'moment';
import { ContainedButton } from '../../../../components/common/ActionButton';
import styled from 'styled-components';

interface TableDateProps {
  id: number;
  issues: number;
  date: string;
  rowData: any;
  isLoading: boolean;
}

export const TableDate = ({
  date,
  id,
  issues,
  rowData,
  isLoading,
}: TableDateProps) => {
  const navigate = useNavigate();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const { domainId } = useParams();
  const getPageResult = (scanHistoryId: number) => {
    navigate(
      `/${currentBusiness?.slug}/developers/${domainId}/flowy/scans/issues/${scanHistoryId}`,
    );
  };

  return (
    <Box display="flex" flexDirection="column" paddingRight={'10px'}>
      {!isLoading && (
        <StyledButton
          onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
            evt.stopPropagation();
            getPageResult(id);
          }}
        >
          {issues} issues
        </StyledButton>
      )}
      <DateText>
        {moment(new Date(date)).format('MMMM Do, YYYY HH:mm')}
      </DateText>
    </Box>
  );
};

const StyledButton = styled(ContainedButton)`
  background: transparent;
  font-size: 14px;
  padding: 0;
  margin: 0;
  color: #000a14;
  box-shadow: none;
  text-align: left;
  text-transform: initial;
  width: fit-content;
  border-radius: 0;
  border-bottom: 1px solid transparent;

  &:hover {
    background: transparent;
    box-shadow: none;
    color: #000a14;
    border-radius: 0;
    border-bottom: 1px solid #454284;
  }

  @media screen and (max-width: 600px) {
    padding: 0 !important;
  }
`;

const DateText = styled(TypographySmall)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
`;
