import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const Spinner = ({ show }) => {
  return (
    <SpinnerContainer show={show}>
      <CircularProgress />
    </SpinnerContainer>
  );
};

Spinner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default Spinner;

const SpinnerContainer = styled.div`
  position: absolute;
  inset: 0;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background-color: #ffffffa6;
`;
