import firebase from 'firebase/app';
import 'firebase/auth';
import { FIREBASE_CONFIG } from './magicValues';

export const app = firebase.initializeApp(FIREBASE_CONFIG);
const firebaseAuth = firebase.auth(app);

function parseJwt(token) {
  const accessToken = token.split('.');
  const base64Url = accessToken[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    // eslint-disable-next-line no-undef
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

function verifyEmail(code) {
  return firebaseAuth.applyActionCode(code);
}

function resetPassword(code, newPassword) {
  return firebaseAuth.confirmPasswordReset(code, newPassword);
}

function updatedPassword(user, newPassword) {
  return firebaseAuth.updatedPassword(user, newPassword);
}

export {
  firebase,
  firebaseAuth,
  parseJwt,
  verifyEmail,
  resetPassword,
  updatedPassword,
};
