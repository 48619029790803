import React, { useMemo } from 'react';
import { Chart } from 'react-google-charts';
import { capitalizeFirstLetter } from '@equally-ai-front/common/src/helpers';
import styled from 'styled-components';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import { useMediaQuery } from '@material-ui/core';

const calculatePercentage = (
  currentValue: number,
  totalValue: number,
): string => {
  const percentage = (currentValue / totalValue) * 100;
  return percentage.toString().split('.').length > 1
    ? percentage.toFixed(1)
    : percentage.toString();
};

const colors = ['#454284', '#4A5284', '#596391', '#6B739D', '#7C7BA8'];
const options = {
  title: '',
  colors,
  pieSliceText: 'percentage',
  pieSliceTextStyle: {
    color: '#ffffff',
    fontSize: 8,
  },
  chartArea: {
    width: '70%',
    height: '70%',
  },
  sliceVisibilityThreshold: 0.0001,
};

interface IssuesPieChartProps {
  scanIssuesGroupingByElement: any[];
  issues: Issue[];
}

export const IssuesPieChart = (props: IssuesPieChartProps) => {
  const { scanIssuesGroupingByElement, issues: allIssues } = props;
  const isLargeScreen = useMediaQuery('(min-width: 1300px)');

  const chartWidth = useMemo(
    () => (isLargeScreen ? 800 : 400),
    [isLargeScreen],
  );

  const pieChartDataForIssuesGroupedByElement = useMemo(() => {
    return [
      ['Issues Element Group Name', 'Number of Issues'],
      ...scanIssuesGroupingByElement.map(([issuesElementGroupName, issues]) => [
        `${capitalizeFirstLetter(
          issuesElementGroupName,
        )} - ${calculatePercentage(issues.length, allIssues.length)}%`,
        issues.length,
      ]),
    ];
  }, [scanIssuesGroupingByElement, allIssues]);

  return (
    <PieChartWrapper>
      <Chart
        key={chartWidth}
        chartType="PieChart"
        data={pieChartDataForIssuesGroupedByElement}
        options={options}
        width={chartWidth}
        height={280}
      />
    </PieChartWrapper>
  );
};

const PieChartWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 0;
  width: 100%;

  @media screen and (max-width: 1300px) and (min-width: 1030px) {
    max-width: 320px;
  }

  @media screen and (min-width: 1300px) {
    max-width: 610px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 25%;
  }
`;
