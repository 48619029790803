import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { buildDevtoolsReport } from '@equally-ai-front/common/src/helpers/templates';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useAppSelector } from '../../../../hooks/use-app-dispach-selector';
// import { Container } from '../../components/container';
// import { MOBILE_PX } from '../../utils/constants';
import { ComplianceScoreChartSection } from './compliance-score-chart-section';
import { IssuesChartSection } from './issues-chart-section';
import { IssuesInfoSlide } from './issues-info-slide';
// import { IssuesSummarySection } from './issues-summary-section';
import { isUserPermitted } from '../../../../lib/permissions';
import { OverviewWebsiteDateFilter } from './overview-website-date-filter';
// import { PerformanceAndSettingsSection } from './performance-and-settings-section';
import { ProgressReportTableSection } from './progress-report-table-section';

export const OverViewPage = () => {
  const { allIssues } = useAppSelector((state) => state.issues);
  const { totalScansData, loading: overviewLoading } = useAppSelector(
    (state) => state.overview,
  );
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );

  const scanIssuesGroupingByElement = useMemo(() => {
    if (allIssues.length === 0) {
      return [];
    }

    return buildDevtoolsReport('', allIssues).reportGroups.all;
  }, [allIssues]);

  return (
    <>
      <OverviewWebsiteDateFilter title="Overview" />
      {/* <OverviewTitle>Overview</OverviewTitle> */}
      <IssuesInfoSlide totalScansData={totalScansData} />
      {isUserPermitted(
        currentBusiness?.id,
        ['view.accessibility_score'],
        personalDetails,
      ) && <ComplianceScoreChartSection />}
      <ProgressReportTableSection />
      <IssuesChartSection
        scanIssuesGroupingByElement={scanIssuesGroupingByElement}
      />
      {/* <IssuesSummarySection /> */}
      {/* <PerformanceAndSettingsSection
        scanIssuesGroupingByElement={scanIssuesGroupingByElement}
      /> */}
      <LoadingView open={overviewLoading} zIndex="9000" />
    </>
  );
};

// const OverviewTitle = styled.h3`
//   font-size: 22px;
//   font-weight: 400;
//   color: #000a14;
//   margin: 10px 0 20px 0;

//   @media screen and (max-width: ${MOBILE_PX}px) {
//     display: none;
//   }
// `;
