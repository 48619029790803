import React from 'react';
import { ColumnOption, IssueCreationType } from './popup';
import {
  AccessibilityAccordion,
  AccordionState,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { Box } from '@mui/material';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import styled from 'styled-components';
import {
  HeaderWrapper,
  ArrowIconWrapper,
  Divider,
  HeaderText,
} from './jira-tasks';
import { JiraTaskIssue } from './jira-task-issue';
import {
  TaskIssue,
  TaskAdditionalInfo,
} from '@equally-ai-front/common/src/types/business';

interface JiraTaskIssuesProps {
  columns: ColumnOption[];
  issueCreationType: IssueCreationType;
  selectedIssues: TaskIssue[];
  selectedDevToolsIssues: DevtoolsSolution[];
  accordionState: AccordionState;
  handleToggleAccordion: (id: string) => void;
  isGrouped: boolean;
  tasksAdditionalInfo: TaskAdditionalInfo[];
  setTasksAdditionalInfo: React.Dispatch<
    React.SetStateAction<TaskAdditionalInfo[]>
  >;
}

export const JiraTaskIssues = (props: JiraTaskIssuesProps) => {
  const {
    columns,
    issueCreationType,
    selectedIssues,
    selectedDevToolsIssues,
    accordionState,
    handleToggleAccordion,
    isGrouped,
    tasksAdditionalInfo,
    setTasksAdditionalInfo,
  } = props;

  return (
    <Wrapper isgrouped={isGrouped.toString()}>
      {selectedIssues.map((selectedIssue, index) => {
        const devToolsIssue = selectedDevToolsIssues.find(
          ({ id }) => id === selectedIssue.id,
        );

        if (!devToolsIssue) return <></>;
        return (
          <Container key={selectedIssue.id}>
            <AccessibilityAccordion
              title={devToolsIssue.title}
              accordionId={devToolsIssue.id.toString()}
              accordionState={accordionState}
              handleToggleAccordion={() =>
                handleToggleAccordion(devToolsIssue.id.toString())
              }
              accordionHeader={
                <HeaderWrapper>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowIconWrapper>
                      <ArrowIcon
                        src="/chevron-down-black.svg"
                        alt="arrow down icon"
                        shouldrotate={accordionState[devToolsIssue.id]}
                      />
                    </ArrowIconWrapper>
                    <HeaderText>{devToolsIssue.selector}</HeaderText>
                  </Box>
                </HeaderWrapper>
              }
            >
              <Divider />
              <JiraTaskIssue
                index={index}
                columns={columns}
                issueCreationType={issueCreationType}
                devToolsIssue={devToolsIssue}
                tasksAdditionalInfo={tasksAdditionalInfo}
                setTasksAdditionalInfo={setTasksAdditionalInfo}
                isGrouped={isGrouped}
              />
            </AccessibilityAccordion>
          </Container>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isgrouped: string }>`
  padding: ${(props) => (props.isgrouped === 'true' ? '20px' : '0')};
`;

const Container = styled.div`
  overflow: hidden;
  border: 2px solid #e4e7eb;
  border-radius: 8px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  > div > h3 {
    padding: 12px 15px;
  }
`;

const ArrowIcon = styled.img<{ shouldrotate: boolean }>`
  width: 25px;
  height: 20px;
  transform: ${(props) => (props.shouldrotate ? 'rotate(180deg)' : 'none')};
  transition: transform 100ms;
`;
