import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';

import { IconButton, InputAdornment } from '@material-ui/core';

import { ReactComponent as SearchIcon } from '../../../../../img/Search.svg';

import AuditForm from '../../../AuditForm';

const AuditInputForm = ({ label, onAudit, ...props }) => {
  const inputRef = useRef();

  const onClick = useCallback((event) => {
    /** Prevent submit */
    event.preventDefault();
    inputRef.current.submitForm();
  }, []);

  const onMouseDown = useCallback((event) => {
    event.preventDefault();
  }, []);

  return (
    <AuditForm
      ref={inputRef}
      label={label}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchButton
              type="submit"
              aria-label="start webpage audit"
              onClick={onClick}
              onMouseDown={onMouseDown}
              edge="end"
            >
              <SearchIcon />
            </SearchButton>
          </InputAdornment>
        ),
      }}
      lightTheme
      onAudit={onAudit}
      {...props}
    />
  );
};

AuditInputForm.propTypes = {
  label: PropTypes.string,
  onAudit: PropTypes.func,
};

AuditInputForm.defaultProps = {
  label: null,
  onAudit: null,
};

export default AuditInputForm;

/** Styled Components */

const SearchButton = styled(IconButton)`
  background-color: #e4e7eb;
  padding: 8px;
  border-radius: 4px;
`;
