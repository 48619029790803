import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
  input: {
    marginTop: '10px',
    height: '48px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
    marginRight: '16px',
    width: '100%',
    fontWeight: 'normal',
    '& input:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#E4E7EB',
    },
  },
  formControl: {
    minWidth: '100%',
  },
  select: {
    marginRight: '16px',
  },
}));

export const AddDomainLine = ({ index, onInputChange }) => {
  const classes = useStyles();
  const [domain, setDomain] = useState('');
  const [domainLineError, setDomainLineError] = useState(false);

  const handleBlur = () => {
    if (!domain) {
      setDomainLineError(true);
    }
  };

  return (
    <div className="add-website-fields">
      <TextField
        label="Website URL"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        value={domain}
        onChange={(e) => {
          setDomain(e.target.value);
          onInputChange(e.target.value, index);
          if (e.target.value && domainLineError) {
            setDomainLineError(false);
          }
        }}
        inputProps={{
          'aria-label': 'Website URL',
          'aria-errormessage': `domain-line-${index}`,
        }}
        className={classes.input}
        onBlur={handleBlur}
        error={domainLineError}
        helperText={domainLineError ? 'Please enter your website URL' : ''}
        FormHelperTextProps={{ id: `domain-line-${index}` }}
      />
    </div>
  );
};

AddDomainLine.propTypes = {
  index: PropTypes.number.isRequired,
  onInputChange: PropTypes.func.isRequired,
};
