import React, { ChangeEvent } from 'react';

import styled from '@emotion/styled';
import { Box, Typography } from '@material-ui/core';
import { OutlinedButton } from '../../../../components/common/ActionButton';
import { TypographyText2 } from '../../../../components/common/styled';

import { useCart } from '../../../../providers/cart-provider';
import { CloseOutlined } from '@material-ui/icons';
import { getTrialEndDate } from '../../../../lib/date';

interface CartItemProps {
  quantity: number;
  unit: string;
  total: number;
  title: string;
  removeItem: () => void;
  displayRemoveButton: boolean;
  itemId: number;
  name: string;
  trialDuration: number;
  trialDurationUnit: string;
}

const CartItem = ({
  quantity,
  unit,
  total,
  title,
  removeItem,
  displayRemoveButton,
  itemId,
  name,
  trialDuration,
  trialDurationUnit,
}: CartItemProps) => {
  const { actions } = useCart();
  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;

    let cleanValue = value;

    if (cleanValue.length > 1 && cleanValue.startsWith('0')) {
      cleanValue = cleanValue.substring(1);
    }

    actions.setItemQuantity(itemId, +cleanValue);
  };

  const handleInputKeydown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  };

  const handleBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const { value } = evt.target;

    if (+value === 0) {
      actions.setItemQuantity(itemId, 1);
    }
  };

  return (
    <CartItemContainer>
      <CartItemDetails>
        <QuantityInputContainer>
          <Box display="flex" flexDirection="column">
            <Typography
              variant="caption"
              style={{ textTransform: 'capitalize' }}
            >
              {name}
            </Typography>
            <CartHeading>{title}</CartHeading>
          </Box>
          {displayRemoveButton && (
            <Quantity>
              <InputControl onClick={() => actions.decreaseQuantity(itemId)}>
                -
              </InputControl>
              <QuantityInput
                type="text"
                name="quantity"
                id={`quantity-${title}`}
                value={quantity}
                aria-label="Quantity"
                onKeyDown={handleInputKeydown}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <InputControl onClick={() => actions.increaseQuantity(itemId)}>
                +
              </InputControl>
            </Quantity>
          )}
          {!displayRemoveButton && (
            <Quantity>
              <span>{quantity}</span>
            </Quantity>
          )}
        </QuantityInputContainer>

        <Price>
          ${total} {title !== 'service' && <Duration>/ {unit}</Duration>}
        </Price>

        {displayRemoveButton && (
          <CloseButton aria-label="Remove Item" onClick={removeItem}>
            <CloseOutlined />
          </CloseButton>
        )}
      </CartItemDetails>
      {trialDuration > 0 && (
        <span style={{ fontSize: '14px' }}>
          You will not be charged until{' '}
          {getTrialEndDate(trialDuration, trialDurationUnit)}
        </span>
      )}
    </CartItemContainer>
  );
};

export default CartItem;

const CartItemContainer = styled.div`
  margin-bottom: 16px;
`;

const CartHeading = styled(TypographyText2)`
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
  width: 50px;
`;

const Duration = styled.sub`
  text-transform: uppercase;
  font-size: 12px;
`;

const Quantity = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px;

  @media screen and (max-width: 600px) {
    margin: 0;
  }
`;

const CartItemDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  /* div {
    display: flex;
    align-items: center;
    width: 65px;
    min-width: fit-content;
  } */
`;

const CloseButton = styled(OutlinedButton)`
  min-width: 32px;
  padding: 10px;
  border-radius: 100%;
  border: 2px solid #e4e7eb !important;

  &:hover {
    background-color: #454284;
  }

  svg {
    fill: #000a14;
    height: 12px;
    width: 12px;
  }

  @media screen and (max-width: 600px) {
    height: 30px;
    width: 30px;
    min-width: 30px;
    padding: 0;
  }
`;

const Price = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 90%;

  & > sub {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;
    font-weight: 300;

    vertical-align: bottom;
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;

    & > sub {
      font-size: 10px;
    }
  }
`;

const QuantityInputContainer = styled.div`
  display: flex;

  /* flex-direction: column; */
  align-items: center;
  /* margin-left: auto; */
`;

const QuantityInput = styled.input`
  width: 45px;
  height: 45px;
  border: 2px solid #454284;
  background: transparent;
  margin: 0 5px;
  text-align: center;

  @media screen and (max-width: 600px) {
    height: 35px;
    width: 35px;
  }
`;

const InputControl = styled(OutlinedButton)`
  height: 45px;
  width: 45px;
  min-width: 45px;
  padding: 0;
  border-radius: 100%;
  border: none !important;
  border-color: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.07);
  }

  @media screen and (max-width: 600px) {
    height: 35px;
    width: 35px;
    min-width: 35px;
  }
`;
