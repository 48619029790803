import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

interface ScanTableLoaderProps {
  isLoading: boolean;
}

export const ScanTableLoader = ({ isLoading }: ScanTableLoaderProps) => {
  return (
    <Wrapper isloading={isLoading ? 'yes' : ''}>
      <CircularProgress style={{ height: 17, width: 17 }} />
    </Wrapper>
  );
};

const Wrapper = styled(Box)<{ isloading: string }>`
  display: flex;
  align-items: center;
  position: absolute;
  left: -27px;
  top: ${(p) => (p.isloading === 'yes' ? '4.8vh' : '6.3vh')};
`;
