import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Proptypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatExpiration } from '../../../lib/date';
import { ActionButtons } from './action-buttons';
import { usePlans } from '../../../providers/plans-context';

const defaultColumns = [
  {
    id: 'index',
    label: '#',
    minWidth: 30,
  },
  {
    id: 'name',
    label: 'Domain',
    minWidth: 100,
  },
  {
    id: 'expiration',
    label: 'Time Left',
    minWidth: 100,
    format: (value, plan, planId) => {
      if (planId && plan) {
        return formatExpiration(plan.expiration);
      }

      return formatExpiration(value);
    },
  },
  {
    id: 'active',
    label: 'Status',
    minWidth: 60,
    format: (value) => (
      <strong style={{ fontWeight: 500 }}>
        {value ? 'Active' : 'Inactive'}
      </strong>
    ),
  },
  {
    id: 'plan_id',
    label: 'Plan Type',
    minWidth: 60,
    format: (value, plan) => (
      <strong style={{ fontWeight: 500 }}>
        {value ? `${plan?.plan_template.name || 'Plan'}` : 'Free Trial'}
      </strong>
    ),
  },

  {
    id: 'actions',
    label: '',
    minWidth: 100,
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: '100%',
    margin: '30px 10px',
  },
  container: {
    height: 'auto',
    maxHeight: 600,
    border: 'none',
    boxShadow: 'none',
  },
  tableRow: {
    // border: '0.3px solid #E0E0E0',
    background: '#FFF',
    marginBottom: '3px',
  },
  tableCell: {
    padding: '5px',
  },
});

const PlansTable = ({
  data,
  handleSelectPlan,
  currentIndex,
  selectedDomains,
  setSelectedDomains,
  withCheck,
  columns,
  showActionsButtons,
  actionButtonProp,
}) => {
  const classes = useStyles();

  const isSelected = (name) =>
    selectedDomains.filter((domain) => domain.name === name).length > 0;

  const handleSelectAll = () => {
    if (selectedDomains.length > 0) {
      setSelectedDomains([]);
      return;
    }

    setSelectedDomains(data);
  };

  const { activePlans: plans } = usePlans();

  const handleSelectDomain = (domain, checked) => {
    // remove from list if it is selected already
    if (!withCheck) {
      return;
    }
    if (checked) {
      const allDomains = [...selectedDomains];
      const domainIndex = allDomains.findIndex((d) => d.name === domain.name);
      allDomains.splice(domainIndex, 1);

      setSelectedDomains(allDomains);
      return;
    }

    setSelectedDomains([...selectedDomains, domain]);
  };

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table
        stickyHeader
        aria-label="Plans Table"
        style={{ borderSpacing: '0 5px', maxWidth: '98%', margin: '0 auto' }}
      >
        <Head>
          <TableRow className={classes.tableRow}>
            {withCheck && (
              <TableCell
                align="left"
                style={{
                  width: 20,
                  minWidth: 20,
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  fontSize: '14px',
                  color: '#898CA6',
                }}
                className={classes.tableCell}
              >
                <Checkbox
                  checked={selectedDomains.length > 0}
                  color="primary"
                  onClick={handleSelectAll}
                />
              </TableCell>
            )}
            {columns.map((column) => (
              <TableCell
                key={`table-head-${column.id}`}
                align={column.align}
                style={{
                  minWidth: column.minWidth,
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  fontSize: '14px',
                  color: '#898CA6',
                }}
                className={classes.tableCell}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </Head>
        <Body>
          {data
            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.name);
              const domainPlan = plans.find((plan) => plan.id === row?.plan_id);
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={`table-row-${row.id}`}
                  className={classes.tableRow}
                  onClick={() => handleSelectDomain(row, isItemSelected)}
                >
                  {withCheck && (
                    <TableCell
                      key={`table-cell-checked-${row.id}`}
                      className={classes.tableCell}
                    >
                      <Checkbox checked={isItemSelected} color="primary" />
                    </TableCell>
                  )}
                  {columns.map((column) => {
                    if (column.id === 'index') {
                      return (
                        <TableCell
                          key={`table-cell-index-${currentIndex + index + 1}`}
                          className={classes.tableCell}
                        >
                          {currentIndex + index + 1}
                        </TableCell>
                      );
                    }

                    const value =
                      column.id === 'actions'
                        ? showActionsButtons && (
                            <ActionButtons
                              row={row}
                              actionButtonProp={{
                                ...actionButtonProp,
                                ariaLabelledby: `table-cell-name-${row.id}-${
                                  index + 1
                                }`,
                              }}
                            />
                          )
                        : row[column.id];
                    return (
                      <TableCell
                        key={`table-cell-${column.id}-${row.id}-${index + 1}`}
                        id={`table-cell-${column.id}-${row.id}-${index + 1}`}
                        align={column.align}
                        className={classes.tableCell}
                      >
                        {column.format
                          ? column.format(value, domainPlan, row?.plan_id)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </Body>
      </Table>
    </TableContainer>
  );
};

export default PlansTable;

PlansTable.propTypes = {
  data: Proptypes.array.isRequired,
  handleSelectPlan: Proptypes.func.isRequired,
  selectedDomains: Proptypes.array.isRequired,
  setSelectedDomains: Proptypes.func.isRequired,
  withCheck: Proptypes.bool,
  currentIndex: Proptypes.number.isRequired,
  columns: Proptypes.array,
  showActionsButtons: Proptypes.bool,
  actionButtonProp: Proptypes.shape({
    showQuickActions: Proptypes.bool,
    hideActionButton: Proptypes.func,
    handleSelectPlan: Proptypes.func,
    actionLabel: Proptypes.string,
    allowAttach: Proptypes.bool,
    allowDetach: Proptypes.bool,
    actionButtonStyle: Proptypes.any,
  }),
};

PlansTable.defaultProps = {
  withCheck: false,
  columns: defaultColumns,
  showActionsButtons: false,
  actionButtonProp: {
    showQuickActions: false,
    hideActionButton: () => {},
    handleSelectPlan: () => {},
    actionLabel: '',
    allowAttach: false,
    allowDetach: false,
  },
};

const Head = styled(TableHead)`
  th {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    &:first-of-type {
      border-left: 1px solid #e0e0e0;
    }
    &:last-child {
      border-right: 1px solid #e0e0e0;
    }
  }
  tr:hover {
    background-color: #fafafa;
  }
`;

const Body = styled(TableBody)`
  td {
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    &:first-of-type {
      border-left: 1px solid #e0e0e0;
    }
    &:last-child {
      border-right: 1px solid #e0e0e0;
    }
  }
  tr:hover {
    background-color: #fafafa;
  }
`;
