import { NewTeamMember } from '@equally-ai-front/common/src/types/business';
import { Button, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ROLES } from '../../../hooks/use-add-team-member';
import { GenericInput } from '../../accessibility-components/generic-input';

const useStyles = makeStyles(() => ({
  input: {
    // height: '48px',
    '& .MuiInputLabel-formControl': {},
    '& .MuiOutlinedInput-input': {},
    '& .PrivateNotchedOutline-root-62': {
      // top: '2px',
    },
    width: '100%',
    fontWeight: 'normal',
    '& input:disabled': {
      color: 'rgba(0, 10, 20, 0.65)',
      backgroundColor: '#E4E7EB',
    },
  },
}));

interface AddTeamMemberProps {
  newMembers: NewTeamMember[];
  isEmailValid: (member: NewTeamMember) => boolean;
  onAddMemberRow: () => void;
  onNewMemberChange: (
    index: number,
    key: 'role' | 'email',
    value: string,
  ) => void;
}

const AddTeamMemberForm = (props: AddTeamMemberProps) => {
  const classes = useStyles();
  const { newMembers, onNewMemberChange, onAddMemberRow, isEmailValid } = props;
  const [showEmailErrorMessage, setShowEmailErrorMessage] =
    useState<boolean>(false);

  const handleInputBlur = (member: NewTeamMember) => {
    setShowEmailErrorMessage(!isEmailValid(member));
  };

  const isInvalid = (member: NewTeamMember) =>
    !member.email && !isEmailValid(member) && showEmailErrorMessage;
  return (
    <FormContainer>
      <MultipleFieldsContainer>
        {newMembers.map((member, index) => {
          return (
            <FieldsContainer key={`new-team-member-${index}`}>
              <FieldContainer>
                <GenericInput
                  label="Email"
                  error={!isEmailValid(member)}
                  value={member.email}
                  onChange={(e) =>
                    onNewMemberChange(index, 'email', e.target.value)
                  }
                  className={classes.input}
                  ariaProps={{
                    'aria-errormessage': `member-email-error-${index}`,
                    'aria-label': 'Email',
                    'aria-invalid': isEmailValid(member) ? 'false' : 'true',
                  }}
                  onBlur={() => handleInputBlur(member)}
                  helperText={
                    isInvalid(member) ? 'Please enter a valid email' : ''
                  }
                />
              </FieldContainer>
              <FieldContainer>
                <GenericInput
                  label="Role"
                  select
                  value={member.role}
                  onChange={(e) => {
                    onNewMemberChange(index, 'role', e.target.value);
                  }}
                  ariaProps={{ 'aria-label': 'Role' }}
                  className={classes.input}
                >
                  {ROLES.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </GenericInput>
              </FieldContainer>
            </FieldsContainer>
          );
        })}
      </MultipleFieldsContainer>
      <StyledButton variant="outlined" onClick={onAddMemberRow}>
        + Add another
      </StyledButton>
    </FormContainer>
  );
};

export default AddTeamMemberForm;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const MultipleFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const FieldContainer = styled.div`
  width: 100%;
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  margin-bottom: 2.5vh;
`;
const StyledButton = styled(Button)`
  background-color: black;
  text-transform: none;
  font-weight: 500;
  max-width: fit-content;
  font-size: 16px;
  padding: 4px 16px;
  color: white;
  border-radius: 8px;

  &&:hover {
    background-color: white;
    color: #000a14;
  }
`;
