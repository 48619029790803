import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { PrimaryButton } from './Controls';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    marginBottom: '28px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
}));

export const PasswordReset = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const user = useSelector((state) => state.user.user);
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.page({
      name: 'Password Reset Page',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-page">
      <div className="content-page-container">
        <div className="header-page">
          <div className="header-page-text">Password reset</div>
        </div>
        <div className="content-page-form">
          <TextField
            className={classes.input}
            label="Email"
            disabled
            defaultValue={user.email}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <TextField
            className={classes.input}
            label="New password"
            type="password"
            autoComplete="new-password"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            className={classes.input}
            label="Confirm new password"
            type="password"
            autoComplete="new-password"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <PrimaryButton
            color="primary"
            style={{ width: 204, fontSize: isMobile ? '14px' : '16px' }}
            variant="contained"
          >
            Update Password
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
