import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { TypographyH2, TypographySubtitle } from '../common/styled';
import { PrimaryButton } from '../redesign/Controls';
import { StyledDialog } from '../redesign/FreeTrialModal/StartFreeTrialModal';
import { InstallExtensionContent } from './components/install-extention-content';

interface InstallExtensionModalProps {
  open: boolean;
  handleClose: () => void;
}

export const InstallExtensionModal = ({
  handleClose,
  open,
}: InstallExtensionModalProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <StyledDialog open={open} onClose={handleClose} fullScreen={isMobile}>
      <CloseButton
        aria-label="close"
        onClick={handleClose}
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </CloseButton>

      <Container>
        <Header>
          <Heading>Just one more step</Heading>
          <Subtitle>
            To build web experiences with Flowy, you’ll use a free Chrome
            extension
          </Subtitle>
        </Header>
        <InstallExtensionContent />
        <Footer>
          <PrimaryButton
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Start building
          </PrimaryButton>
        </Footer>
      </Container>
    </StyledDialog>
  );
};

const Container = styled.div`
  min-height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  max-width: 600px;

  @media screen and (max-width: 600px) {
    max-width: 100%;
    height: 100%;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Heading = styled(TypographyH2)`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Subtitle = styled(TypographySubtitle)`
  font-size: 16px;
  font-weight: 300;
`;

const CloseButton = styled(IconButton)`
  &:focus {
    border: 2px solid #3e3c77;
  }
`;
