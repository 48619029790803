import {
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import React from 'react';

export const Support = () => {
  return (
    <div>
      <div className="my-plan-tab-panel-header-text">
        Happy to Help You 24/7
      </div>
      <div>We are available 24/7. Respond time in less than 24 hours.</div>
      <div>
        Before sending us question, please check our FAQ page for value
        information.
      </div>

      <div className="support-input-container">
        <TextField
          required
          style={{ width: '48%' }}
          label="Name"
          defaultValue=""
          variant="outlined"
        />
        <TextField
          required
          style={{ width: '48%' }}
          label="Email"
          defaultValue=""
          variant="outlined"
        />
      </div>
      <div className="support-input-container">
        <FormControl variant="outlined" style={{ width: '30%' }}>
          <InputLabel htmlFor="outlined-selectTopic-native-simple">
            Select Topic
          </InputLabel>
          <Select
            native
            label="Select Topic"
            inputProps={{
              name: 'selectTopic',
              id: 'outlined-selectTopic-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            <option value={10}>1</option>
            <option value={20}>2</option>
            <option value={30}>3</option>
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ width: '30%' }}>
          <InputLabel htmlFor="outlined-selectSubject-native-simple">
            Select Subject
          </InputLabel>
          <Select
            native
            label="Select Subject"
            inputProps={{
              name: 'selectSubject',
              id: 'outlined-selectSubject-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            <option value={10}>1</option>
            <option value={20}>2</option>
            <option value={30}>3</option>
          </Select>
        </FormControl>
        <TextField
          style={{ width: '30%' }}
          label="Or enter your own subject (Optional)"
          defaultValue=""
          variant="outlined"
        />
      </div>
      <div className="support-input-container">
        <TextField
          label="Your Question"
          multiline
          defaultValue=""
          rows="4"
          style={{ width: '100%' }}
          variant="outlined"
        />
      </div>
      <div className="support-input-container">
        <Button
          variant="contained"
          color="primary"
          style={{ width: '182px', height: '54px' }}
        >
          Send Message
        </Button>
      </div>
    </div>
  );
};
