import React from 'react';
import { Document, Page, Font, StyleSheet } from '@react-pdf/renderer';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { isShadeOfWhiteOrGrey } from '@equally-ai-front/common/src/utils/helpers';
import { IssuesSummary } from './components/issues-summary';
import { Table } from './components/table';
import { IssuesCategoriesCount } from '../../hooks';
import { CustomReportFields } from '../exports/export';

interface PdfReportProps {
  pdfReport: DevtoolsSolution[];
  issuesCategoriesCount: IssuesCategoriesCount;
  accessibilityScore: number;
  devDomainName: string;
  customReportFields: CustomReportFields | null;
}

Font.register({
  family: 'DM Sans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxhTmf3ZGMZpg.ttf',
      fontWeight: 'normal',
    },
    {
      src: 'https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAkJxhTmf3ZGMZpg.ttf',
      fontWeight: 'medium',
    },
    {
      src: 'https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwARZthTmf3ZGMZpg.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAC5thTmf3ZGMZpg.ttf',
      fontWeight: 'ultrabold',
    },
  ],
});

export const PdfReport = (props: PdfReportProps) => {
  const {
    pdfReport,
    issuesCategoriesCount,
    accessibilityScore,
    devDomainName,
    customReportFields,
  } = props;

  const isColorShadeOfWhiteOrGrey = isShadeOfWhiteOrGrey(
    customReportFields?.themeColor || '',
  );

  const styles = StyleSheet.create({
    pageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 10px 0 10px',
      width: '100%',
      maxWidth: '95%',
      margin: 'auto',
    },
    page: {
      backgroundColor: isColorShadeOfWhiteOrGrey ? '#000000' : '#ffffff',
    },
  });

  return (
    <Document
      title={`Web Accessibility Report For ${devDomainName}`}
      subject={`A break down of the web accessibility issues found on ${pdfReport[0].domain_url}`}
      author="Equally AI"
      creator="Equally AI"
      producer="Equally AI"
      keywords="Accessibility, Web Accessibility, Digital Accessibility, WCAG, ADA Compliance, Section 508, Screen Readers, Keyboard Navigation, Voice Recognition, Accessible PDFs, Accessible Websites, Accessibility Best Practices, Accessibility Testing, Inclusive Design, Visual Impairments, Hearing Impairments, Cognitive Disabilities, Motor Disabilities, Accessibility Compliance, Accessibility Laws, Accessibility Tools, Accessibility Software, E-commerce Accessibility, Education Accessibility, Mobile Accessibility, UX Accessibility"
    >
      <Page size="A4" style={styles.page}>
        <IssuesSummary
          pdfReport={pdfReport}
          issuesCategoriesCount={issuesCategoriesCount}
          accessibilityScore={accessibilityScore}
          devDomainName={devDomainName}
          customReportFields={customReportFields}
          isColorShadeOfWhiteOrGrey={isColorShadeOfWhiteOrGrey}
        />
      </Page>
      <Page size="A4" style={[styles.pageWrapper, styles.page]}>
        <Table
          pdfReport={pdfReport}
          customReportFields={customReportFields}
          isColorShadeOfWhiteOrGrey={isColorShadeOfWhiteOrGrey}
        />
      </Page>
    </Document>
  );
};
