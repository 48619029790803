import { Issue } from '../types/issues';
import { ScanData, ScanTotals, TotalScansData } from '../types/scans';
import { buildUnknownUrl, extractDomainAndPath } from '../utils';
import {
  AUDIT_STATUS_ERROR,
  AUDIT_STATUS_SUCCESS,
  AUDIT_STATUS_WARNING,
} from '../utils/constants';
import { DevtoolsSolution } from '../utils/devToolsTypeConfig';
import { removeSlashAtUrlEnd } from './domains';

const SCAN_TOTALS_INITIAL_STATE: ScansTotals = Object.freeze({
  totalIssues: 0,
  totalCritical: 0,
  totalNotices: 0,
  totalUsability: 0,
  totalManual: 0,
  totalDismissed: 0,
  totalPageScanned: 0,
});

export interface ScansTotals {
  totalIssues: number;
  totalCritical: number;
  totalNotices: number;
  totalUsability: number;
  totalManual: number;
  totalDismissed: number;
  totalPageScanned: number;
}

export interface AccessibilityReport {
  overallScore: number;
  levelA: ComplianceProgressTotals;
  levelAA: ComplianceProgressTotals;
}

interface ComplianceProgressTotals {
  total: number;
  done: number;
  toFix: number;
  score: number;
}

const complianceProgressTotals: ComplianceProgressTotals = Object.freeze({
  total: 0,
  done: 0,
  toFix: 0,
  score: 0,
});

export const accessibilityReport: AccessibilityReport = Object.freeze({
  levelAA: { ...complianceProgressTotals },
  levelA: { ...complianceProgressTotals },
  overallScore: 0,
});

export interface CriteriaScore {
  displayName: string;
  score?: number;
  description?: string;
  link?: string;
}

export type WCAGCriteria = Record<string, Record<string, CriteriaScore>>;
export type WCAGCriteriaTechniques = Record<string, Record<string, string>>;

// @ts-ignore
export const wcag_criteria: WCAGCriteria = {
  'WCAG2AA.Principle1.Guideline1_1': {
    '1_1_1': {
      displayName: 'Non-text Content',
      description:
        'All non-text content that is presented to the user has a text alternative that serves the equivalent purpose, except for the situations listed below.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/non-text-content',
    },
  },
  'WCAG2AA.Principle1.Guideline1_2': {
    '1_2_1': {
      displayName: 'Audio-only and Video-only (Prerecorded)',
      description:
        'For prerecorded audio-only and prerecorded video-only media, the following are true, except when the audio or video is a media alternative for text and is clearly labeled as such:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/audio-only-and-video-only-prerecorded',
    },
    '1_2_2': {
      displayName: 'Captions (Prerecorded)',
      description:
        'Captions are provided for all prerecorded audio content in synchronized media, except when the media is a media alternative for text and is clearly labeled as such.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/captions-prerecorded',
    },
    '1_2_3': {
      displayName: 'Audio Description or Media Alternative (Prerecorded)',
      description:
        'An alternative for time-based media or audio description of the prerecorded video content is provided for synchronized media, except when the media is a media alternative for text and is clearly labeled as such.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/audio-description-or-media-alternative-prerecorded',
    },
    '1_2_4': {
      displayName: 'Captions (Live)',
      description:
        'Captions are provided for all live audio content in synchronized media.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/captions-live',
    },
    '1_2_5': {
      displayName: 'Audio Description (Prerecorded)',
      description:
        'Audio description is provided for all prerecorded video content in synchronized media.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/audio-description-prerecorded',
    },
    '1_2_6': {
      displayName: 'Sign Language (Prerecorded)',
      description:
        'Sign language interpretation is provided for all prerecorded audio content in synchronized media.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/sign-language-prerecorded',
    },
    '1_2_7': {
      displayName: 'Extended Audio Description (Prerecorded)',
      description:
        'Where pauses in foreground audio are insufficient to allow audio descriptions to convey the sense of the video, extended audio description is provided for all prerecorded video content in synchronized media.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/extended-audio-description-prerecorded',
    },
    '1_2_8': {
      displayName: 'Media Alternative (Prerecorded)',
      description:
        'An alternative for time-based media is provided for all prerecorded synchronized media and for all prerecorded video-only media.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/media-alternative-prerecorded',
    },
    '1_2_9': {
      displayName: 'Audio-only (Live)',
      description:
        'An alternative for time-based media that presents equivalent information for live audio-only content is provided.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/audio-only-live',
    },
  },
  'WCAG2AA.Principle1.Guideline1_3': {
    '1_3_1': {
      displayName: 'Info and Relationships',
      description:
        'Information, structure, and relationships conveyed through presentation can be programmatically determined or are available in text.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/info-and-relationships',
    },
    '1_3_2': {
      displayName: 'Meaningful Sequence',
      description:
        'When the sequence in which content is presented affects its meaning, a correct reading sequence can be programmatically determined.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/meaningful-sequence',
    },
    '1_3_3': {
      displayName: 'Sensory Characteristics',
      description:
        'Instructions provided for understanding and operating content do not rely solely on sensory characteristics of components such as shape, size, visual location, orientation, or sound.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/sensory-characteristics',
    },
    '1_3_4': {
      displayName: 'Orientation',
      description:
        'Content does not restrict its view and operation to a single display orientation, such as portrait or landscape, unless a specific display orientation is essential.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/orientation',
    },
    '1_3_5': {
      displayName: 'Identify Input Purpose',
      description:
        'The purpose of each input field collecting information about the user can be programmatically determined when:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/identify-input-purpose',
    },
    '1_3_6': {
      displayName: 'Identify Purpose',
      description:
        'In content implemented using markup languages, the purpose of User Interface Components, icons, and regions can be programmatically determined.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/identify-purpose',
    },
  },
  'WCAG2AA.Principle1.Guideline1_4': {
    '1_4_1': {
      displayName: 'Use of Color',
      description:
        'Color is not used as the only visual means of conveying information, indicating an action, prompting a response, or distinguishing a visual element.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/use-of-color',
    },
    '1_4_2': {
      displayName: 'Audio Control',
      description:
        'If any audio on a Web page plays automatically for more than 3 seconds, either a mechanism is available to pause or stop the audio, or a mechanism is available to control audio volume independently from the overall system volume level.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/audio-control',
    },
    '1_4_3': {
      displayName: 'Contrast (Minimum)',
      description:
        'The visual presentation of text and images of text has a contrast ratio of at least 4.5:1, except for the following:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum',
    },
    '1_4_4': {
      displayName: 'Resize text',
      description:
        'Except for captions and images of text, text can be resized without assistive technology up to 200 percent without loss of content or functionality.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/resize-text',
    },
    '1_4_5': {
      displayName: 'Images of Text',
      description:
        'If the technologies being used can achieve the visual presentation, text is used to convey information rather than images of text except for the following:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/images-of-text',
    },
    '1_4_6': {
      displayName: 'Contrast (Enhanced)',
      description:
        'The visual presentation of text and images of text has a contrast ratio of at least 7:1, except for the following:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/contrast-enhanced',
    },
    '1_4_7': {
      displayName: 'Low or No Background Audio',
      description:
        'For prerecorded audio-only content that (1) contains primarily speech in the foreground, (2) is not an audio CAPTCHA or audio logo, and (3) is not vocalization intended to be primarily musical expression such as singing or rapping, at least one of the following is true:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/low-or-no-background-audio',
    },
    '1_4_8': {
      displayName: 'Visual Presentation',
      description:
        'For the visual presentation of blocks of text, a mechanism is available to achieve the following:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/visual-presentation',
    },
    '1_4_9': {
      displayName: 'Images of Text (No Exception)',
      description:
        'Images of text are only used for pure decoration or where a particular presentation of text is essential to the information being conveyed.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/images-of-text-no-exception',
    },
    '1_4_10': {
      displayName: 'Reflow',
      description:
        'Content can be presented without loss of information or functionality, and without requiring scrolling in two dimensions for:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/reflow',
    },
    '1_4_11': {
      displayName: 'Non-text Contrast',
      description:
        'The visual presentation of the following have a contrast ratio of at least 3:1 against adjacent color(s):',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast',
    },
    '1_4_12': {
      displayName: 'Text Spacing',
      description:
        'In content implemented using markup languages that support the following text style properties, no loss of content or functionality occurs by setting all of the following and by changing no other style property:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/text-spacing',
    },
    '1_4_13': {
      displayName: 'Content on Hover or Focus',
      description:
        'Where receiving and then removing pointer hover or keyboard focus triggers additional content to become visible and then hidden, the following are true:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/content-on-hover-or-focus',
    },
  },
  'WCAG2AA.Principle2.Guideline2_1': {
    '2_1_1': {
      displayName: 'Keyboard',
      description:
        "All functionality of the content is operable through a keyboard interface without requiring specific timings for individual keystrokes, except where the underlying function requires input that depends on the path of the user's movement and not just the endpoints.",
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/keyboard',
    },
    '2_1_2': {
      displayName: 'No Keyboard Trap',
      description:
        'If keyboard focus can be moved to a component of the page using a keyboard interface, then focus can be moved away from that component using only a keyboard interface, and, if it requires more than unmodified arrow or tab keys or other standard exit methods, the user is advised of the method for moving focus away.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/no-keyboard-trap',
    },
    '2_1_3': {
      displayName: 'Keyboard (No Exception)',
      description:
        'All functionality of the content is operable through a keyboard interface without requiring specific timings for individual keystrokes.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/keyboard-no-exception',
    },
    '2_1_4': {
      displayName: 'Character Key Shortcuts',
      description:
        'If a keyboard shortcut is implemented in content using only letter (including upper- and lower-case letters), punctuation, number, or symbol characters, then at least one of the following is true:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/character-key-shortcuts',
    },
  },
  'WCAG2AA.Principle2.Guideline2_2': {
    '2_2_1': {
      displayName: 'Timing Adjustable',
      description:
        'For each time limit that is set by the content, at least one of the following is true:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/timing-adjustable',
    },
    '2_2_2': {
      displayName: 'Pause, Stop, Hide',
      description:
        'For moving, blinking, scrolling, or auto-updating information, all of the following are true:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/pause-stop-hide',
    },
    '2_2_3': {
      displayName: 'No Timing',
      description:
        'Timing is not an essential part of the event or activity presented by the content, except for non-interactive synchronized media and real-time events.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/no-timing',
    },
    '2_2_4': {
      displayName: 'Interruptions',
      description:
        'Interruptions can be postponed or suppressed by the user, except interruptions involving an emergency.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/interruptions',
    },
    '2_2_5': {
      displayName: 'Re-authenticating',
      description:
        'When an authenticated session expires, the user can continue the activity without loss of data after re-authenticating.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/re-authenticating',
    },
    '2_2_6': {
      displayName: 'Timeouts',
      description:
        'Users are warned of the duration of any user inactivity that could cause data loss, unless the data is preserved for more than 20 hours when the user does not take any actions.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/timeouts',
    },
  },
  'WCAG2AA.Principle2.Guideline2_3': {
    '2_3_1': {
      displayName: 'Three Flashes or Below Threshold',
      description:
        'Web pages do not contain anything that flashes more than three times in any one second period, or the flash is below the general flash and red flash thresholds.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/three-flashes-or-below-threshold',
    },
    '2_3_2': {
      displayName: 'Three Flashes',
      description:
        'Web pages do not contain anything that flashes more than three times in any one second period.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/three-flashes',
    },
    '2_3_3': {
      displayName: 'Animation from Interactions',
      description:
        'Motion animation triggered by interaction can be disabled, unless the animation is essential to the functionality or the information being conveyed.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/animation-from-interactions',
    },
  },
  'WCAG2AA.Principle2.Guideline2_4': {
    '2_4_1': {
      displayName: 'Bypass Blocks',
      description:
        'A mechanism is available to bypass blocks of content that are repeated on multiple Web pages.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/bypass-blocks',
    },
    '2_4_2': {
      displayName: 'Page Titled',
      description: 'Web pages have titles that describe topic or purpose.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/page-titled',
    },
    '2_4_3': {
      displayName: 'Focus Order',
      description:
        'If a Web page can be navigated sequentially and the navigation sequences affect meaning or operation, focusable components receive focus in an order that preserves meaning and operability.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/focus-order',
    },
    '2_4_4': {
      displayName: 'Link Purpose (In Context)',
      description:
        'The purpose of each link can be determined from the link text alone or from the link text together with its programmatically determined link context, except where the purpose of the link would be ambiguous to users in general.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-in-context',
    },
    '2_4_5': {
      displayName: 'Multiple Ways',
      description:
        'More than one way is available to locate a Web page within a set of Web pages except where the Web Page is the result of, or a step in, a process.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/multiple-ways',
    },
    '2_4_6': {
      displayName: 'Headings and Labels',
      description: 'Headings and labels describe topic or purpose.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/headings-and-labels',
    },
    '2_4_7': {
      displayName: 'Focus Visible',
      description:
        'Any keyboard operable user interface has a mode of operation where the keyboard focus indicator is visible.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/focus-visible',
    },
    '2_4_8': {
      displayName: 'Location',
      description:
        "Information about the user's location within a set of Web pages is available.",
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/location',
    },
    '2_4_9': {
      displayName: 'Link Purpose (Link Only)',
      description:
        'A mechanism is available to allow the purpose of each link to be identified from link text alone, except where the purpose of the link would be ambiguous to users in general.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-link-only',
    },
    '2_4_10': {
      displayName: 'Section Headings',
      description: 'Section headings are used to organize the content.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/section-headings',
    },
  },
  'WCAG2AA.Principle2.Guideline2_5': {
    '2_5_1': {
      displayName: 'Pointer Gestures',
      description:
        'All functionality that uses multipoint or path-based gestures for operation can be operated with a single pointer without a path-based gesture, unless a multipoint or path-based gesture is essential.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/pointer-gestures',
    },
    '2_5_2': {
      displayName: 'Pointer Cancellation',
      description:
        'For functionality that can be operated using a single pointer, at least one of the following is true:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/pointer-cancellation',
    },
    '2_5_3': {
      displayName: 'Label in Name',
      description:
        'For user interface components with labels that include text or images of text, the name contains the text that is presented visually.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/label-in-name',
    },
    '2_5_4': {
      displayName: 'Motion Actuation',
      description:
        'Functionality that can be operated by device motion or user motion can also be operated by user interface components and responding to the motion can be disabled to prevent accidental actuation, except when:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/motion-actuation',
    },
    '2_5_5': {
      displayName: 'Target Size',
      description:
        'The size of the target for pointer inputs is at least 44 by 44 CSS pixels except when:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/target-size',
    },
    '2_5_6': {
      displayName: 'Concurrent Input Mechanisms',
      description:
        'Web content does not restrict use of input modalities available on a platform except where the restriction is essential, required to ensure the security of the content, or required to respect user settings.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/concurrent-input-mechanisms',
    },
  },
  'WCAG2AA.Principle3.Guideline3_1': {
    '3_1_1': {
      displayName: 'Language of Page',
      description:
        'The default human language of each Web page can be programmatically determined.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/language-of-page',
    },
    '3_1_2': {
      displayName: 'Language of Parts',
      description:
        'The human language of each passage or phrase in the content can be programmatically determined except for proper names, technical terms, words of indeterminate language, and words or phrases that have become part of the vernacular of the immediately surrounding text.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/language-of-parts',
    },
    '3_1_3': {
      displayName: 'Unusual Words',
      description:
        'A mechanism is available for identifying specific definitions of words or phrases used in an unusual or restricted way, including idioms and jargon.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/unusual-words',
    },
    '3_1_4': {
      displayName: 'Abbreviations',
      description:
        'A mechanism for identifying the expanded form or meaning of abbreviations is available.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/abbreviations',
    },
    '3_1_5': {
      displayName: 'Reading Level',
      description:
        'When text requires reading ability more advanced than the lower secondary education level after removal of proper names and titles, supplemental content, or a version that does not require reading ability more advanced than the lower secondary education level, is available.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/reading-level',
    },
    '3_1_6': {
      displayName: 'Pronunciation',
      description:
        'A mechanism is available for identifying specific pronunciation of words where meaning of the words, in context, is ambiguous without knowing the pronunciation.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/pronunciation',
    },
  },
  'WCAG2AA.Principle3.Guideline3_2': {
    '3_2_1': {
      displayName: 'On Focus',
      description:
        'When any component receives focus, it does not initiate a change of context.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/on-focus',
    },
    '3_2_2': {
      displayName: 'On Input',
      description:
        'Changing the setting of any user interface component does not automatically cause a change of context unless the user has been advised of the behavior before using the component.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/on-input',
    },
    '3_2_3': {
      displayName: 'Consistent Navigation',
      description:
        'Navigational mechanisms that are repeated on multiple Web pages within a set of Web pages occur in the same relative order each time they are repeated, unless a change is initiated by the user.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/consistent-navigation',
    },
    '3_2_4': {
      displayName: 'Consistent Identification',
      description:
        'Components that have the same functionality within a set of Web pages are identified consistently.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/consistent-identification',
    },
    '3_2_5': {
      displayName: 'Change on Request',
      description:
        'Changes of context are initiated only by user request or a mechanism is available to turn off such changes.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/change-on-request',
    },
  },
  'WCAG2AA.Principle3.Guideline3_3': {
    '3_3_1': {
      displayName: 'Error Identification',
      description:
        'If an input error is automatically detected, the item that is in error is identified and the error is described to the user in text.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/error-identification',
    },
    '3_3_2': {
      displayName: 'Labels or Instructions',
      description:
        'Labels or instructions are provided when content requires user input.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions',
    },
    '3_3_3': {
      displayName: 'Error Suggestion',
      description:
        'If an input error is automatically detected and suggestions for correction are known, then the suggestions are provided to the user, unless it would jeopardize the security or purpose of the content.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/error-suggestion',
    },
    '3_3_4': {
      displayName: 'Error Prevention (Legal, Financial, Data)',
      description:
        'For Web pages that cause legal commitments or financial transactions for the user to occur, that modify or delete user-controllable data in data storage systems, or that submit user test responses, at least one of the following is true:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/error-prevention-legal-financial-data',
    },
    '3_3_5': {
      displayName: 'Help',
      description: 'Context-sensitive help is available.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/help',
    },
    '3_3_6': {
      displayName: 'Error Prevention (All)',
      description:
        'For Web pages that require the user to submit information, at least one of the following is true:',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/error-prevention-all',
    },
  },
  'WCAG2AA.Principle4.Guideline4_1': {
    '4_1_1': {
      displayName: 'Parsing',
      description:
        'In content implemented using markup languages, elements have complete start and end tags, elements are nested according to their specifications, elements do not contain duplicate attributes, and any IDs are unique, except where the specifications allow these features.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/parsing',
    },
    '4_1_2': {
      displayName: 'Name, Role, Value',
      description:
        'For all user interface components (including but not limited to: form elements, links and components generated by scripts), the name and role can be programmatically determined; states, properties, and values that can be set by the user can be programmatically set; and notification of changes to these items is available to user agents, including assistive technologies.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/name-role-value',
    },
    '4_1_3': {
      displayName: 'Status Messages',
      description:
        'In content implemented using markup languages, status messages can be programmatically determined through role or properties such that they can be presented to the user by assistive technologies without receiving focus.',
      link: 'https://www.w3.org/WAI/WCAG21/Understanding/status-messages',
    },
  },
};

export const wcag_criteria_techniques: WCAGCriteriaTechniques = {
  'WCAG2AA.Principle1.Guideline1_1.1_1_1': {
    G94: 'Providing short text alternative for non-text content that serves the same purpose and presents the same information as the non-text content',
    ARIA6: 'Using aria-label to provide labels for objects',
    ARIA10:
      'Using aria-labelledby to provide a text alternative for non-text content',
    G196: 'Using a text alternative on one item within a group of images that describes all items in the group',
    H2: 'Combining adjacent image and text links for the same resource',
    H37: 'Using alt attributes on img elements',
    H53: 'Using the body of the object element',
    H86: 'Providing text alternatives for emojis, emoticons, ASCII art, and leetspeak',
    PDF1: 'Applying text alternatives to images with the Alt entry in PDF documents',
    ARIA15: 'Using aria-describedby to provide descriptions of images',
    G73: 'Providing a long description in another location with a link to it that is immediately adjacent to the non-text content',
    G74: 'Providing a long description in text near the non-text content, with a reference to the location of the long description in the short description',
    G92: 'Providing long description for non-text content that serves the same purpose and presents the same information',
    ARIA9:
      'Using aria-labelledby to concatenate a label from several text nodes',
    H24: 'Providing text alternatives for the area elements of image maps',
    H30: 'Providing link text that describes the purpose of a link for anchor elements',
    H36: 'Using alt attributes on images used as submit buttons',
    H44: 'Using label elements to associate text labels with form controls',
    H65: 'Using the title attribute to identify form controls when the label element cannot be used',
    G68: 'Providing a short text alternative that describes the purpose of live audio-only and live video-only content',
    G100: 'Providing a short text alternative which is the accepted name or a descriptive name of the non-text content ',
    G143: 'Providing a text alternative that describes the purpose of the CAPTCHA',
    G144: 'Ensuring that the Web Page contains another CAPTCHA serving the same purpose using a different modality',
    C9: 'Using CSS to include decorative images',
    H67: 'Using null alt text and no title attribute on img elements for images that assistive technology should ignore',
    PDF4: 'Hiding decorative images with the Artifact tag in PDF documents',
    C18: 'Using CSS margin and padding rules instead of spacer images for layout design,',
    F3: 'Failure of Success Criterion 1.1.1 due to using CSS to include images that convey important information',
    F13: 'Failure of Success Criterion 1.1.1 and 1.4.1 due to having a text alternative that does not include information that is conveyed by color differences in the image',
    F20: 'Failure of Success Criterion 1.1.1 and 4.1.2 due to not updating text alternatives when changes to non-text content occur',
    F30: 'Failure of Success Criterion 1.1.1 and 1.2.1 due to using text alternatives that are not alternatives (e.g., filenames or placeholder text)',
    F38: 'Failure of Success Criterion 1.1.1 due to not marking up decorative images in HTML in a way that allows assistive technology to ignore them',
    F39: 'Failure of Success Criterion 1.1.1 due to providing a text alternative that is not null (e.g., alt="spacer" or alt="image") for images that should be ignored by assistive technology',
    F65: 'Failure of Success Criterion 1.1.1 due to omitting the alt attribute or text alternative on img elements, area elements, and input elements of type "image"',
    F67: 'Failure of Success Criterion 1.1.1 and 1.2.1 due to providing long descriptions for non-text content that does not serve the same purpose or does not present the same information',
    F71: 'Failure of Success Criterion 1.1.1 due to using text look-alikes to represent text without providing a text alternative',
    F72: 'Failure of Success Criterion 1.1.1 due to using ASCII art without providing a text alternative',
  },
  'WCAG2AA.Principle1.Guideline1_2.1_2_1': {
    G158: 'Providing an alternative for time-based media for audio-only content',
    G159: 'Providing an alternative for time-based media for video-only content',
    G166: 'Providing audio that describes the important video content and describing it as such',
    H96: 'Using the track element to provide audio descriptions',
    F30: 'Failure of Success Criterion 1.1.1 and 1.2.1 due to using text alternatives that are not alternatives (e.g., filenames or placeholder text)',
    F67: 'Failure of Success Criterion 1.1.1 and 1.2.1 due to providing long descriptions for non-text content that does not serve the same purpose or does not present the same information',
  },
  'WCAG2AA.Principle1.Guideline1_2.1_2_2': {
    G93: 'Providing open (always visible) captions',
    G87: 'Providing closed captions ',
    SM11: 'Providing captions through synchronized text streams in SMIL 1.0',
    SM12: 'Providing captions through synchronized text streams in SMIL 2.0',
    H95: 'Using the track element to provide captions',
    F8: 'Failure of Success Criterion 1.2.2 due to captions omitting some dialogue or important sound effects',
    F75: 'Failure of Success Criterion 1.2.2 by providing synchronized media without captions when the synchronized media presents more information than is presented on the page',
    F74: 'Failure of Success Criterion 1.2.2 and 1.2.8 due to not labeling a synchronized media alternative to text as an alternative',
  },
  'WCAG2AA.Principle1.Guideline1_2.1_2_3': {
    G69: 'Providing an alternative for time based media ',
    G58: 'Placing a link to the alternative for time-based media immediately next to the non-text content',
    H53: 'Using the body of the object element',
    G78: 'Providing a second, user-selectable, audio track that includes audio descriptions',
    G173: 'Providing a version of a movie with audio descriptions ',
    SM6: 'Providing audio description in SMIL 1.0',
    SM7: 'Providing audio description in SMIL 2.0',
    G8: 'Providing a movie with extended audio descriptions ',
    SM1: 'Adding extended audio description in SMIL 1.0',
    SM2: 'Adding extended audio description in SMIL 2.0',
    G203: 'Using a static text alternative to describe a talking head video',
    H96: 'Using the track element to provide audio descriptions',
  },
  'WCAG2AA.Principle1.Guideline1_2.1_2_4': {
    G9: 'Creating captions for live synchronized media',
    G93: 'Providing open (always visible) captions',
    G87: 'Providing closed captions ',
    SM11: 'Providing captions through synchronized text streams in SMIL 1.0',
    SM12: 'Providing captions through synchronized text streams in SMIL 2.0',
  },
  'WCAG2AA.Principle1.Guideline1_2.1_2_5': {
    G78: 'Providing a second, user-selectable, audio track that includes audio descriptions',
    G173: 'Providing a version of a movie with audio descriptions ',
    SM6: 'Providing audio description in SMIL 1.0',
    SM7: 'Providing audio description in SMIL 2.0',
    G8: 'Providing a movie with extended audio descriptions',
    SM1: 'Adding extended audio description in SMIL 1.0',
    SM2: 'Adding extended audio description in SMIL 2.0',
    G203: 'Using a static text alternative to describe a talking head video',
  },
  'WCAG2AA.Principle1.Guideline1_3.1_3_1': {
    ARIA11: 'Using ARIA landmarks to identify regions of a page',
    H101: 'Using semantic HTML elements to identify regions of a page',
    ARIA12: 'Using role=heading to identify headings',
    ARIA13: 'Using aria-labelledby to name regions and landmarks',
    ARIA16:
      'Using aria-labelledby to provide a name for user interface controls',
    ARIA17: 'Using grouping roles to identify related form controls',
    ARIA20: 'Using the region role to identify a region of the page',
    G115: 'Using semantic elements to mark up structure ',
    H49: 'Using semantic markup to mark emphasized or special text',
    G117: 'Using text to convey information that is conveyed by variations in presentation of text',
    G140: 'Separating information and structure from presentation to enable different presentations',
    ARIA24: 'Semantically identifying a font icon with role="img"',
    G138: 'Using semantic markup whenever color cues are used',
    H51: 'Using table markup to present tabular information',
    PDF6: 'Using table elements for table markup in PDF Documents',
    PDF20: "Using Adobe Acrobat Pro's Table Editor to repair mistagged tables",
    H39: 'Using caption elements to associate data table captions with data tables',
    H63: 'Using the scope attribute to associate header cells and data cells in data tables',
    H43: 'Using id and headers attributes to associate data cells with header cells in data tables',
    H44: 'Using label elements to associate text labels with form controls',
    H65: 'Using the title attribute to identify form controls when the label element cannot be used',
    PDF10: 'Providing labels for interactive form controls in PDF documents',
    PDF12:
      'Providing name, role, value information for form fields in PDF documents',
    H71: 'Providing a description for groups of form controls using fieldset and legend elements',
    H85: 'Using optgroup to group option elements inside a select',
    H48: 'Using ol, ul and dl for lists or groups of links',
    H42: 'Using h1-h6 to identify headings',
    PDF9: 'Providing headings by marking content with heading tags in PDF documents',
    SCR21:
      'Using functions of the Document Object Model (DOM) to add content to a page',
    PDF11:
      'Providing links and link text using the Link annotation and the /Link structure element in PDF documents',
    PDF17: 'Specifying consistent page numbering for PDF documents',
    PDF21: 'Using List tags for lists in PDF documents',
    H97: 'Grouping related links using the nav element',
    T1: 'Using standard text formatting conventions for paragraphs',
    T2: 'Using standard text formatting conventions for lists',
    T3: 'Using standard text formatting conventions for headings',
    C22: 'Using CSS to control visual presentation of text',
    G162: 'Positioning labels to maximize predictability of relationships',
    ARIA1:
      'Using the aria-describedby property to provide a descriptive label for user interface controls',
    ARIA2: 'Identifying a required field with the aria-required property',
    G141: 'Organizing a page using headings',
    F2: 'Failure of Success Criterion 1.3.1 due to using changes in text presentation to convey information without using the appropriate markup or text',
    F33: 'Failure of Success Criterion 1.3.1 and 1.3.2 due to using white space characters to create multiple columns in plain text content',
    F34: 'Failure of Success Criterion 1.3.1 and 1.3.2 due to using white space characters to format tables in plain text content',
    F42: 'Failure of Success Criteria 1.3.1, 2.1.1, 2.1.3, or 4.1.2 when emulating links',
    F43: 'Failure of Success Criterion 1.3.1 due to using structural markup in a way that does not represent relationships in the content',
    F46: 'Failure of Success Criterion 1.3.1 due to using th elements, layout tables',
    F48: 'Failure of Success Criterion 1.3.1 due to using the pre element to markup tabular information',
    F87: "Failure of Success Criterion 1.3.1 due to inserting non-decorative content by using ::before and ::after pseudo-elements and the 'content' property in CSS",
    F90: 'Failure of Success Criterion 1.3.1 for incorrectly associating table headers and content via the headers and id attributes',
    F91: 'Failure of Success Criterion 1.3.1 for not correctly marking up table headers',
    F92: 'Failure of Success Criterion 1.3.1 due to the use of role presentation on content which conveys semantic information',
  },
  'WCAG2AA.Principle1.Guideline1_3.1_3_2': {
    G57: 'Ordering the content in a meaningful sequence ',
    H34: 'Using a Unicode right-to-left mark (RLM) or left-to-right mark (LRM) to mix text direction inline',
    H56: 'Using the dir attribute on an inline element to resolve problems with nested directional runs',
    C6: 'Positioning content based on structural markup',
    C8: 'Using CSS letter-spacing to control spacing within a word',
    C27: 'Making the DOM order match the visual order',
    PDF3: 'Ensuring correct tab and reading order in PDF documents',
    F34: 'Failure of Success Criterion 1.3.1 and 1.3.2 due to using white space characters to format tables in plain text content',
    F33: 'Failure of Success Criterion 1.3.1 and 1.3.2 due to using white space characters to create multiple columns in plain text content',
    F32: 'Failure of Success Criterion 1.3.2 due to using white space characters to control spacing within a word',
    F49: 'Failure of Success Criterion 1.3.2 due to using an HTML layout table that does not make sense when linearized',
    F1: 'Failure of Success Criterion 1.3.2 due to changing the meaning of content by positioning information with CSS',
  },
  'WCAG2AA.Principle1.Guideline1_3.1_3_3': {
    G96: 'Providing textual identification of items that otherwise rely only on sensory information to be understood',
    F14: 'Failure of Success Criterion 1.3.3 due to identifying content only by its shape or location',
    F26: 'Failure of Success Criterion 1.3.3 due to using a graphical symbol alone to convey information',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_1': {
    G14: 'Ensuring that information conveyed by color differences is also available in text',
    G205: 'Including a text cue for colored form control labels',
    G182: 'Ensuring that additional visual cues are available when text color differences are used to convey information',
    G183: 'Using a contrast ratio of 3:1 with surrounding text and providing additional visual cues on hover for links or controls where color alone is used to identify them',
    G111: 'Using color and pattern',
    C15: 'Using CSS to change the presentation of a user interface component when it receives focus',
    F13: 'Failure of Success Criterion 1.1.1 and 1.4.1 due to having a text alternative that does not include information that is conveyed by color differences in the image',
    F73: 'Failure of Success Criterion 1.4.1 due to creating links that are not visually evident without color vision',
    F81: 'Failure of Success Criterion 1.4.1 due to identifying required or error fields using color differences only',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_2': {
    G60: 'Playing a sound that turns off automatically within three seconds',
    G170: 'Providing a control near the beginning of the Web page that turns off sounds that play automatically',
    G171: 'Playing sounds only on user request',
    F23: 'Failure of 1.4.2 due to playing a sound longer than 3 seconds where there is no mechanism to turn it off',
    F93: 'Failure of Success Criterion 1.4.2 for absence of a way to pause or stop an HTML5 media element that autoplays',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_3': {
    G18: 'Ensuring that a contrast ratio of at least 4.5:1 exists between text (and images of text) and background behind the text',
    G148: 'Not specifying background color, not specifying text color, and not using technology features that change those defaults',
    G174: 'Providing a control with a sufficient contrast ratio that allows users to switch to a presentation that uses sufficient contrast',
    G145: 'Ensuring that a contrast ratio of at least 3:1 exists between text (and images of text) and background behind the text',
    G156: 'Using a technology that has commonly-available user agents that can change the foreground and background of blocks of text',
    F24: 'Failure of Success Criterion 1.4.3, 1.4.6 and 1.4.8 due to specifying foreground colors without specifying background colors or vice versa',
    F83: 'Failure of Success Criterion 1.4.3 and 1.4.6 due to using background images that do not provide sufficient contrast with foreground text (or images of text)',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_4': {
    G142: 'Using a technology that has commonly-available user agents that support zoom',
    C28: 'Specifying the size of text containers using em units',
    C12: 'Using percent for font sizes',
    C13: 'Using named font sizes',
    C14: 'Using em units for font sizes',
    SCR34: 'Calculating size and position in a way that scales with text size',
    G146: 'Using liquid layout',
    G178: 'Providing controls on the Web page that allow users to incrementally change the size of all text on the page up to 200 percent',
    G179: 'Ensuring that there is no loss of content or functionality when the text resizes and text containers do not change their width',
    C17: 'Scaling form elements which contain text',
    C20: 'Using relative measurements to set column widths so that lines can average 80 characters or less when the browser is resized',
    C22: 'Using CSS to control visual presentation of text',
    F69: 'Failure of Success Criterion 1.4.4 when resizing visually rendered text up to 200 percent causes the text, image or controls to be clipped, truncated or obscured',
    F80: 'Failure of Success Criterion 1.4.4 when text-based form controls do not resize when visually rendered text is resized up to 200%',
    F94: 'Failure of Success Criterion 1.4.4 due to incorrect use of viewport units to resize text',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_5': {
    C22: 'Using CSS to control visual presentation of text',
    C30: 'Using CSS to replace text with images of text and providing user interface controls to switch',
    G140: 'Separating information and structure from presentation to enable different presentations',
    PDF7: 'Performing OCR on a scanned PDF document to provide actual text',
    C12: 'Using percent for font sizes',
    C13: 'Using named font sizes',
    C14: 'Using em units for font sizes',
    C8: 'Using CSS letter-spacing to control spacing within a word',
    C6: 'Positioning content based on structural markup',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_6': {
    G17: 'Ensuring that a contrast ratio of at least 7:1 exists between text (and images of text) and background behind the text',
    G148: 'Not specifying background color, not specifying text color, and not using technology features that change those defaults',
    G174: 'Providing a control with a sufficient contrast ratio that allows users to switch to a presentation that uses sufficient contrast',
    G18: 'Ensuring that a contrast ratio of at least 4.5:1 exists between text (and images of text) and background behind the text',
    G156: 'Using a technology that has commonly-available user agents that can change the foreground and background of blocks of text',
    F24: 'Failure of Success Criterion 1.4.3, 1.4.6 and 1.4.8 due to specifying foreground colors without specifying background colors or vice versa',
    F83: 'Failure of Success Criterion 1.4.3 and 1.4.6 due to using background images that do not provide sufficient contrast with foreground text (or images of text)',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_7': {
    G56: 'Mixing audio files so that non-speech sounds are at least 20 decibels lower than the speech audio content',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_8': {
    C23: 'Specifying text and background colors of secondary content such as banners, features and navigation in CSS while not specifying text and background colors of the main content',
    C25: 'Specifying borders and layout in CSS to delineate areas of a Web page while not specifying text and text-background colors',
    G156: 'Using a technology that has commonly-available user agents that can change the foreground and background of blocks of text',
    G148: 'Not specifying background color, not specifying text color, and not using technology features that change those defaults',
    G175: 'Providing a multi color selection tool on the page for foreground and background colors',
    G204: "Not interfering with the user agent's reflow of text as the viewing window is narrowed",
    C20: 'Using relative measurements to set column widths so that lines can average 80 characters or less when the browser is resized',
    C19: 'Specifying alignment either to the left OR right in CSS',
    G172: 'Providing a mechanism to remove full justification of text',
    G169: 'Aligning text on only one side',
    G188: 'Providing a button on the page to increase line spaces and paragraph spaces',
    C21: 'Specifying line spacing in CSS',
    G146: 'Using liquid layout',
    C12: 'Using percent for font sizes',
    C13: 'Using named font sizes',
    C14: 'Using em units for font sizes',
    C24: 'Using percentage values in CSS for container sizes',
    SCR34: 'Calculating size and position in a way that scales with text size',
    G206: 'Providing options within the content to switch to a layout that does not require the user to scroll horizontally to read a line of text',
    F24: 'Failure of Success Criterion 1.4.3, 1.4.6 and 1.4.8 due to specifying foreground colors without specifying background colors or vice versa',
    F88: 'Failure of Success Criterion 1.4.8 due to using text that is justified (aligned to both the left and the right margins)',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_9': {
    C22: 'Using CSS to control visual presentation of text',
    C30: 'Using CSS to replace text with images of text and providing user interface controls to switch',
    G140: 'Separating information and structure from presentation to enable different presentations',
    PDF7: 'Performing OCR on a scanned PDF document to provide actual text',
    C12: 'Using percent for font sizes',
    C13: 'Using named font sizes',
    C14: 'Using em units for font sizes',
    C8: 'Using CSS letter-spacing to control spacing within a word',
    C6: 'Positioning content based on structural markup',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_10': {
    C32: 'Using media queries and grid CSS to reflow columns',
    C31: 'Using CSS Flexbox to reflow content',
    C33: 'Allowing for Reflow with Long URLs and Strings of Text',
    C38: 'Using CSS width, max-width and flexbox to fit labels and inputs',
    SCR34: 'Calculating size and position in a way that scales with text size',
    G206: 'Providing options within the content to switch to a layout that does not require the user to scroll horizontally to read a line of text',
    C34: 'Using media queries to un-fixing sticky headers / footers',
    C37: 'Using CSS max-width and height to fit images',
    F102: 'Failure of Success Criterion 1.4.10 due to content disappearing and not being available when content has reflowed',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_11': {
    G195: 'Using an author-supplied, visible focus indicator',
    G174: 'Providing a control with a sufficient contrast ratio that allows users to switch to a presentation that uses sufficient contrast',
    G207: 'Ensuring that a contrast ratio of 3:1 is provided for icons',
    G209: 'Provide sufficient contrast at the boundaries between adjoining colors',
    F78: 'Failure of Success Criterion 2.4.7 due to styling element outlines and borders in a way that removes or renders non-visible the visual focus indicator',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_12': {
    C36: 'Allowing for text spacing override',
    C35: 'Allowing for text spacing without wrapping',
    C8: 'Using CSS letter-spacing to control spacing within a word',
    C21: 'Specifying line spacing in CSS',
    C28: 'Specifying the size of text containers using em units',
    F104: 'Failure of Success Criterion 1.4.12 due to clipped or overlapped content when text spacing is adjusted',
  },
  'WCAG2AA.Principle1.Guideline1_4.1_4_13': {
    SCR39:
      'Making content on focus or hover hoverable, dismissible, and persistent',
    F95: 'Failure of Success Criterion 1.4.13 due to content shown on hover not being hoverable',
  },
  'WCAG2AA.Principle2.Guideline2_1.2_1_1': {
    G202: 'Ensuring keyboard control for all functionality',
    H91: 'Using HTML form controls and links',
    PDF3: 'Ensuring correct tab and reading order in PDF documents',
    PDF11:
      'Providing links and link text using the Link annotation and the /Link structure element in PDF documents',
    PDF23: 'Providing interactive form controls in PDF documents',
    G90: 'Providing keyboard-triggered event handlers ',
    SCR20: 'Using both keyboard and other device-specific functions',
    SCR35:
      'Making actions keyboard accessible by using the onclick event of anchors and buttons',
    SCR2: 'Using redundant keyboard and mouse event handlers',
    SCR29: 'Adding keyboard-accessible actions to static HTML elements',
    F54: 'Failure of Success Criterion 2.1.1 due to using only pointing-device-specific event handlers (including gesture) for a function',
    F55: 'Failure of Success Criteria 2.1.1, 2.4.7, and 3.2.1 due to using script to remove focus when focus is received',
    F42: 'Failure of Success Criteria 1.3.1, 2.1.1, 2.1.3, or 4.1.2 when emulating links',
  },
  'WCAG2AA.Principle2.Guideline2_1.2_1_2': {
    G21: 'Ensuring that users are not trapped in content',
    F10: 'Failure of Success Criterion 2.1.2 and Conformance Requirement 5 due to combining multiple content formats in a way that traps users inside one format type',
  },
  'WCAG2AA.Principle2.Guideline2_2.2_2_1': {
    G133: 'Providing a checkbox on the first page of a multipart form that allows users to ask for longer session time limit or no session time limit',
    G198: 'Providing a way for the user to turn the time limit off',
    G180: 'Providing the user with a means to set the time limit to 10 times the default time limit',
    SCR16:
      'Providing a script that warns the user a time limit is about to expire AND SCR1: Allowing the user to extend the default time limit',
    G4: 'Allowing the content to be paused and restarted from where it was paused',
    SCR33:
      'Using script to scroll content, and providing a mechanism to pause it',
    SCR36:
      'Providing a mechanism to allow users to display moving, scrolling, or auto-updating text in a static window or area',
    F40: 'Failure due to using meta redirect with a time limit',
    F41: 'Failure of Success Criterion 2.2.1, 2.2.4, and 3.2.5 due to using meta refresh to reload the page',
    F58: 'Failure of Success Criterion 2.2.1 due to using server-side techniques to automatically redirect pages after a time-out',
  },
  'WCAG2AA.Principle2.Guideline2_2.2_2_2': {
    G4: 'Allowing the content to be paused and restarted from where it was paused',
    SCR33:
      'Using script to scroll content, and providing a mechanism to pause it',
    G11: 'Creating content that blinks for less than 5 seconds',
    G187: 'Using a technology to include blinking content that can be turned off via the user agent',
    G152: 'Setting animated gif images to stop blinking after n cycles (within 5 seconds)',
    SCR22:
      'Using scripts to control blinking and stop it in five seconds or less',
    G186: 'Using a control in the Web page that stops moving, blinking, or auto-updating content',
    G191: 'Providing a link, button, or other mechanism that reloads the page without any blinking content',
    F16: 'Failure of Success Criterion 2.2.2 due to including scrolling content where movement is not essential to the activity without also including a mechanism to pause and restart the content',
    F47: 'Failure of Success Criterion 2.2.2 due to using the blink element',
    F4: 'Failure of Success Criterion 2.2.2 due to using text-decoration:blink without a mechanism to stop it in less than five seconds',
    F50: 'Failure of Success Criterion 2.2.2 due to a script that causes a blink effect without a mechanism to stop the blinking at 5 seconds or less',
    F7: 'Failure of Success Criterion 2.2.2 due to an object or applet for more than five seconds',
  },
  'WCAG2AA.Principle2.Guideline2_3.2_3_1': {
    G19: 'Ensuring that no component of the content flashes more than three times in any 1-second period',
    G176: 'Keeping the flashing area small enough',
    G15: 'Using a tool to ensure that content does not violate the general flash threshold or red flash threshold',
  },
  'WCAG2AA.Principle2.Guideline2_3.2_3_2': {
    G19: 'Ensuring that no component of the content flashes more than three times in any 1-second period',
  },
  'WCAG2AA.Principle2.Guideline2_4.2_4_1': {
    G1: 'Adding a link at the top of each page that goes directly to the main content area',
    G123: 'Adding a link at the beginning of a block of repeated content to go to the end of the block',
    G124: 'Adding links at the top of the page to each area of the content',
    ARIA11: 'Using ARIA landmarks to identify regions of a page',
    H69: 'Providing heading elements at the beginning of each section of content',
    PDF9: 'Providing headings by marking content with heading tags in PDF documents',
    H70: 'Using frame elements to group blocks of repeated material ',
    H64: 'Using the title attribute of the iframe element',
    SCR28:
      'Using an expandable and collapsible menu to bypass block of content',
    C6: 'Positioning content based on structural markup',
    H97: 'Grouping related links using the nav element',
  },
  'WCAG2AA.Principle2.Guideline2_4.2_4_2': {
    G88: 'Providing descriptive titles for Web pages ',
    H25: 'Providing a title using the title element',
    PDF18:
      'Specifying the document title using the Title entry in the document information dictionary of a PDF document',
    G127: "Identifying a Web page's relationship to a larger collection of Web pages",
    F25: 'Failure of Success Criterion 2.4.2 due to the title of a Web page not identifying the contents',
  },
  'WCAG2AA.Principle2.Guideline2_4.2_4_3': {
    G59: 'Placing the interactive elements in an order that follows sequences and relationships within the content',
    C27: 'Making the DOM order match the visual order',
    PDF3: 'Ensuring correct tab and reading order in PDF documents',
    SCR26:
      'Inserting dynamic content into the Document Object Model immediately following its trigger element',
    SCR37: 'Creating Custom Dialogs in a Device Independent Way',
    SCR27: 'Reordering page sections using the Document Object Model',
    F44: 'Failure of Success Criterion 2.4.3 due to using tabindex to create a tab order that does not preserve meaning and operability',
    F85: 'Failure of Success Criterion 2.4.3 due to using dialogs or menus that are not adjacent to their trigger control in the sequential navigation order',
  },
  'WCAG2AA.Principle2.Guideline2_4.2_4_4': {
    G91: 'Providing link text that describes the purpose of a link',
    H30: 'Providing link text that describes the purpose of a link for anchor elements',
    H24: 'Providing text alternatives for the area elements of image maps',
    G189: 'Providing a control near the beginning of the Web page that changes the link text',
    SCR30: 'Using scripts to change the link text',
    G53: 'Identifying the purpose of a link using link text combined with the text of the enclosing sentence',
    H33: 'Supplementing link text with the title attribute',
    C7: 'Using CSS to hide a portion of the link text',
    ARIA7: 'Using aria-labelledby for link purpose',
    ARIA8: 'Using aria-label for link purpose',
    H77: 'Identifying the purpose of a link using link text combined with its enclosing list item',
    H78: 'Identifying the purpose of a link using link text combined with its enclosing paragraph',
    H79: 'Identifying the purpose of a link in a data table using the link text combined with its enclosing table cell and associated table header cells',
    H81: 'Identifying the purpose of a link in a nested list using link text combined with the parent list item under which the list is nested',
    PDF11:
      'Providing links and link text using the Link annotation and the /Link structure element in PDF documents',
    PDF13:
      'Providing replacement text using the /Alt entry for links in PDF documents',
    H2: 'Combining adjacent image and text links for the same resource',
    H80: 'Identifying the purpose of a link using link text combined with the preceding heading element',
    F63: 'Failure of Success Criterion 2.4.4 due to providing link context only in content that is not related to the link',
    F89: 'Failure of Success Criteria 2.4.4, 2.4.9 and 4.1.2 due to not providing an accessible name for an image which is the only content in a link',
  },
  'WCAG2AA.Principle2.Guideline2_4.2_4_5': {
    G125: 'Providing links to navigate to related Web pages',
    G64: 'Providing a Table of Contents',
    G63: 'Providing a site map',
    G161: 'Providing a search function to help users find content',
    G126: 'Providing a list of links to all other Web pages',
    G185: 'Linking to all of the pages on the site from the home page',
    H59: 'Using the link element and navigation tools',
    PDF2: 'Creating bookmarks in PDF documents',
  },
  'WCAG2AA.Principle2.Guideline2_4.2_4_6': {
    G130: 'Providing descriptive headings',
    G131: 'Providing descriptive labels',
  },
  'WCAG2AA.Principle2.Guideline2_4.2_4_7': {
    G149: 'Using user interface components that are highlighted by the user agent when they receive focus',
    C15: 'Using CSS to change the presentation of a user interface component when it receives focus',
    G165: 'Using the default focus indicator for the platform so that high visibility default focus indicators will carry over',
    G195: 'Using an author-supplied, visible focus indicator',
    C40: 'Creating a two-color focus indicator to ensure sufficient contrast with all components',
    SCR31:
      'Using script to change the background color or border of the element with focus',
    F55: 'Failure of Success Criteria 2.1.1, 2.4.7, and 3.2.1 due to using script to remove focus when focus is received',
    F78: 'Failure of Success Criterion 2.4.7 due to styling element outlines and borders in a way that removes or renders non-visible the visual focus indicator',
  },
  'WCAG2AA.Principle2.Guideline2_4.2_4_8': {
    G65: 'Providing a breadcrumb trail',
    G63: 'Providing a site map',
    G128: 'Indicating current location within navigation bars',
    G127: "Identifying a Web page's relationship to a larger collection of Web pages",
    H59: 'Using the link element and navigation tools',
    PDF14: 'Providing running headers and footers in PDF documents',
    PDF17: 'Specifying consistent page numbering for PDF documents',
  },
  'WCAG2AA.Principle2.Guideline2_4.2_4_9': {
    ARIA8: 'Using aria-label for link purpose',
    G91: 'Providing link text that describes the purpose of a link',
    H30: 'Providing link text that describes the purpose of a link for anchor elements',
    H24: 'Providing text alternatives for the area elements of image maps',
    G189: 'Providing a control near the beginning of the Web page that changes the link text',
    SCR30: 'Using scripts to change the link text',
    C7: 'Using CSS to hide a portion of the link text',
    PDF11:
      'Providing links and link text using the Link annotation and the /Link structure element in PDF documents',
    PDF13:
      'Providing replacement text using the /Alt entry for links in PDF documents',
    H2: 'Combining adjacent image and text links for the same resource',
    H33: 'Supplementing link text with the title attribute',
    F84: 'Failure of Success Criterion 2.4.9 due to using a non-specific link such as "click here" or "more" without a mechanism to change the link text to specific text.',
    F89: 'Failure of Success Criteria 2.4.4, 2.4.9 and 4.1.2 due to not providing an accessible name for an image which is the only content in a link',
  },
  'WCAG2AA.Principle2.Guideline2_4.2_4_10': {
    G141: 'Organizing a page using headings',
    H69: 'Providing heading elements at the beginning of each section of content',
  },
  'WCAG2AA.Principle2.Guideline2_5.2_5_1': {
    G215: 'Providing controls to achieve the same result as path based or multipoint gestures',
    G216: 'Providing single point activation for a control slider',
    F105: 'Failure of Success Criterion 2.5.1 due to providing functionality via a path-based gesture without simple pointer alternative',
  },
  'WCAG2AA.Principle2.Guideline2_5.2_5_2': {
    G210: 'Ensuring that drag-and-drop actions can be cancelled',
    G212: 'Using native controls to ensure functionality is triggered on the up-event.',
    F101: 'Failure of Success Criterion 2.5.2 due to activating a control on the down-event',
  },
  'WCAG2AA.Principle2.Guideline2_5.2_5_3': {
    G208: 'Including the text of the visible label as part of the accessible name',
    G211: 'Matching the accessible name to the visible label',
    G162: 'Positioning labels to maximize predictability of relationships',
    F96: 'Failure due to the accessible name not containing the visible label text',
  },
  'WCAG2AA.Principle2.Guideline2_5.2_5_4': {
    G213: 'Provide conventional controls and an application setting for motion activated input',
    F106: 'Failure due to inability to deactivate motion actuation',
  },
  'WCAG2AA.Principle2.Guideline2_5.2_5_6': {
    F98: 'Failure due to interactions being limited to touch-only on touchscreen devices',
  },
  'WCAG2AA.Principle3.Guideline3_1.3_1_1': {
    H57: 'Using the language attribute on the HTML element',
    PDF16:
      'Setting the default language using the /Lang entry in the document catalog of a PDF document',
    PDF19:
      'Specifying the language for a passage or phrase with the Lang entry in PDF documents',
    SVR5: 'Specifying the default language in the HTTP header',
  },
  'WCAG2AA.Principle3.Guideline3_1.3_1_2': {
    H58: 'Using language attributes to identify changes in the human language.',
    PDF19:
      'Specifying the language for a passage or phrase with the Lang entry in PDF documents.',
  },
  'WCAG2AA.Principle3.Guideline3_1.3_1_3': {
    G101: 'Providing the definition of a word or phrase used in an unusual or restricted way ',
    G55: 'Linking to definitions',
    H40: 'Using description lists',
    G112: 'Using inline definitions',
    H54: 'Using the dfn element to identify the defining instance of a word',
    G62: 'Providing a glossary',
    G70: 'Providing a function to search an online dictionary',
  },
  'WCAG2AA.Principle3.Guideline3_1.3_1_4': {
    G102: 'Providing the expansion or explanation of an abbreviation ',
    G97: 'Providing the first use of an abbreviation immediately before or after the expanded form',
    G55: 'Linking to definitions',
    H28: 'Providing definitions for abbreviations by using the abbr element',
    PDF8: 'Providing definitions for abbreviations via an E entry for a structure element',
    G62: 'Providing a glossary',
    G70: 'Providing a function to search an online dictionary',
  },
  'WCAG2AA.Principle3.Guideline3_1.3_1_5': {
    G86: 'Providing a text summary that can be understood by people with lower secondary education level reading ability',
    G103: 'Providing visual illustrations, pictures, and symbols to help explain ideas, events, and processes',
    G79: 'Providing a spoken version of the text',
    G153: 'Making the text easier to read',
    G160: 'Providing sign language versions of information, ideas, and processes that must be understood in order to use the content',
  },
  'WCAG2AA.Principle3.Guideline3_1.3_1_6': {
    G120: 'Providing the pronunciation immediately following the word',
    G121: 'Linking to pronunciations',
    G62: 'Providing a glossary',
    G163: 'Using standard diacritical marks that can be turned off',
    H62: 'Using the ruby element',
  },
  'WCAG2AA.Principle3.Guideline3_2.3.2.1': {
    G107: 'Using "activate" rather than "focus" as a trigger for changes of context',
    G200: 'Opening new windows and tabs from a link only when necessary',
    G201: 'Giving users advanced warning when opening a new window',
    F55: 'Failure of Success Criteria 2.1.1, 2.4.7, and 3.2.1 due to using script to remove focus when focus is received',
  },
  'WCAG2AA.Principle3.Guideline3_2.3.2.2': {
    G80: 'Providing a submit button to initiate a change of context',
    H32: 'Providing submit buttons',
    H84: 'Using a button with a select element to perform an action',
    PDF15: 'Providing submit buttons with the submit-form action in PDF forms',
    G13: 'Describing what will happen before a change to a form control that causes a change of context to occur is made',
    SCR19:
      'Using an onchange event on a select element without causing a change of context',
    G201: 'Giving users advanced warning when opening a new window',
    F36: 'Failure of Success Criterion 3.2.2 due to automatically submitting a form and given a value',
    F37: 'Failure of Success Criterion 3.2.2 due to launching a new window without prior warning when the selection of a radio button, check box or select list is changed',
  },
  'WCAG2AA.Principle3.Guideline3_2.3.2.3': {
    G61: 'Presenting repeated components in the same relative order each time they appear',
    PDF14: 'Providing running headers and footers in PDF documents',
    PDF17: 'Specifying consistent page numbering for PDF documents',
    F66: 'Failure of Success Criterion 3.2.3 due to presenting navigation links in a different relative order on different pages',
  },
  'WCAG2AA.Principle3.Guideline3_2.3.2.4': {
    G197: 'Using labels, names, and text alternatives consistently for content that has the same functionality',
    F31: 'Failure of Success Criterion 3.2.4 due to using two different labels for the same function on different Web pages within a set of Web pages',
  },
  'WCAG2AA.Principle3.Guideline3_3.3_3_1': {
    G83: 'Providing text descriptions to identify required fields that were not completed',
    ARIA21: 'Using Aria-Invalid to Indicate An Error Field',
    SCR18: 'Providing client-side validation and alert',
    PDF5: 'Indicating required form controls in PDF forms',
    ARIA18: 'Using aria-alertdialog to Identify Errors',
    ARIA19: 'Using ARIA role=alert or Live Regions to Identify Errors',
    G84: 'Providing a text description when the user provides information that is not in the list of allowed values',
    G85: 'Providing a text description when user input falls outside the required format or values',
    SCR32: 'Providing client-side validation and adding error text via the DOM',
    PDF22:
      'Indicating when user input falls outside the required format or values in PDF forms',
    G139: 'Creating a mechanism that allows users to jump to errors',
    G199: 'Providing success feedback when data is submitted successfully',
  },
  'WCAG2AA.Principle3.Guideline3_3.3_3_2': {
    G131: 'Providing descriptive labels',
    ARIA1:
      'Using the aria-describedby property to provide a descriptive label for user interface controls',
    ARIA9:
      'Using aria-labelledby to concatenate a label from several text nodes',
    ARIA17: 'Using grouping roles to identify related form controls',
    G89: 'Providing expected data format and example',
    G184: 'Providing text instructions at the beginning of a form or set of fields that describes the necessary input',
    G162: 'Positioning labels to maximize predictability of relationships',
    G83: 'Providing text descriptions to identify required fields that were not completed',
    H90: 'Indicating required form controls using label or legend',
    PDF5: 'Indicating required form controls in PDF forms',
    H44: 'Using label elements to associate text labels with form controls',
    PDF10: 'Providing labels for interactive form controls in PDF documents',
    H71: 'Providing a description for groups of form controls using fieldset and legend elements',
    G167: 'Using an adjacent button to label the purpose of a field',
    G13: 'Describing what will happen before a change to a form control that causes a change of context to occur is made',
    F82: 'Failure of Success Criterion 3.3.2 by visually formatting a set of phone number fields but not including a text label',
  },
  'WCAG2AA.Principle3.Guideline3_3.3_3_3': {
    ARIA18: 'Using aria-alertdialog to Identify Errors',
    G85: 'Providing a text description when user input falls outside the required format or values',
    G177: 'Providing suggested correction text',
    PDF22:
      'Indicating when user input falls outside the required format or values in PDF forms',
    G84: 'Providing a text description when the user provides information that is not in the list of allowed values',
    G139: 'Creating a mechanism that allows users to jump to errors',
    G199: 'Providing success feedback when data is submitted successfully',
    SCR18: 'Providing client-side validation and alert',
    SCR32: 'Providing client-side validation and adding error text via the DOM',
  },
  'WCAG2AA.Principle3.Guideline3_3.3_3_4': {
    G164: 'Providing a stated time within which an online request (or transaction) may be amended or canceled by the user after making the request',
    G98: 'Providing the ability for the user to review and correct answers before submitting',
    G155: 'Providing a checkbox in addition to a submit button',
    G99: 'Providing the ability to recover deleted information',
    G168: 'Requesting confirmation to continue with selected action',
    SCR18: 'Providing client-side validation and alert',
    G199: 'Providing success feedback when data is submitted successfully',
  },
  'WCAG2AA.Principle3.Guideline3_3.3_3_5': {
    G71: 'Providing a help link on every Web page',
    G193: 'Providing help by an assistant in the Web page',
    G194: 'Providing spell checking and suggestions for text input',
    G184: 'Providing text instructions at the beginning of a form or set of fields that describes the necessary input',
    G89: 'Providing expected data format and example',
    H89: 'Using the title attribute to provide context-sensitive help',
  },
  'WCAG2AA.Principle4.Guideline4_1.4_1_1': {
    G134: 'Validating Web pages',
    G192: 'Fully conforming to specifications',
    H88: 'Using HTML according to spec',
    H74: 'Ensuring that opening and closing tags are used according to specification',
    H93: 'Ensuring that id attributes are unique on a Web page ',
    H94: 'Ensuring that elements do not contain duplicate attributes',
    H75: 'Ensuring that Web pages are well-formed',
    F70: 'Failure of Success Criterion 4.1.1 due to incorrect use of start and end tags or attribute markup',
    F77: 'Failure of Success Criterion 4.1.1 due to duplicate values of type ID',
  },
  'WCAG2AA.Principle4.Guideline4_1.4_1_2': {
    ARIA14:
      'Using aria-label to provide an invisible label where a visible label cannot be used',
    ARIA16:
      'Using aria-labelledby to provide a name for user interface controls',
    G108: 'Using markup features to expose the name and role, allow user-settable properties to be directly set, and provide notification of changes ',
    H91: 'Using HTML form controls and links',
    H44: 'Using label elements to associate text labels with form controls',
    H64: 'Using the title attribute of the iframe element',
    H65: 'Using the title attribute to identify form controls when the label element cannot be used',
    H88: 'Using HTML according to spec',
    G135: 'Using the accessibility API features of a technology to expose names and notification of changes',
    PDF10: 'Providing labels for interactive form controls in PDF documents',
    PDF12:
      'Providing name, role, value information for form fields in PDF documents',
    G10: 'Creating components using a technology that supports the accessibility notification of changes ',
    ARIA4:
      'Using a WAI-ARIA role to expose the role of a user interface component',
    ARIA5:
      'Using WAI-ARIA state and property attributes to expose the state of a user interface component',
    F59: 'Failure of Success Criterion 4.1.2 due to using script to make div or span a user interface control in HTML without providing a role for the control',
    F15: 'Failure of Success Criterion 4.1.2 due to implementing custom controls that do not use an accessibility API for the technology, or do so incompletely',
    F20: 'Failure of Success Criterion 1.1.1 and 4.1.2 due to not updating text alternatives when changes to non-text content occur',
    F68: 'Failure of Success Criterion 4.1.2 due to a user interface control not having a programmatically determined name',
    F79: 'Failure of Success Criterion 4.1.2 due to the focus state of a user interface component not being programmatically determinable or no notification of change of focus state available',
    F86: 'Failure of Success Criterion 4.1.2 due to not providing names for each part of a multi-part form field, such as a US telephone number',
    F89: 'Failure of Success Criteria 2.4.4, 2.4.9 and 4.1.2 due to not providing an accessible name for an image which is the only content in a link',
  },
  'WCAG2AA.Principle4.Guideline4_1.4_1_3': {
    ARIA22: 'Using role=status to present status messages',
    G199: 'Providing success feedback when data is submitted successfully',
    ARIA19: 'Using ARIA role=alert or Live Regions to Identify Errors',
    G83: 'Providing text descriptions to identify required fields that were not completed',
    G84: 'Providing a text description when the user provides information that is not in the list of allowed values',
    G85: 'Providing a text description when user input falls outside the required format or values',
    G177: 'Providing suggested correction text',
    G194: 'Providing spell checking and suggestions for text input',
    ARIA23: 'Using role=log to identify sequential information updates',
    G193: 'Providing help by an assistant in the Web page',
    ARIA18: 'Using aria-alertdialog to Identify Errors',
    SCR14: 'Using scripts to make nonessential alerts optional',
    F103: 'Failure of Success Criterion 4.1.3 due to providing status messages that cannot be programmatically determined through role or properties',
  },
};

export const getCriteriaValues = (code: string) => {
  if (!code) {
    throw new Error('Invalid format.');
  }

  const [level, principle, guideline, criteria, techniques] = code.split('.');

  return {
    level,
    principle,
    guideline,
    criteria,
    techniques,
  };
};

export const getAccessibilityReport = (
  issues: Issue[],
  scans: ScanData[],
): AccessibilityReport => {
  const websiteIdToUrlAccessibilityReport: { [x: string]: any } = {};
  const totals: AccessibilityReport = JSON.parse(
    JSON.stringify(accessibilityReport),
  );

  const levelAIssues: Issue[] = [];
  const levelAAIssues: Issue[] = [];

  for (const issue of issues) {
    const {
      website_id: websiteId,
      domain_url: domainUrl,
      code,
      status,
    } = issue;

    if (issue.source === 'Manual') {
      continue;
    }

    const {
      level,
      // @ts-ignore
      principle,
      // @ts-ignore
      guideline,
      // @ts-ignore
      criteria,
      // @ts-ignore
      techniques,
    } = getCriteriaValues(code);

    if (!websiteIdToUrlAccessibilityReport[websiteId]) {
      websiteIdToUrlAccessibilityReport[websiteId] = {};
    }

    if (!websiteIdToUrlAccessibilityReport[websiteId][domainUrl]) {
      websiteIdToUrlAccessibilityReport[websiteId][domainUrl] = {
        ...accessibilityReport,
      };
    }

    switch (level) {
      case 'WCAG2A':
        totals.levelA.total++;
        if (status === 'resolved') {
          totals.levelA.done++;
        } else if (status === 'pending' || status === '') {
          totals.levelA.toFix++;
        }
        levelAIssues.push(issue);
        break;
      case 'WCAG2AA':
        totals.levelAA.total++;
        if (status === 'resolved') {
          totals.levelAA.done++;
        } else if (status === 'pending' || status === '') {
          totals.levelAA.toFix++;
        }
        levelAAIssues.push(issue);
        break;
      default:
        break;
    }
  }
  // const levelAScans;
  const levelAAScans = scans.map((scan) => {
    return {
      totalErrors: scan.error,
      totalWarnings: scan.warning,
      totalNotices: scan.notice,
    };
  });

  totals.levelA.score = 0;
  totals.levelAA.score = calculateAccessibilityScore(levelAAScans);
  totals.overallScore = calculateAccessibilityScore(levelAAScans);

  return totals;
};

// export const combineMultipleScans = (
//   combinedScans: ScanData[],
//   currentScan: ScanData,
//   existingScanIndex: number,
//   isMultipleWebsiteScans: boolean
// ): ScanData[] => {
//   if (existingScanIndex !== -1) {
//     const existingDate = new Date(combinedScans[existingScanIndex].updatedAt);
//     const currentDate = new Date(currentScan.updatedAt);
//     const updatedScan = { ...combinedScans[existingScanIndex] };
//     updatedScan.error += currentScan.error;
//     updatedScan.warning += currentScan.warning;
//     updatedScan.notice += currentScan.notice;
//     updatedScan.updatedAt =
//       currentDate > existingDate
//         ? currentScan.updatedAt
//         : combinedScans[existingScanIndex].updatedAt;
//     const [firstUrl] = currentScan.url.split("/");
//     updatedScan.url = isMultipleWebsiteScans ? firstUrl : currentScan.url;
//     const updatedCombinedScans = [...combinedScans];
//     updatedCombinedScans[existingScanIndex] = updatedScan;
//     updatedScan.manualScans += currentScan.manualScans;
//     return updatedCombinedScans;
//   }
//
//   combinedScans.push(currentScan);
//   return combinedScans;
// };

export interface ExtendedScanData extends ScanData {
  manualScans: number;
}

interface ScansTotalsAndData {
  scansDataMap: Record<string, ExtendedScanData>;
  scanTotals: ScansTotals;
}

type WebsiteScanTotals = Record<number, ScansTotalsAndData>;

const getManualScanCount = (scanData: ScanData): number => {
  return scanData.type === 'Manual'
    ? scanData.error + scanData.warning + scanData.notice
    : 0;
};

export const getWebsiteScanTotals = (
  scans: ScanData[],
  isMultipleWebsiteScans: boolean,
): WebsiteScanTotals => {
  const websiteScansMap: WebsiteScanTotals = {};

  for (const scan of scans) {
    const { websiteId } = scan;
    const url = buildUnknownUrl(scan.url);
    const { domain } = extractDomainAndPath(url.href);

    if (!websiteScansMap[websiteId]) {
      websiteScansMap[websiteId] = {
        scansDataMap: {},
        scanTotals: { ...SCAN_TOTALS_INITIAL_STATE },
      };
    }

    const currentUrl = removeSlashAtUrlEnd(
      isMultipleWebsiteScans ? domain : `${url.hostname}${url.pathname}`,
    );
    if (!websiteScansMap[websiteId].scansDataMap[currentUrl]) {
      websiteScansMap[websiteId].scansDataMap[currentUrl] = {
        url: scan.url,
        error: scan.error,
        warning: scan.warning,
        notice: scan.notice,
        updatedAt: scan.updatedAt,
        type: scan.type,
        websiteId: scan.websiteId,
        createdAt: scan.createdAt,
        userId: scan.userId,
        id: scan.id,
        manualScans: getManualScanCount(scan),
      };
    } else {
      websiteScansMap[websiteId].scansDataMap[currentUrl].error += scan.error;
      websiteScansMap[websiteId].scansDataMap[currentUrl].warning +=
        scan.warning;
      websiteScansMap[websiteId].scansDataMap[currentUrl].notice += scan.notice;
      websiteScansMap[websiteId].scansDataMap[currentUrl].updatedAt =
        scan.updatedAt;
      websiteScansMap[websiteId].scansDataMap[currentUrl].createdAt =
        scan.createdAt;
      websiteScansMap[websiteId].scansDataMap[currentUrl].url = currentUrl;
      websiteScansMap[websiteId].scansDataMap[currentUrl].manualScans +=
        getManualScanCount(scan);
    }

    websiteScansMap[websiteId].scanTotals.totalPageScanned += 1;
    websiteScansMap[websiteId].scanTotals.totalIssues +=
      scan.error + scan.warning + scan.notice;
    websiteScansMap[websiteId].scanTotals.totalManual +=
      getManualScanCount(scan);
    websiteScansMap[websiteId].scanTotals.totalNotices += scan.notice;
    websiteScansMap[websiteId].scanTotals.totalUsability += scan.warning;
    websiteScansMap[websiteId].scanTotals.totalCritical += scan.error;
  }

  return websiteScansMap;
};

export const getScansTotals = (totalScansData: TotalScansData): ScansTotals => {
  const scansTotals: ScansTotals = {
    ...JSON.parse(JSON.stringify(SCAN_TOTALS_INITIAL_STATE)),
  };

  const { totalWarnings, totalErrors, totalNotices, scans } = totalScansData;
  const automatedScans: ScanData[] = [];
  const manualScans: ScanData[] = [];

  for (const scan of scans) {
    if (scan.type === 'Automated') {
      automatedScans.push(scan);
    } else {
      manualScans.push(scan);
      scansTotals.totalManual += scan.error + scan.warning + scan.notice;
    }
  }
  scansTotals.totalPageScanned = automatedScans.length + manualScans.length;
  scansTotals.totalCritical = totalErrors;
  scansTotals.totalUsability = totalWarnings;
  scansTotals.totalNotices = totalNotices;
  scansTotals.totalIssues = totalErrors + totalWarnings + totalNotices;
  return scansTotals;
};

export const calculateAccessibilityScore = (scans: ScanTotals[]): number => {
  if (scans.length === 0) {
    return 0;
  }

  const PERCENTAGE = 100;

  let totalScans = 0;
  for (const scan of scans) {
    const accessibilityScore =
      PERCENTAGE -
      (scan.totalErrors * (15 / 100) +
        scan.totalWarnings * (5 / 100) +
        scan.totalNotices * (1 / 100));

    totalScans += accessibilityScore;
  }

  return Number((totalScans / scans.length).toFixed(2));
};

const checkIssueStatus = (issue: Issue): string => {
  let issueStatus = AUDIT_STATUS_SUCCESS;

  if (issue.type === AUDIT_STATUS_ERROR) {
    issueStatus = AUDIT_STATUS_ERROR;
  }

  if (issue.type === AUDIT_STATUS_WARNING) {
    issueStatus = AUDIT_STATUS_WARNING;
  }

  return issueStatus;
};

export interface WcagIssueCounts {
  error: number;
  warning: number;
  notice: number;
}

export interface WcagIssuesGroupingByStatus {
  error: Issue[];
  warning: Issue[];
  notice: Issue[];
}

export const calculateIssuesAndCountBasedOnStatus = (
  issue: Issue,
  wcagIssueCounts: WcagIssueCounts,
  wcagIssues: WcagIssuesGroupingByStatus,
) => {
  if (!issue) {
    return;
  }

  const criteriaStatus = checkIssueStatus(issue);

  if (criteriaStatus === AUDIT_STATUS_ERROR) {
    wcagIssueCounts.error += 1;
    wcagIssues.error.push(issue);
  } else if (criteriaStatus === AUDIT_STATUS_WARNING) {
    wcagIssueCounts.warning += 1;
    wcagIssues.warning.push(issue);
  } else {
    wcagIssueCounts.notice += 1;
    wcagIssues.notice.push(issue);
  }
};

export const groupAccessibilityIssuesByTitle = (
  issues: DevtoolsSolution[],
): Record<string, DevtoolsSolution[]> => {
  const issuesGroupedByTitle: Record<string, DevtoolsSolution[]> = {};

  issues.forEach((issue) => {
    if (!issuesGroupedByTitle[issue.title]) {
      issuesGroupedByTitle[issue.title] = [issue];
    } else {
      issuesGroupedByTitle[issue.title].push(issue);
    }
  });

  return issuesGroupedByTitle;
};
