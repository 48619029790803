import React, { useState, useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { Box, Grid, useMediaQuery } from '@material-ui/core';

import PriceTabs, { TabType } from './price-tabs';
import { useCart } from '../../../../providers/cart-provider';
import { PriceCard } from './pricing-card';
// import SelectOptions from './price-select/select-price-options';
// import ExplanationBox from './pricing-explanation';
import { ModalPanel } from '../../../../components/redesign/ModalPanel';
import {
  ContainedButton,
  OutlinedButton,
} from '../../../../components/common/ActionButton';
import { CenterBlock } from '../../../../components/common/styled';
import { Container as MuiContainer } from '@mui/material';
import { ProductType } from '@equally-ai/cart-checkout';

import Cookies from 'js-cookie';
import { EQUALLY_COOKIE_KEY } from '../../../../utils/cookie.utils';

const TAB_ONE_YEAR = 'oneYear';
const TAB_ONE_MONTH = 'oneMonth';

const tabIndexToDuration = {
  '0': 'YEAR',
  '1': 'MONTH',
};
const TABS: TabType[] = [
  {
    key: TAB_ONE_YEAR,
    label: 'Annual',
    badge: 'Save 20%',
    value: 0,
  },
  {
    key: TAB_ONE_MONTH,
    label: 'Monthly',
    value: 1,
  },
];

interface PriceBlockProps {
  productType: string;
}
const productTypes: Record<string, ProductType> = {
  widget: 'widget',
  flowy: 'flowy',
  service: 'service',
};

const PriceBlock = ({ productType }: PriceBlockProps) => {
  const { actions, priceTemplates, store } = useCart();
  const isWide = useMediaQuery('(min-width: 767px)');
  const isSM = useMediaQuery('(max-width: 600px)');
  const equallyAttribution = JSON.parse(
    Cookies.get(EQUALLY_COOKIE_KEY) || '{}',
  );

  const [currentTabIdx, setCurrentTabIdx] = useState(0);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const onTabChange = useCallback(
    (_: any, newValue: number) => setCurrentTabIdx(newValue),
    [],
  );

  useEffect(() => {
    if (
      !productTypes[productType] ||
      priceTemplates.prices[0]?.product_type === productType
    ) {
      return;
    }
    void actions.fetchPrices(productTypes[productType]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productType]);

  const [oneYearPrices, oneMonthPrices] = useMemo(() => {
    const oneYear = priceTemplates.prices.filter(
      (price) => price.duration_unit === 'YEAR',
    );
    const oneMonth = priceTemplates.prices.filter(
      (price) => price.duration_unit === 'MONTH',
    );
    return [oneYear, oneMonth];
  }, [priceTemplates.prices]);

  useEffect(() => {
    if (oneYearPrices.length > 0 && oneMonthPrices.length === 0) {
      // Activate one year prices
      setCurrentTabIdx(0);
    } else if (oneMonthPrices.length > 0 && oneYearPrices.length === 0) {
      // Activate monthly prices
      setCurrentTabIdx(1);
    }
  }, [oneYearPrices, oneMonthPrices]);

  // const currentPrices = currentTabIdx === 0 ? oneYearPrices : oneMonthPrices;
  const currentPrices: React.ReactNode[] = [];

  const currentPricesArrayLength = {
    [TAB_ONE_YEAR]: oneYearPrices.length,
    [TAB_ONE_MONTH]: oneMonthPrices.length,
  };

  const onCloseConfirmationModal = () => {
    sessionStorage.removeItem('equally_ca-temp');
    setConfirmationModal(false);
  };

  const onUpdateWithNewItem = () => {
    const item = JSON.parse(sessionStorage.getItem('equally_ca-temp') || '');

    if (item) {
      actions.addToCart(Number(item.id), item.duration);
      onCloseConfirmationModal();
    }
  };

  const planTemplatesNames = Object.keys(priceTemplates.planTemplatesNameMap);
  planTemplatesNames.forEach((planTemplateName, index) => {
    const similarTemplates = priceTemplates.planTemplatesNameMap[
      planTemplateName
    ].filter(
      (pt) =>
        pt.duration_unit ===
        (currentTabIdx === 0 ? tabIndexToDuration[0] : tabIndexToDuration[1]),
    );
    if (similarTemplates.length === 0) return;
    const similarTemplatesIds = similarTemplates.map((pt) => pt.id);
    const cartItems = store?.cart?.cart_items || [];

    let planTemplate = cartItems.filter((item) =>
      similarTemplatesIds.includes(item.plan_template_id),
    )?.[0]?.plan_template;

    if (!planTemplate) {
      planTemplate = similarTemplates?.[0];
    }

    const isLastFlowyItem =
      planTemplate.product_type === 'developer' &&
      index === planTemplatesNames.length - 2;

    // const isCustom = planTemplate.name.toLowerCase() === 'custom';
    const isCustom = planTemplate.price === 0;
    const getTitleTextAndFeatures = () => {
      const [header, ...args] = planTemplate?.features || [];
      try {
        const cardDetails = JSON.parse(header.replaceAll("'", '"'));
        return [cardDetails, ...(args || [])];
      } catch (error) {
        console.log(error);
        return [header, ...(args || [])];
      }
    };
    const [title, ...features] = getTitleTextAndFeatures();

    if (
      title.specialCodeOffer &&
      title.specialCodeOffer !== equallyAttribution?.special_code_offer
    ) {
      return;
    }
    (currentPrices || []).push(
      <CardContainer item key={planTemplate.name}>
        <PriceCard
          name={
            planTemplate.name === 'Custom' ? 'Enterprise' : planTemplate.name
          }
          priceID={planTemplate.id}
          title={planTemplate.product_sub_type}
          type={planTemplate.product_type}
          description={planTemplate.description}
          durationLabel={planTemplate.duration_unit}
          price={planTemplate.price}
          showGetStartedLink={isCustom}
          similarPrices={isCustom ? null : similarTemplates}
          setConfirmationModal={setConfirmationModal}
          templateBenefits={{
            pretext: title?.features_header || '',
            features: features || [],
          }}
          isLimited={title?.is_limited_time}
          isPopular={title?.is_popular}
          ctaActionType={title?.cta_action}
          ctaLink={title?.demo_link}
          ctaText={title?.cta_text}
          position={isLastFlowyItem ? 'last' : index}
        />
      </CardContainer>,
    );
  });

  return (
    <Wrapper>
      <Container>
        <Grid container direction={isSM ? 'row' : 'column'}>
          {/* <ExplanationBoxContainer>
            <ExplanationBox productType={productType || 'widget'} />
          </ExplanationBoxContainer> */}
          {!priceTemplates.isLoading && productType !== 'service' && (
            <CenteredBlock>
              <PriceTabs
                currentTabIdx={currentTabIdx}
                tabs={TABS}
                onTabChange={onTabChange}
                pricesLength={currentPricesArrayLength}
              />
            </CenteredBlock>
          )}
        </Grid>

        <Box mt="16px" />

        {!priceTemplates.isLoading && (
          <PriceCardContainer container spacing={2} alignContent="stretch">
            {currentPrices}
          </PriceCardContainer>
        )}

        {/* <Box mt="48px" /> */}

        {/* <CheckmarkSection>
        <CenterBlock></CenterBlock>
      </CheckmarkSection> */}
        <ModalPanel
          open={confirmationModal}
          handleClose={onCloseConfirmationModal}
          title="Update Cart"
        >
          <Content>
            <ModalText>
              <span>
                Multiple items of different durations cannot be added to the
                same cart.
              </span>{' '}
              <br />
              <span>
                {' '}
                Do you want to update the cart with the new item and remove the
                old item?
              </span>
            </ModalText>
            <ModalButtonContainer>
              <OutlinedButton onClick={onCloseConfirmationModal}>
                No, keep previous item
              </OutlinedButton>
              <Box ml={isWide ? '24px' : ''} />
              <ContainedButton onClick={onUpdateWithNewItem}>
                Yes, update Cart
              </ContainedButton>
            </ModalButtonContainer>
          </Content>
        </ModalPanel>
      </Container>
    </Wrapper>
  );
};

export default PriceBlock;

/** Styled Components */

const Wrapper = styled(MuiContainer)`
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
`;

const Container = styled(MuiContainer)`
  padding: 0;
`;

const ModalText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 140px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: flex-end;

  & > button {
    text-transform: capitalize;
  }
`;
const CenteredBlock = styled(CenterBlock)`
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  & .MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

const PriceCardContainer = styled(Grid)`
  padding-top: 10px;
  padding-bottom: 22px;

  max-height: 450px;
  overflow-y: scroll;

  @media screen and (max-width: 600px) {
    max-height: 100%;
    overflow-y: hidden;
  }
`;

const CardContainer = styled(Grid)`
  min-width: 95%;
`;
