/* eslint-disable camelcase,react/jsx-curly-newline,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginBottom: '0px !important',
    marginTop: '0px !important',
  },
  expansion: {
    padding: 0,
  },
}));

export const Subscription = ({ sub, isActive, index, editSub }) => {
  const levelParts = sub.level.split('_');

  const level = levelParts[0].toUpperCase();
  let years = '';

  if (levelParts[2]) {
    years = levelParts[2].indexOf('2') ? '2 Years' : '1 Year';
  }

  const classes = useStyles();
  return (
    <ExpansionPanel className={classes.root}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading} component="div">
          <div className="my-plan-expansion-header">
            <div className="my-plan-expansion-header-item">{`#${index}`}</div>
            <div className="my-plan-expansion-header-item">|</div>
            <div className="my-plan-expansion-header-item">{sub.website}</div>
            <div
              className="my-plan-expansion-header-item"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                editSub(sub);
                return false;
              }}
            >
              <img src="pencil.png" alt="edit subscription" />
            </div>
          </div>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansion}>
        <div className="my-plan-subscription-container">
          <div className="my-plan-subscription-general">
            <img src="sticker.png" alt="logo" />
            <div className="my-plan-subscription-detail-type">
              {`${level} ${years}`}
            </div>
            <a href="http://dev.equally.ai/pricing" className="plan-details">
              Show Plan Details
            </a>
            {/* <Button variant="contained" color="primary">
              Upgrade Now
            </Button> */}
          </div>
          <div className="my-plan-subscription-details-container">
            <div className="my-plan-subscription-detail">
              <div>Status</div>
              <div
                className={`my-plan-subscription-detail-value ${
                  isActive
                    ? 'my-plan-subscription-detail-value-active'
                    : 'my-plan-subscription-detail-value-inactive'
                }`}
              >
                {isActive ? 'Active' : 'Inactive'}
              </div>
            </div>
            {/* <div className="my-plan-subscription-detail"> */}
            {/*  <div>Level</div> */}
            {/*  <div className="my-plan-subscription-detail-value my-plan-subscription-detail-value-premium"> */}
            {/*    {`${level} ${years}`} */}
            {/*  </div> */}
            {/* </div> */}
            <div className="my-plan-subscription-detail">
              <div>Valid Until</div>
              <div className="my-plan-subscription-detail-value">
                {sub.expiration
                  ? moment(sub.expiration).format('MM.DD.YYYY')
                  : ''}
              </div>
            </div>
            {/* <div className="my-plan-subscription-detail"> */}
            {/*  <div>Next Capture</div> */}
            {/*  <div className="my-plan-subscription-detail-value"> */}
            {/*    {sub.renew_date */}
            {/*      ? moment(sub.renew_date).format("MM.DD.YYYY") */}
            {/*      : ""} */}
            {/*  </div> */}
            {/* </div> */}
            {/* <div className="my-plan-subscription-detail"> */}
            {/*   <div> */}
            {/*    <FormControlLabel */}
            {/*      style={{ marginLeft: 0 }} */}
            {/*      labelPlacement="start" */}
            {/*      control={ */}
            {/*        <Switch */}
            {/*          checked={sub.renew_active} */}
            {/*          name="checkedB" */}
            {/*          color="primary" */}
            {/*        /> */}
            {/*      } */}
            {/*      label="Auto-Renewal" */}
            {/*    /> */}
            {/*   </div> */}
            {/*  <div className="my-plan-subscription-detail-value my-plan-subscription-detail-value-annually"> */}
            {/*    {RENEW_MODES_DISPLAY[sub.renew_mode]} */}
            {/*  </div> */}
            {/* </div> */}
            <div className="my-plan-subscription-detail">
              <div>Payment Method</div>
              {/* <div */}
              {/*  className={ */}
              {/*    sub.payment_method === "masterCard" */}
              {/*      ? "visa-master-crop-container" */}
              {/*      : "visa-master-crop-container visa-master-crop-container-visa" */}
              {/*  } */}
              {/* > */}
              {/*  <img src="visa_mastercard.png" alt="credit card processor" /> */}
              {/* </div> */}
              <img src="paypal.png" alt="credit card processor" />
            </div>
            <div className="my-plan-subscription-detail">
              <div>Amount</div>
              <div className="my-plan-subscription-detail-value">
                {`${sub.payment_amount} ${sub.payment_currency}`}
              </div>
            </div>
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

Subscription.propTypes = {
  sub: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  editSub: PropTypes.func.isRequired,
};
