export enum ScanType {
  automated = 'Automated',
  manual = 'Manual',
}

export interface ScanData {
  id: number;
  userId: number;
  websiteId: number;
  url: string;
  notice: number;
  warning: number;
  error: number;
  createdAt: string;
  updatedAt: string;
  type: ScanType.automated | ScanType.manual;
}

export interface TotalScansData {
  scans: ScanData[];
  totalErrors: number;
  totalNotices: number;
  totalWarnings: number;
  totalScan: number;
}

export interface ScanTotals {
  totalErrors: number;
  totalNotices: number;
  totalWarnings: number;
}
