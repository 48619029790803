import styled from '@emotion/styled';
import { useAccessibilityAccordion } from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { IssueReport } from '@equally-ai/audit-ui/src/components/issue-report/issue-report';
import { ScanReportLayout } from '@equally-ai/audit-ui/src/components/scan-report/scan-report-layout';
import { ScanReportSection } from '@equally-ai/audit-ui/src/components/scan-report/scan-report-section';
import { Box, useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setApiSuccessMessage } from '../../../../store/actions';
import StyleConstants from '../../../../common/styleConstants';
import { PdfDownloadButton } from '@equally-ai/audit-ui/src/components/PdfDownloadButton';

const ReportView = ({
  report: auditReport,
  url,
}: {
  report: ReportData;
  url: string;
}) => {
  // const urlDetails = getURLDetails(auditReport.url);
  const dispatch = useDispatch();
  const successMessage = useSelector(
    (state: any) => state.navigation.successMessage,
  );
  const isMD = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const [activeAuditGroup, setActiveAuditGroup] = useState(1);
  const [isScanReportItemVisible, setIsScanReportItemVisible] = useState(false);
  const [activeItemId, setActiveItemId] = useState('');
  const toggleSectionItem = (id: string) => {
    if (isScanReportItemVisible && activeItemId === id) {
      setIsScanReportItemVisible(false);
    } else {
      setIsScanReportItemVisible(true);
      setActiveItemId(id);
    }
  };
  const { accordionState, handleAccordionToggle } = useAccessibilityAccordion();

  const setSuccessMessage = (message: string) => {
    dispatch(setApiSuccessMessage(message));
  };

  const getUpdatedTitles = (title: string): string => {
    switch (title) {
      case 'tables':
        return 'Table Data: Cells and Columns';
      case 'clickables':
        return 'Clickable Elements: Links and Buttons';
      case 'title':
        return 'Headings: Structured and Visual Headings.';
      case 'graphics':
        return 'Graphics: Icons, Images, and Backgrounds';
      default:
        return title;
    }
  };

  return (
    <Container>
      <Box mt={isMD ? '32px' : '24px'} />
      <ButtonsContainer>
        {auditReport.list.all.length > 0 && (
          <PdfDownloadButton
            pdfReport={auditReport.list.all}
            websiteUrl={auditReport.websiteUrl}
            styleConsts={StyleConstants}
            iconSrc={'/download.svg'}
            iconAltText={'Download icon'}
            backgroundColor="#F5F7FA"
            borderStyle="2px solid #CBD2D9"
            buttonText="Download PDF"
          />
          // <PDFDownloadLink
          //   document={
          //     <ReportPdf
          //       pdfReport={auditReport.pdfReport}
          //       url={auditReport.websiteUrl}
          //     />
          //   }
          //   fileName={`${auditReport.websiteUrl}-${nanoid()}.pdf`}
          // >
          //   {({ loading }) =>
          //     loading ? 'Loading document...' : 'Get the Report'
          //   }
          // </PDFDownloadLink>
        )}
      </ButtonsContainer>
      <Box mt={isMD ? '32px' : '24px'} />
      {activeAuditGroup === 1 ? (
        <ScanReportLayout>
          {auditReport.reportGroups.all.map(
            // @ts-ignore
            ([groupTitle, currentReportIssues]) =>
              currentReportIssues.length > 0 && (
                <ScanReportSection
                  key={groupTitle}
                  accordionState={accordionState}
                  handleAccordionToggle={handleAccordionToggle}
                  accordionId={groupTitle}
                  reportSectionTitle={getUpdatedTitles(groupTitle)}
                  scanItems={currentReportIssues}
                  devDomain={undefined}
                  wcagReport={auditReport}
                  issueType={'all'}
                  activeItemId={activeItemId}
                  toggleSectionItem={toggleSectionItem}
                  successMessage={successMessage}
                  setSuccessMessage={setSuccessMessage}
                />
              ),
          )}
        </ScanReportLayout>
      ) : (
        <IssueReport
          wcagReport={auditReport}
          list={auditReport.reportList}
          devDomain={undefined}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </Container>
  );
};

ReportView.propTypes = {
  report: PropTypes.object,
};

ReportView.defaultProps = {
  report: null,
};

export default ReportView;

/** Styled Components */

const Container = styled.div``;
const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
