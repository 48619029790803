import React from 'react';
import styled from 'styled-components';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import { WcagIssuesDistributionPieChart } from './wcag-issues-distribution-pie-chart';

interface IssuesByWcagProps {
  issues: Issue[];
}
export const IssuesByWcag = (props: IssuesByWcagProps) => {
  const { issues } = props;

  return (
    <Wrapper>
      <Title>Issues by Wcag</Title>
      <WcagIssuesDistributionPieChart issues={issues} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-shadow: 0px 4px 17px 0px #08256417;
  border-radius: 10px;
  position: relative;
  background-color: #ffffff;
  width: 100%;
  max-width: 49%;
  height: 100%;
  overflow: hidden;

  .recharts-responsive-container {
    margin-top: 10px;

    @media screen and (max-width: 860px) {
      margin: auto;
    }
  }

  .line-chart-label {
    transform: rotate(-90deg);
    transform-origin: initial;
  }

  @media (pointer: none), (pointer: coarse) {
    max-width: 100%;
    overflow: scroll hidden;
    margin-top: 40px;
  }
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 18px;
  padding: 17px 20px 0;
`;
