import { useAccessibilityAccordion } from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Add } from '@material-ui/icons';
import { ScanSettingSteps } from '@equally-ai-front/common/src/types/audit-settings';
import { Divider, TypographyH3, TypographyH4 } from '../common/styled';
import { ScanStepContainer } from './components/common';
import { CreateScanStepPlaceholder } from './create-scan-step-placeholder';
import { Button } from './scan-step-action';
import { ScanStepContent } from './scan-step-content';
import { PrimaryButton } from '../../components/redesign/Controls';

interface ScanStepProps {
  steps: ScanSettingSteps[];
  setSteps: Dispatch<SetStateAction<ScanSettingSteps[]>>;
  handleAddNewStep: (
    isAuthenticationStep: boolean,
    handleAccordionToggle: (accordionId: string) => void,
  ) => void;
  isAuthStepsModified: boolean;
  isAppStepsModified: boolean;
  saveScanActions: () => Promise<void>;
  scanStepSaved: boolean;
  setScanStepSaved: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ScanStep = (props: ScanStepProps) => {
  const {
    steps,
    setSteps,
    handleAddNewStep,
    isAuthStepsModified,
    isAppStepsModified,
    saveScanActions,
    scanStepSaved,
    setScanStepSaved,
  } = props;
  const { accordionState, handleAccordionToggle } = useAccessibilityAccordion();
  const newStepRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    stepId: string,
    propToUpdate: string,
  ) => {
    const { value, checked, type } = evt.target;

    setSteps((prevSteps) =>
      prevSteps.map((step) => {
        if (step.id !== stepId) {
          return step;
        }

        const updatedStep = { ...step };
        if (type === 'checkbox') {
          updatedStep.is_mandatory = checked;
        } else if (
          propToUpdate === 'stepName' ||
          propToUpdate === 'login_page_url'
        ) {
          updatedStep[propToUpdate] = value;
        }

        return {
          ...updatedStep,
        };
      }),
    );
  };

  const handleRemoveStep = (stepId: string) => {
    setSteps((prevSteps) => prevSteps.filter((step) => step.id !== stepId));
  };

  const authenticationSteps = steps.filter(
    (step) => step.stepName === 'Authentication',
  );
  const appSteps = steps.filter((step) => step.stepName !== 'Authentication');

  useEffect(() => {
    if (appSteps.length > 0 && newStepRef.current) {
      newStepRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [appSteps.length]);

  useEffect(() => {
    if (!scanStepSaved) return;

    authenticationSteps.forEach((authStep) => {
      handleAccordionToggle(authStep.id);
    });
    appSteps.forEach((appStep) => {
      handleAccordionToggle(appStep.id);
    });
    setScanStepSaved(false);
  }, [scanStepSaved, authenticationSteps.length, appSteps.length]);

  return (
    <>
      <StepsHeaderWrapper>
        <StepsHeaderContent>
          <StepHeader>Authentication Step</StepHeader>
          <StepSubHeader>
            Create authentication step to bypass login
          </StepSubHeader>
        </StepsHeaderContent>
        <SaveButton
          aria-label="Save authentication step actions"
          type="button"
          onClick={saveScanActions}
          color="primary"
          variant="contained"
          disabled={!isAuthStepsModified}
        >
          Save
        </SaveButton>
      </StepsHeaderWrapper>
      <StepsWrapper
        isstepsempty={authenticationSteps.length === 0 ? 'true' : 'false'}
      >
        {authenticationSteps.length === 0 && (
          <CreateScanStepPlaceholder
            isAuthenticationStep
            handleAddNewStep={handleAddNewStep}
            handleAccordionToggle={handleAccordionToggle}
          />
        )}

        {authenticationSteps.map((step) => {
          const accordionId = step.id;
          return (
            <ScanStepContainer key={step.id}>
              <ScanStepContent
                accordionId={accordionId}
                step={step}
                accordionState={accordionState}
                handleAccordionToggle={handleAccordionToggle}
                steps={steps}
                setSteps={setSteps}
                handleChange={handleChange}
                handleRemoveStep={handleRemoveStep}
                isAuthenticationStep
              />
            </ScanStepContainer>
          );
        })}
      </StepsWrapper>

      <StepDivider />

      <StepsHeaderWrapper>
        <StepsHeaderContent>
          <StepHeader>App Steps</StepHeader>
          <StepSubHeader>
            Create any flow to test all your app scenarios
          </StepSubHeader>
        </StepsHeaderContent>
        <SaveButton
          aria-label="Save app steps actions"
          type="button"
          onClick={saveScanActions}
          color="primary"
          variant="contained"
          disabled={!isAppStepsModified}
        >
          Save
        </SaveButton>
      </StepsHeaderWrapper>
      {appSteps.length > 0 && (
        <AddNewStepButton
          aria-label="Add new step"
          onClick={() => handleAddNewStep(false, handleAccordionToggle)}
        >
          <Add /> <span>Add new step</span>
        </AddNewStepButton>
      )}
      <StepsWrapper isstepsempty={appSteps.length === 0 ? 'true' : 'false'}>
        {appSteps.length === 0 && (
          <CreateScanStepPlaceholder
            isAuthenticationStep={false}
            handleAddNewStep={handleAddNewStep}
            handleAccordionToggle={handleAccordionToggle}
          />
        )}

        {appSteps.map((step, idx) => {
          const accordionId = step.id;
          const isLastItem = idx === steps.length - 1;
          const ref = isLastItem ? newStepRef : null;

          return (
            <ScanStepContainer key={accordionId} ref={ref}>
              <ScanStepContent
                accordionId={accordionId}
                step={step}
                accordionState={accordionState}
                handleAccordionToggle={handleAccordionToggle}
                steps={steps}
                setSteps={setSteps}
                handleChange={handleChange}
                handleRemoveStep={handleRemoveStep}
                isAuthenticationStep={false}
              />
            </ScanStepContainer>
          );
        })}
      </StepsWrapper>
    </>
  );
};

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const HeaderText = styled.p`
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  max-width: calc(100% - 50px);
  width: 100%;

  @media screen and (max-width: 610px) {
    font-size: 12px;
  }
`;

export const TitleAndIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowBox = styled.div`
  border: 2px solid #e4e7eb;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 410px) {
    width: 37px;
  }
`;

interface ArrowIconProps {
  shouldRotate: boolean;
}

export const ArrowIcon = styled.img<ArrowIconProps>`
  transform: ${(props) => (props.shouldRotate ? 'rotate(180deg)' : 'none')};
  transition: transform 100ms;
`;

const AddNewStepButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 25px;
`;

const StepsWrapper = styled.div<{ isstepsempty: string }>`
  margin: 10px 0 40px;
  border-radius: 20px;
  border: ${(props) =>
    props.isstepsempty === 'true' ? '2px solid #e4e7eb' : ''};
`;

const StepsHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const StepsHeaderContent = styled.div`
  width: 100%;
  max-width: 50%;
`;

const StepHeader = styled(TypographyH3)`
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 10, 20, 1);
  margin-bottom: 5px;
`;

const StepSubHeader = styled(TypographyH4)`
  font-weight: 300;
  font-size: 14px;
  color: rgba(0, 10, 20, 0.65);
  margin-bottom: 15px;
`;

const StepDivider = styled(Divider)`
  margin: 40px 0;
  height: 2px;
`;

const SaveButton = styled(PrimaryButton)`
  font-weight: 500;
  padding: 0px 15px;
`;
