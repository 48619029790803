const DOMAIN_REGEX =
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;
export const isDomainNameValid = (domainName: string) =>
  DOMAIN_REGEX.test(domainName);

export const removeSlashAtUrlEnd = (url: string): string => {
  const lastCharacter = url.charAt(url.length - 1);
  const formattedUrl =
    lastCharacter === '/' && url.length > 1
      ? url.substring(0, url.length - 1)
      : url;
  return formattedUrl;
};

export const removeLeadingSlash = (str: string): string => {
  return str.replace(/^\//, '');
};
