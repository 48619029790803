import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import {
  QUERY_PARAM_REDIRECT_TOKEN,
  QUERY_PARAM_SPECIAL_OFFER,
} from '../common/magicValues';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { useQuery } from '../hooks/useQuery';
import { useAuth } from '../providers/auth-context';
import { routes } from '../routes';
import { setToken } from '../store/actions';
import { Header } from './Header';
import { Notifications } from './Notifications';
import { MobileNavigation } from './redesign';
import { EQUALLY_COOKIE_KEY, upsertCookie } from '../utils/cookie.utils';

const Main = () => {
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const { isAuthenticated } = useAuth();
  const router = useRoutes(routes);
  const user = useSelector((state) => state.user.user);

  const analytics = useAnalytics();

  const redirectToken = useQuery().get(QUERY_PARAM_REDIRECT_TOKEN);
  const specialCodeOfferParam = useQuery().get(QUERY_PARAM_SPECIAL_OFFER);
  const dispatch = useDispatch();

  const identifyUser = useCallback(() => {
    if (!user.user_id) {
      return;
    }
    analytics.identify(user.user_id, {
      email: user.email,
      name: user.name,
    });
  }, [user.user_id, user.email, user.name, analytics]);

  useEffect(() => {
    if (redirectToken) {
      dispatch(setToken(redirectToken));
    }
  }, [dispatch, redirectToken]);

  useEffect(() => {
    identifyUser();
  }, [identifyUser]);

  useEffect(() => {
    if (specialCodeOfferParam) {
      upsertCookie(
        EQUALLY_COOKIE_KEY,
        {
          special_code_offer: specialCodeOfferParam,
        },
        true,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isMobile ? (
        <Header isAuthenticated={isAuthenticated} />
      ) : (
        <MobileNavigation isAuthenticated={isAuthenticated} />
      )}
      <Notifications />
      <main className="main">{router}</main>
    </>
  );
};

export default Main;
