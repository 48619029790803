import { Box } from '@material-ui/core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import Monitor from '../../../img/Monitor.png';
import { ContainedButton } from '../../common/ActionButton';
import { TypographyH4, TypographySubtitle } from '../../common/styled';

export const InstallExtensionContent = () => {
  return (
    <Body>
      <Content>
        <ImageContainer>
          <img src={Monitor} alt="An icon of a monitor" />
        </ImageContainer>
        <Box mt="10px">
          <InstallText>Install Flowy</InstallText>
          <Subtitle>
            The Flowy Chrome extension lets you create and edit experiences
            while on your website.
          </Subtitle>
        </Box>
      </Content>
      <InstallExtensionLink
        component={RouterLink}
        target="_blank"
        rel="noopener noreferrer"
        to="https://chromewebstore.google.com/detail/flowy/fjkmnbfpagmpblgkdflondomjkaejipi"
      >
        <OpenInNewIcon sx={{ marginRight: '5px' }} />
        <span>Install Chrome extension</span>
      </InstallExtensionLink>
    </Body>
  );
};

const Body = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  margin: 20px 0;

  @media screen and (max-width: 600px) {
    margin: 10px 0;
  }
`;

const Content = styled(Box)`
  display: flex;
  align-items: flex-start;
  padding: 10px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 20px;

    & > div {
      width: 100%;
      text-align: center;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 33%;
  margin-right: 25px;

  & img {
    height: 150px;
  }
`;

const Subtitle = styled(TypographySubtitle)`
  font-size: 16px;
  font-weight: 300;
  margin-top: 10px;
`;

const InstallText = styled(TypographyH4)`
  font-size: 18px;
  font-weight: 500;
`;

const InstallExtensionLink = styled(ContainedButton)`
  background-color: #000a14;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  width: max-content;
  text-transform: capitalize;
  margin-top: 30px;

  @media screen and (max-width: 600px) {
    margin: auto;
  }
`;
