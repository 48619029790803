import styled from '@emotion/styled';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutationObserver, useOnWindowResize } from 'rooks';

const a = 0.005085508021;
const b = 0.3968930481;
const c = 0.7268360071;
const d = 0.9949144385;
const e = 0.005085508021;

const createUnderlineSVG = (width, fill) => {
  return (
    <svg
      width={`${width}`}
      height="7"
      viewBox={`0 0 ${width} 7`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`M${width * a} 3C${width * b} 2.33333 ${width * c} 3 ${
          width * d
        } 5L${width * e} 3Z`}
        fill={fill}
      />
      <path
        d={`M${width * a} 3C${width * b} 2.33333 ${width * c} 3 ${width * d} 5`}
        stroke={fill}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

const MUTATION_OBSERVER_OPTIONS = {
  attributes: true,
  attributeFilter: ['style'],
};

// TODO: Use ResizeObserver instead of WindowResize

const Underline = ({ children, space }) => {
  const theme = useTheme();
  const ref = useRef();
  const boundingClientRect = useRef();
  const [LineEl, setLineEl] = useState();

  const doUpdateLine = useCallback(() => {
    const textWidth = ref.current?.offsetWidth;
    if (textWidth) {
      const svg = createUnderlineSVG(textWidth, theme.palette.primary.main);
      setLineEl(svg);
    }
  }, [theme.palette.primary.main]);

  const onStyleChange = useCallback(() => {
    doUpdateLine();
  }, [doUpdateLine]);

  useMutationObserver(ref, onStyleChange, MUTATION_OBSERVER_OPTIONS);

  const onWindowResize = useCallback(() => {
    const oldRect = boundingClientRect.current;
    const newRect = ref.current?.getBoundingClientRect();

    if (
      newRect?.width !== oldRect?.width ||
      newRect?.height !== oldRect?.height
    ) {
      doUpdateLine();
    }

    boundingClientRect.current = newRect;
  }, [doUpdateLine]);

  useOnWindowResize(onWindowResize);

  /** Initial render */
  useEffect(() => {
    doUpdateLine();
  }, [doUpdateLine]);

  return (
    <Text space={space}>
      <span ref={ref}>{children}</span>
      <LineWrapper>{LineEl}</LineWrapper>
    </Text>
  );
};

Underline.propTypes = {
  children: PropTypes.any.isRequired,
  space: PropTypes.number,
};

Underline.defaultProps = {
  space: 0,
};

export default Underline;

/** Styled Components */

const Text = styled.span`
  position: relative;
  z-index: 1;

  padding-bottom: ${({ space }) => space}px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LineWrapper = styled.span`
  position: absolute;
  bottom: 0px;
  z-index: -1;

  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  line-height: 0;

  display: block;
`;
