import React from 'react';
import styled from 'styled-components';
import { TypographySmall } from '../../../components/common/Pill';
import { TypographyH4 } from '../../../components/common/styled';
import { PrimaryButton } from '../../../components/redesign/Controls';
import { ReactComponent as RocketIcon } from '../../../img/icons/rocket.svg';
import { ScanRecommendationsResult } from '@equally-ai-front/common/src/hooks/use-recommendations';
import { Box, CircularProgress } from '@material-ui/core';

interface RecommendationBannerProps {
  numberOfRecommendations: number;
  onReviewClick: () => void;
  scanRecommendationsResult: ScanRecommendationsResult;
}

export const RecommendationBanner = ({
  numberOfRecommendations,
  onReviewClick,
  scanRecommendationsResult,
}: RecommendationBannerProps) => {
  const { status, issues_recommendations: issuesRecommendations } =
    scanRecommendationsResult;

  return (
    <Container>
      {status === 'PENDING' ? (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CircularProgress style={{ height: 17, width: 17 }} />
        </Box>
      ) : (
        <RocketIconWrapper>
          <RocketIcon />
        </RocketIconWrapper>
      )}
      {Object.keys(issuesRecommendations).length > 0 && (
        <>
          <TextBox>
            <TypographyH4 style={{ fontSize: '18px', fontWeight: 500 }}>
              {status === 'SUCCESS'
                ? `${numberOfRecommendations} recommendations found`
                : 'Loading recommendations'}
            </TypographyH4>
            <TypographySmall>
              We found solutions for {numberOfRecommendations} issues in the
              page
            </TypographySmall>
          </TextBox>
          <ReviewButton
            aria-label="review recommendations"
            color="primary"
            variant="contained"
            onClick={onReviewClick}
          >
            Review
          </ReviewButton>
        </>
      )}
      {Object.keys(issuesRecommendations).length === 0 &&
        status === 'PENDING' && <TextBox>Loading recommendations</TextBox>}
      {(status === 'FAILED' ||
        (status === 'SUCCESS' &&
          Object.keys(issuesRecommendations).length === 0)) && (
        <TextBox>Failed to load recommendations</TextBox>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #e4e7eb;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 20px;
  padding: 10px 20px;
  flex-wrap: wrap;

  @media (pointer: none), (pointer: coarse) {
    margin: 0 0 20px;
  }
`;

const TextBox = styled.div`
  margin-left: 10px;
  margin-right: auto;

  @media (pointer: none), (pointer: coarse) {
    margin: 10px 0;
  }
`;

const ReviewButton = styled(PrimaryButton)`
  height: 32px;
`;

const RocketIconWrapper = styled.div`
  width: fit-content;

  svg {
    width: 100%;
  }
`;
