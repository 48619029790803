import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { TypographyH1, TypographyH2 } from '../../../components/common/styled';

import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { Box, InputAdornment } from '@material-ui/core';
import { GenericInput } from '../../../components/accessibility-components/generic-input';
import { Search } from '@material-ui/icons';
import { PrimaryButton } from '../../../components/redesign/Controls';

interface CreateScanUrlsListProps {
  pageUrls: string[];
  domain: DevDomain;
  currentUrls: string;
  handleClose: () => void;
  handleAddUrls: (urls: string) => any;
}

export const CreateScanUrlsList = ({
  domain,
  currentUrls,
  handleClose,
  pageUrls,
  handleAddUrls,
}: CreateScanUrlsListProps) => {
  const urlObject = useMemo(() => pageUrls.map((url) => ({ url })), [pageUrls]);
  const { currentData, handlePageChange, handleSearch, pageCount, searchTerm } =
    usePagination(urlObject, 8, 'url');

  const [selectedPages, setSelectedPages] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pagePath = event.target.name;
    if (event.target.checked) {
      setSelectedPages([...selectedPages, pagePath]);
      return;
    }

    const tempSelectedPages = [...selectedPages].filter(
      (page) => page !== pagePath,
    );
    setSelectedPages([...tempSelectedPages]);
  };

  const onSubmitUrls = () => {
    const allUrls = currentUrls.split(/,|\n/).concat(selectedPages);
    handleAddUrls(`${allUrls.join(',')},`);
  };

  const getPagePath = (url: string) => url.split(domain.name)[1];

  const addEllipsisToLabel = (label: string) =>
    `${label.substring(0, 45)}${label.length > 45 ? '...' : ''}`;

  return (
    <>
      <StyledHeader>
        <MainHeader>Choose pages to scan</MainHeader>
      </StyledHeader>

      <SubHeaderBox>
        <SubHeader>
          <strong>
            {selectedPages.length} of {pageUrls.length}
          </strong>{' '}
          URLs selected
        </SubHeader>
        <SearchInput
          value={searchTerm}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSearch(e.target.value)
          }
          ariaProps={{ 'aria-label': 'Search urls' }}
          id="search-url"
          label="Search"
          type="search"
          style={{ height: '45px' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </SubHeaderBox>
      <Box mt="20px" />
      <Box width="90%">
        <FormControl
          component="fieldset"
          variant="outlined"
          style={{ width: '100%' }}
        >
          <FormGroup>
            {currentData.map(({ url }) => {
              const pagePath = getPagePath(url);
              const label = url.split('https://').join('');
              return (
                <StyledFormControlLabel
                  key={url}
                  control={
                    <Checkbox
                      checked={selectedPages.includes(pagePath)}
                      onChange={handleChange}
                      name={pagePath}
                      color="primary"
                    />
                  }
                  title={label}
                  label={addEllipsisToLabel(label)}
                />
              );
            })}
          </FormGroup>
        </FormControl>
        <Box mt="10px" />
        <Pagination handlePageChange={handlePageChange} pageCount={pageCount} />
      </Box>
      <Box mt="10px" />
      <ButtonContainer>
        <PrimaryButton color="primary" variant="outlined" onClick={handleClose}>
          Cancel
        </PrimaryButton>
        <Box mr="5px" />
        <PrimaryButton
          color="primary"
          variant="contained"
          onClick={onSubmitUrls}
        >
          Add URLs
        </PrimaryButton>
      </ButtonContainer>
    </>
  );
};

const StyledHeader = styled.div`
  align-self: flex-start;
  margin-bottom: 5px;
`;

const MainHeader = styled(TypographyH1)`
  font-size: 24px;
  font-weight: 700;
`;

const SubHeaderBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 5px;
`;

const SubHeader = styled(TypographyH2)`
  font-size: 20px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  border: 1px solid #eee;
  border-radius: 3px;
  margin-bottom: 6px;
  height: 38px;

  @media screen and (max-width: 600px) {
    height: auto;
    margin-bottom: 4px;
    margin-right: 0;
  }
`;

const SearchInput = styled(GenericInput)`
  & > .MuiInputBase-root {
    height: 45px;
  }

  @media screen and (max-width: 600px) {
    width: 48%;
  }
`;

const ButtonContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & button {
    height: 38px;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 40px;
  }
`;
