import React, { useEffect } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/redesign/Controls';
import AddTeamMemberForm from '../../../components/redesign/Teams/add-team-member-form';
import { useAddTeamMember } from '../../../hooks/use-add-team-member';
import {
  HeaderContainer,
  HeadingTypography,
  StepContainer,
  StepContent,
  SubHeaderTypography,
} from './common';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface TeamMemberStepProps {
  onSubmit: () => void;
  onComplete: () => void;
}

export const TeamMemberStep = (props: TeamMemberStepProps) => {
  const { onComplete } = props;
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );

  const {
    handleAddMember,
    handleSubmit,
    newMembers,
    updateTeamMember,
    isEmailValid,
  } = useAddTeamMember({ onComplete, personalDetails });
  return (
    <StepContainer>
      <HeaderContainer>
        <HeadingTypography>Invite your team members</HeadingTypography>
      </HeaderContainer>
      <SubHeaderTypography>
        {`Collaborate with your team members by inviting them to your business.`}
      </SubHeaderTypography>
      <StepContent>
        <AddTeamMemberForm
          isEmailValid={isEmailValid}
          newMembers={newMembers}
          onNewMemberChange={updateTeamMember}
          onAddMemberRow={handleAddMember}
        />
      </StepContent>
      <ButtonsContainer>
        <PrimaryButton color="primary" variant="outlined" onClick={onComplete}>
          Skip for now
        </PrimaryButton>

        <PrimaryButton
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Invite and Continue
        </PrimaryButton>
      </ButtonsContainer>
    </StepContainer>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  padding: 2vh 1vw;
  gap: 20px;
  justify-content: flex-end;
  width: 100%;

  & > button {
    padding: 12px 24px;
  }
`;
