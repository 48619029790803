import { Grid } from '@material-ui/core';
import React from 'react';

import { Case } from './case';

import {
  PasswordLengthValidator,
  PasswordOneLowercaseCharValidator,
  PasswordOneNumberValidator,
  PasswordOneSpecialCharValidator,
  PasswordOneUppercaseCharValidator,
} from '../../../lib/validators';

interface PasswordChecksProps {
  password: string;
}

export const PasswordChecks = ({ password }: PasswordChecksProps) => {
  const isValidLength = PasswordLengthValidator.isValidSync(password);
  const hasLowercase = PasswordOneLowercaseCharValidator.isValidSync(password);
  const hasUppercase = PasswordOneUppercaseCharValidator.isValidSync(password);
  const hasNumber = PasswordOneNumberValidator.isValidSync(password);
  const hasSpecial = PasswordOneSpecialCharValidator.isValidSync(password);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Case strike={hasLowercase}>One lowercase character</Case>
          </Grid>
          <Grid item xs={12}>
            <Case strike={hasUppercase}>One uppercase character</Case>
          </Grid>
          <Grid item xs={12}>
            <Case strike={hasNumber}>One number</Case>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Case strike={hasSpecial}>One special character</Case>
          </Grid>
          <Grid item xs={12}>
            <Case strike={isValidLength}>8 characters minimum</Case>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
