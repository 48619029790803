import React from 'react';
import styled from 'styled-components';

interface ScanReportLayoutProps {
  children: any[];
}

export const ScanReportLayout = ({ children }: ScanReportLayoutProps) => {
  return (
    <Wrapper>
      {children.length > 0 &&
        children.map((child, idx) => (
          <div key={`scan-report-element-${idx}`}>{child}</div>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 100px;
`;
