import styled from '@emotion/styled';
import { List, ListItem } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import React from 'react';
import { TypographyH4 } from '../../../components/common/styled';

import ProductsImage from '../../../img/products-screenshot.png';

import GetApp from '../../../img/DigitalAccessibilityPlatforms_BestResults_Total.png';
import Capterra from '../../../img/DigitalAccessibilityPlatforms_EasiestToUse_EaseOfUse.png';
import G2 from '../../../img/DigitalAccessibilityPlatforms_UsersMostLikelyToRecommend_Nps.png';
import HighPerformance from '../../../img/High-Preformence.svg';
import { PageContent, Wrapper } from './styled';

export const Products = () => {
  return (
    <Wrapper bgcolor="#fbfcfd">
      <PageContent>
        <ImageContainer>
          <img src={ProductsImage} alt="Screenshot of Equally AI products" />
          <List style={{ alignSelf: 'flex-start' }}>
            <ListItem>
              <CheckCircleRoundedIcon
                style={{ fill: '#ACAAD4', height: '16px', marginRight: '8px' }}
              />{' '}
              <span>2 min installation</span>
            </ListItem>
            <ListItem>
              <CheckCircleRoundedIcon
                style={{ fill: '#ACAAD4', height: '16px', marginRight: '8px' }}
              />{' '}
              <span>All-in-One Dashboard</span>
            </ListItem>
            <ListItem>
              <CheckCircleRoundedIcon
                style={{ fill: '#ACAAD4', height: '16px', marginRight: '8px' }}
              />{' '}
              <span>Free trial, no credit card required</span>
            </ListItem>
          </List>
        </ImageContainer>
        <AwardBox>
          <AwardText>An award-winning platform</AwardText>
          <BadgeContainer>
            <img src={GetApp} alt="Equally AI Best results Badge" />

            <img src={Capterra} alt="Equally AI Easiest to use Badge" />

            <img
              src={HighPerformance}
              alt="Equally AI High Performance Badge"
            />
            <img src={G2} alt="Equally AI Likely to recommend Badge" />
          </BadgeContainer>
        </AwardBox>
      </PageContent>
    </Wrapper>
  );
};

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 30px;

  @media screen and (max-width: 600px) {
    img {
      width: 90%;
    }
  }
`;

const AwardBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const AwardText = styled(TypographyH4)`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const BadgeContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  img {
    width: 85px;
    object-fit: contain;
  }
`;
