import React, { useEffect, useState } from 'react';

import Benefits from './components/benefits';
import PriceBlock from './components/price-block/price-block-container';
import {
  Box,
  CircularProgress,
  Container as MuiContainer,
} from '@material-ui/core';
import styled from 'styled-components';
import { useCart } from '../../providers/cart-provider';
import { SelectProductType } from './components/select-plan-type';
import { ModalPanel } from '../../components/redesign/ModalPanel';
import { CenterBlock } from '../../components/StyledComponents';
import { PrimaryButton } from '../../components/redesign/Controls';
import Summary from './components/checkout-popup/summary/summary';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

interface PricingContainerProps {
  productType?: string;
  handleClose: () => void;
  showModal: boolean;
  domainId?: number;
}

export const PricingContainer = ({
  productType,
  handleClose,
  showModal,
  domainId,
}: PricingContainerProps) => {
  const {
    priceTemplates,
    store: { cart },
    actions,
  } = useCart();
  const [type, setType] = useState<string | undefined>(undefined);
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const [showCheckout, setShowCheckout] = useState(false);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);

  const handleCloseCheckout = () => {
    setShowCheckout(false);
  };

  const cartItems = cart?.cart_items || [];

  const handleAuthCheckout = async () => {
    setIsLoadingCheckout(true);
    const cartItemsArray = cartItems.map((item) => item);

    await actions.handleCartUpdate(cartItemsArray);
    await actions.resolveCartOnCheckout();

    setShowCheckout(true);
    setIsLoadingCheckout(false);
  };

  useEffect(() => {
    if (productType) {
      setType(productType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCartEmpty = cartItems.length === 0;

  return (
    <ModalPanel
      title="Choose a plan"
      open={showModal}
      handleClose={handleClose}
      modalWidth={type ? '90%' : undefined}
      modalBodyStyle={
        !isMobile
          ? {
              margin: '13px 0',
              padding: 0,
            }
          : undefined
      }
    >
      {!type && (
        <SelectProductType
          onSubmitType={(selectedType) => setType(selectedType)}
        />
      )}

      {priceTemplates.isLoading && type && (
        <CenterBlock>
          <CircularProgress color="primary" style={{ marginTop: '30px' }} />
        </CenterBlock>
      )}
      {type && (
        <Box>
          <Container>
            {showCheckout ? (
              <Summary
                domainId={domainId}
                onComplete={handleClose}
                onClose={handleCloseCheckout}
                currentBusiness={currentBusiness}
              />
            ) : (
              <PriceBlock productType={type} />
            )}

            {!priceTemplates.isLoading && priceTemplates.prices.length > 0 && (
              <BenefitsAndCheckoutContainer>
                <Benefits priceTemplates={priceTemplates.prices} />
              </BenefitsAndCheckoutContainer>
            )}
          </Container>
          {!showCheckout && !priceTemplates.isLoading && (
            <ReviewBox>
              <ReviewButton
                disabled={isCartEmpty}
                variant="contained"
                onClick={handleAuthCheckout}
              >
                Continue
              </ReviewButton>
            </ReviewBox>
          )}
        </Box>
      )}
      <LoadingView
        open={isLoadingCheckout}
        loadingText="Getting your items ready"
      />
    </ModalPanel>
  );
};

const Container = styled(Box)`
  display: flex;
  min-height: 50vh;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const BenefitsAndCheckoutContainer = styled(MuiContainer)`
  display: flex;
  flex-direction: column;
`;

const ReviewBox = styled(Box)`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  padding: 14px 50px 0;
  border-top: 2px solid #f5f7fa;

  & > button {
    padding: 8px 40px;
    height: min-content;
    margin-bottom: 4px;
  }

  @media screen and (max-width: 600px) {
    position: fixed;
    bottom: 3%;
    left: 5%;
    width: 90%;
    background: #fff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 5px 5px 18px;

    & > button {
      padding: 8px 16px;
      height: min-content;
    }
  }
`;

export const ReviewButton = withStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.on.lightHigh,
      border: '1px solid transparent',
      '&:hover': {
        backgroundColor: theme.palette.on.lightHigh,
        color: theme.palette.on.darkHigh,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  };
})(PrimaryButton);
