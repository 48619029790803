import moment from 'moment';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Issue, Scan } from '@equally-ai-front/common/src/types/issues';
import { IssuesByElement } from './issues-by-element';
import { IssuesByWcag } from './issues-by-wcag';

const getLastScan = (data: Scan[]): Scan | null => {
  if (data.length === 0) return null;

  const mostRecentScan = data.reduce((prev, current) => {
    const prevDate = new Date(prev.updated_at);
    const currentDate = new Date(current.updated_at);
    return currentDate > prevDate ? current : prev;
  });
  return mostRecentScan;
};

interface IssuesChartProps {
  scanIssuesGroupingByElement: any[];
  issues: Issue[];
  history: Scan[];
}

export const IssuesChart = (props: IssuesChartProps) => {
  const { scanIssuesGroupingByElement, issues, history } = props;
  const lastScanData = useMemo(() => getLastScan(history), [history]);

  return (
    <Wrapper>
      <>
        {issues.length > 0 ? (
          <ChartsWrapper>
            <IssuesByElement
              scanIssuesGroupingByElement={scanIssuesGroupingByElement}
              issues={issues}
            />
            <IssuesByWcag issues={issues} />
          </ChartsWrapper>
        ) : (
          <NoDataText>No Data</NoDataText>
        )}
      </>
      <>
        {lastScanData && (
          <FooterSection>
            <FooterSectionText>
              Last Scan:&nbsp;
              {moment(lastScanData.updated_at).format('DD MMM YYYY hh:mma')}
            </FooterSectionText>
          </FooterSection>
        )}
      </>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ChartsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 330px;
  overflow: hidden;
  box-shadow: 0px 4px 17px 0px #08256417;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
    box-shadow: none;
    overflow: initial;
    height: fit-content;
  }
`;

const FooterSection = styled.div`
  display: flex;
  margin-top: 10px;
`;

const FooterSectionText = styled.p`
  color: #000a14;
  margin-right: 5px;
`;

const NoDataText = styled.p`
  width: 100%;
  text-align: center;
  margin: 10px 0 20px;
  font-weight: 500;
`;
