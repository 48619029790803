import styled from '@emotion/styled';
import { Box, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { useState } from 'react';
import { FieldMap, GridRow } from './grid-row';

interface GridGridAccordionRow {
  accordionMap: FieldMap[];
  rowData: { [key: string]: string | number };
  variant: 'heading' | 'sub-heading';
  rowPadding?: string;
  children?: React.ReactNode;
}

export const GridAccordionRow = ({
  accordionMap,
  rowData,
  variant,
  rowPadding,
  children,
}: GridGridAccordionRow) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);
  return (
    <StyledContainer
      display="flex"
      flexDirection="column"
      mb="10px"
      isOpen={isOpen}
      // my="10px"
    >
      <GridRow
        rowData={rowData}
        rowMap={accordionMap}
        variant={variant}
        disableborder
        rowPadding={rowPadding}
      />

      <AccordionButton onClick={toggleOpen}>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </AccordionButton>

      {isOpen ? children : null}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)<{ isOpen: boolean }>`
  border: 1px solid #e0e0e0;
  width: 100%;
  max-height: 100%;
  position: relative;
  text-overflow: ellipsis;
  max-width: 100%;
  border-radius: 4px;
`;

const AccordionButton = styled(IconButton)`
  display: flex;
  margin-left: auto;

  position: absolute;
  right: 0;
  height: 38px;
  width: 38px;
`;
