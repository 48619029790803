import { Checkbox, Chip, FormControlLabel, Tooltip } from '@mui/material';
import React, { useRef } from 'react';
import styled from 'styled-components';

interface AttributesChipProps {
  value: string;
  attribute: string;
  withCheckbox?: boolean;
  onDelete?: () => void;
  handleChecked?: (checked: boolean) => void;
}

export const AttributesChip = ({
  attribute,
  onDelete,
  withCheckbox,
  handleChecked,
  value,
}: AttributesChipProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const chipStyle = withCheckbox && {
    '.MuiChip-label': {
      padding: '0 0 0 5px',
    },
  };

  return (
    <StyledChip
      sx={{ ...chipStyle }}
      label={
        withCheckbox ? (
          <FormControlLabel
            sx={{
              fontSize: '12px',
              '.MuiFormControlLabel-root': {
                marginRight: '11px',
              },
              '.MuiTypography-root': {
                display: 'flex',
              },
            }}
            control={
              <Checkbox
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean,
                ) => {
                  handleChecked && handleChecked(checked);
                }}
                sx={{
                  '&.MuiCheckbox-root': {
                    padding: '0 0 0 9px',
                  },
                  '& .MuiSvgIcon-root': { fontSize: 18 },
                }}
              />
            }
            label={
              <Tooltip
                title={value}
                PopperProps={{
                  container: ref.current,
                }}
              >
                <span style={{ fontSize: '13px' }}>{attribute}</span>
              </Tooltip>
            }
          />
        ) : (
          <Tooltip title={value} arrow>
            <span>{attribute}</span>
          </Tooltip>
        )
      }
      onDelete={onDelete}
    />
  );
};

const StyledChip = styled(Chip)`
  border: 1px solid #454284;
  background: #45428442;
  margin-right: 3px;
  width: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  font-size: 12px;
`;
