import styled from '@emotion/styled';
import ArrowDown from '@equally-ai-front/common/src/assets/svg/arrow-down-white.svg';
import ArrowUp from '@equally-ai-front/common/src/assets/svg/arrow-up-white.svg';
import { Avatar, Box, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { isUserPermitted } from '../lib/permissions';
import { useAuth } from '../providers/auth-context';
import { BusinessMenu } from './business-menu';
import { ComplianceManagerMenu } from './compliance-manager/components/compliance-manager-menu';

export const Header = () => {
  const personalDetails = useSelector(
    (state) => state.personalDetails.personalDetails,
  );
  const { isAuthenticated } = useAuth();

  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const currentBusiness = useSelector(
    (state) => state.business.currentBusiness,
  );

  const unAuthNavLink = pathname.includes('login') ? '/signup' : '/login';

  if (!isAuthenticated) {
    return (
      <UnAuthHeader className="header-root">
        <div className="header">
          <NavLink to={unAuthNavLink}>
            <img
              className="header-icon"
              src="/logo.svg"
              alt="Equally AI Logo"
            />
          </NavLink>
        </div>
      </UnAuthHeader>
    );
  }

  return (
    <div className="header-root">
      <div className="header">
        <img className="header-icon" src="/logo.svg" alt="Equally AI Logo" />
        <Box ml="46px" />
        <NavLink
          to={`/${currentBusiness?.slug}`}
          className="header-manage-websites-link"
          activeclassname="link-active"
        >
          My Websites
        </NavLink>
        <Box ml="10px" />
        {isUserPermitted(
          currentBusiness?.id,
          ['view.compliance_page'],
          personalDetails,
        ) && (
          <NavLink
            to={`/${currentBusiness?.slug}/compliance-manager/overview`}
            className="header-manage-websites-link"
            activeclassname="link-active"
          >
            Flowy
          </NavLink>
        )}
        {/* <Box ml="10px" /> */}
        {/* <NavLink */}
        {/*  to={`/${currentBusiness?.slug}/developers`} */}
        {/*  className={`header-manage-websites-link ${ */}
        {/*    isUserPermitted( */}
        {/*      currentBusiness?.id, */}
        {/*      ['view.flowy_page'], */}
        {/*      personalDetails, */}
        {/*    ) */}
        {/*      ? '' */}
        {/*      : 'd-none' */}
        {/*  }`} */}
        {/*  activeclassname="link-active" */}
        {/* > */}
        {/*  Developers */}
        {/* </NavLink> */}
        <Box ml="10px" />
        <NavLink
          to={`/${currentBusiness?.slug}/audit`}
          className="header-manage-websites-link"
          activeclassname="link-active"
        >
          Audit
        </NavLink>
      </div>
      <div className="header-navigation">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <StyledButton
          onClick={handleClick}
          aria-label="User Profile"
          aria-expanded={Boolean(anchorEl)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: '#fff',
            position: 'relative',
            paddingRight: '21px',
          }}
        >
          <Avatar
            alt={personalDetails && personalDetails.name}
            src={(personalDetails && personalDetails.profileUrl) || ''}
            style={{
              width: '37px',
              height: '37px',
            }}
          />
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '8px',
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              {personalDetails && personalDetails.name}
            </Typography>
            <Typography
              component="span"
              style={{
                fontSize: '12px',
              }}
            >
              {personalDetails && personalDetails.email}
            </Typography>
          </Box>
          <Typography
            component="span"
            style={{
              transform: 'scale(0.6)',
              position: 'absolute',
              top: '7px',
              right: '-2px',
            }}
          >
            <img src={anchorEl ? ArrowDown : ArrowUp} alt="arrow icon" />
          </Typography>
        </StyledButton>

        <BusinessMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
};

const StyledButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:focus-visible {
    outline: solid 3px #b8b5f6;
    outline-offset: 5px;
    outline-style: inset;
  }

  &:hover {
    background: #454284;
  }
`;

const UnAuthHeader = styled.div`
  margin-bottom: 0 !important;
`;
