import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import BusinessAPI from '../../api/business';
import {
  Business,
  NewBusiness,
  NewBusinessWithMember,
  NewMember,
  JiraSettings,
  TaskAdditionalInfo,
  TaskIssue,
} from '../../types/business';
import { BusinessState } from './types';

const initialState: BusinessState = {
  businesses: undefined,
  currentBusiness: undefined,
  loading: false,
  teamMembers: [],
  invitations: [],
  currentBusinessGptKeyExist: false,
  jiraSettings: undefined,
  jiraTasks: [],
};

type CreateBusinessAction = {
  newBusiness: NewBusiness;
  newMember: NewMember;
};
interface CreateJiraSettingsAction {
  businessId: number;
  apiKey: string;
  userEmail: string;
  boardUrl: string;
}
interface JiraSettingsResponse {
  error?: string;
  isSuccess: boolean;
  data?: JiraSettings;
}
export const getBusinesses = createAsyncThunk(
  `business/getBusiness`,
  async (_, { rejectWithValue }) => {
    const { error, data, isSuccess } = await BusinessAPI.getBusinesses();
    if (isSuccess) {
      return data;
    } else {
      return rejectWithValue(error);
    }
  },
);
export const getTeamMembers = createAsyncThunk(
  `business/getTeamMembers`,
  async ({ businessId }: any, { rejectWithValue }) => {
    const { error, data, isSuccess } =
      await BusinessAPI.getTeamMembers(businessId);
    if (isSuccess) {
      return data;
    } else {
      return rejectWithValue(error);
    }
  },
);
export const getBusinessInvitations = createAsyncThunk(
  `business/getBusinessInvitations`,
  async ({ businessId }: any, { rejectWithValue }) => {
    const { error, data, isSuccess } =
      await BusinessAPI.getInvitations(businessId);
    if (isSuccess) {
      return data;
    } else {
      return rejectWithValue(error);
    }
  },
);

export const createBusiness = createAsyncThunk(
  `business/createBusiness`,
  async (action: CreateBusinessAction, { rejectWithValue }) => {
    const newBusinessWithMember: NewBusinessWithMember = {
      name: action.newBusiness.name,
      is_partner: action.newBusiness.isPartner,
      size: action.newBusiness.size,
      auto_assign_domain: action.newBusiness.autoAssignDomain,
      new_member: {
        name: action.newMember.name,
        role: action.newMember.role,
      },
    };
    const { error, data, isSuccess } = await BusinessAPI.createBusiness(
      newBusinessWithMember,
    );
    if (isSuccess) {
      return { data, isSuccess };
    } else {
      return rejectWithValue({ isSuccess, error });
    }
  },
);
export const deleteTeamMember = createAsyncThunk(
  `business/deleteTeamMember`,
  async (action: any, { rejectWithValue }) => {
    const { error, data, isSuccess } = await BusinessAPI.deleteTeamMember(
      action.businessId,
      action.id,
    );
    if (isSuccess) {
      return data;
    } else {
      return rejectWithValue(error);
    }
  },
);
export const deleteInvitation = createAsyncThunk(
  `business/deleteInvitation`,
  async (
    action: { businessId: number; invitationId: number },
    { rejectWithValue },
  ) => {
    const { businessId, invitationId } = action;
    const { error, isSuccess } = await BusinessAPI.deleteInvitation(
      businessId,
      invitationId,
    );

    if (isSuccess) {
      return isSuccess;
    } else {
      return rejectWithValue(error);
    }
  },
);

export const getGptKey = createAsyncThunk(
  `business/getGptKey`,
  async (action: { businessId: number }, { rejectWithValue }) => {
    const { businessId } = action;
    const { error, isSuccess, data } = await BusinessAPI.getGPTKey(businessId);

    if (isSuccess) {
      return data;
    } else {
      return rejectWithValue(error);
    }
  },
);

export const createGptKey = createAsyncThunk(
  `business/createGptKey`,
  async (action: { businessId: number; key: string }, { rejectWithValue }) => {
    const { businessId, key } = action;
    const { error, isSuccess, data } = await BusinessAPI.createGPTKey(
      businessId,
      key,
    );

    if (isSuccess) {
      return data;
    } else {
      return rejectWithValue(error);
    }
  },
);

export const getJiraSettings = createAsyncThunk<
  JiraSettingsResponse,
  { businessId: number },
  {
    rejectValue: JiraSettingsResponse;
  }
>(`business/getJiraSettings`, async (action, { rejectWithValue }) => {
  const { businessId } = action;
  const { error, isSuccess, data } =
    await BusinessAPI.getJiraSettings(businessId);

  if (isSuccess) {
    const projectIssueTypes = data?.jira?.project_issue_types?.map(
      (projectIssueType: any) => ({
        iconUrl: projectIssueType?.icon_url,
        issueTypeId: projectIssueType?.issue_type_id,
        name: projectIssueType?.name,
        projectId: projectIssueType?.project_id,
      }),
    );
    const jiraSettings = {
      userEmail: data?.jira?.user_email || '',
      boardUrl: data?.jira?.board_url || '',
      isActive: data?.jira?.is_active || false,
      projectIssueTypes: projectIssueTypes || [],
      projects: data?.jira?.projects || [],
      apiKey: '',
    };
    return { isSuccess, data: jiraSettings };
  } else {
    return rejectWithValue({ isSuccess, error });
  }
});

export const createJiraSettings = createAsyncThunk<
  JiraSettingsResponse,
  CreateJiraSettingsAction,
  {
    rejectValue: JiraSettingsResponse;
  }
>(`business/createJiraSettings`, async (action, { rejectWithValue }) => {
  const { businessId, userEmail, apiKey, boardUrl } = action;
  const { error, isSuccess, data } = await BusinessAPI.createJiraSettings(
    businessId,
    { userEmail, apiKey, boardUrl },
  );

  if (isSuccess) {
    const projectIssueTypes = data?.jira?.project_issue_types?.map(
      (projectIssueType: any) => ({
        iconUrl: projectIssueType?.icon_url,
        issueTypeId: projectIssueType?.issue_type_id,
        name: projectIssueType?.name,
        projectId: projectIssueType?.project_id,
      }),
    );

    const jiraSettings = {
      userEmail: data?.jira?.user_email || '',
      boardUrl: data?.jira?.board_url || '',
      isActive: data?.jira?.is_active || false,
      projectIssueTypes: projectIssueTypes || [],
      projects: data?.jira?.projects || [],
      apiKey: '',
    };
    return { isSuccess, data: jiraSettings };
  } else {
    return rejectWithValue({ isSuccess, error });
  }
});

interface CreateJiraTaskPayload {
  businessId: number;
  issues: TaskIssue[];
  tasksAdditionalInfo: TaskAdditionalInfo[];
}
export const createJiraTasks = createAsyncThunk<
  {
    error?: string;
    isSuccess: boolean;
    data?: any;
  },
  CreateJiraTaskPayload,
  {
    rejectValue: {
      error?: string;
      isSuccess: boolean;
      data?: any;
    };
  }
>(`business/createJiraTasks`, async (action, { rejectWithValue }) => {
  const { businessId, issues, tasksAdditionalInfo } = action;
  const { error, isSuccess, data } = await BusinessAPI.createJiraTasks(
    businessId,
    issues,
    tasksAdditionalInfo,
  );

  if (isSuccess) {
    return { isSuccess, data };
  } else {
    return rejectWithValue({ isSuccess, error });
  }
});

export const getJiraTasks = createAsyncThunk<
  // JiraSettingsResponse,
  any,
  { businessId: number },
  {
    rejectValue: /* JiraSettingsResponse */ any;
  }
>(`business/getJiraTasks`, async (action, { rejectWithValue }) => {
  const { businessId } = action;
  const { error, isSuccess, data } = await BusinessAPI.getJiraTasks(businessId);

  console.log({ error, isSuccess, data }, '{ error, isSuccess, data }');
  if (isSuccess) {
    return { isSuccess, data };
  } else {
    return rejectWithValue({ isSuccess, error });
  }
});

const slice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    currentBusinessUpdated(state, action: PayloadAction<Business | undefined>) {
      state.currentBusiness = action.payload;
    },
    updateBusinessAndCurrentBusiness(
      state,
      action: PayloadAction<{
        businesses: any[] | undefined;
        currentBusiness: Business | undefined;
      }>,
    ) {
      state.currentBusiness = action.payload.currentBusiness;
      state.businesses = action.payload.businesses;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getBusinesses.pending.type]: (state) => {
      state.loading = true;
    },
    [getBusinesses.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.businesses = action.payload;
    },
    [getBusinesses.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [createBusiness.pending.type]: (state) => {
      state.loading = true;
    },
    [createBusiness.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.currentBusiness = action.payload;
    },
    [createBusiness.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [getTeamMembers.pending.type]: (state) => {
      state.loading = true;
    },
    [getTeamMembers.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.teamMembers = action.payload;
    },
    [getTeamMembers.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [deleteTeamMember.pending.type]: (state) => {
      state.loading = true;
    },
    [deleteTeamMember.fulfilled.type]: (state, action) => {
      state.loading = false;
    },
    [deleteTeamMember.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [getBusinessInvitations.pending.type]: (state) => {
      state.loading = true;
    },
    [getBusinessInvitations.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.invitations = action.payload;
    },
    [getBusinessInvitations.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [deleteInvitation.pending.type]: (state) => {
      state.loading = true;
    },
    [deleteInvitation.fulfilled.type]: (state, action) => {
      state.loading = false;
    },
    [deleteInvitation.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [getGptKey.pending.type]: (state) => {
      state.loading = true;
    },
    [getGptKey.fulfilled.type]: (state, action) => {
      state.currentBusinessGptKeyExist = action.payload;
      state.loading = false;
    },
    [getGptKey.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [createGptKey.pending.type]: (state) => {
      state.loading = true;
    },
    [createGptKey.fulfilled.type]: (state, action) => {
      state.currentBusinessGptKeyExist = true;
      state.loading = false;
    },
    [createGptKey.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [getJiraSettings.pending.type]: (state) => {
      state.loading = true;
    },
    [getJiraSettings.fulfilled.type]: (state, action) => {
      state.jiraSettings = action.payload.data;
      state.loading = false;
    },
    [getJiraSettings.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [createJiraSettings.pending.type]: (state) => {
      state.loading = true;
    },
    [createJiraSettings.fulfilled.type]: (state, action) => {
      state.jiraSettings = action.payload.data;
      state.loading = false;
    },
    [createJiraSettings.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [createJiraTasks.pending.type]: (state) => {
      state.loading = true;
    },
    [createJiraTasks.fulfilled.type]: (state, action) => {
      state.loading = false;
    },
    [createJiraTasks.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [getJiraTasks.pending.type]: (state) => {
      state.loading = true;
    },
    [getJiraTasks.fulfilled.type]: (state, action) => {
      state.jiraTasks = action.payload.data;
      state.loading = false;
    },
    [getJiraTasks.rejected.type]: (state, action) => {
      state.loading = false;
    },
  },
});
export const {
  setLoading,
  currentBusinessUpdated,
  updateBusinessAndCurrentBusiness,
} = slice.actions;
export default slice.reducer;
