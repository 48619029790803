import { useAnalytics } from '@equally-ai-front/common/src';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isNullOrUndefined } from '@equally-ai-front/common/src';
import { updateBusinessAndCurrentBusiness } from '@equally-ai-front/common/src/redux/business-slice/business';
import { checkIfTokenHasExpired } from '@equally-ai-front/common/src/utils';
import Cookies from 'js-cookie';
import React, { useCallback, useContext, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { parseJwt } from '../common/firebase';
import { getPersonalDetails } from '../common/helpers';
import {
  QUERY_PARAM_REDIRECT_TOKEN,
  QUERY_PARAM_REDIRECT_TO_SIGN_UP,
} from '../common/magicValues';
import { useQuery } from '../hooks/useQuery';
import {
  setApiError,
  setApiSuccessMessage,
  setPersonalDetails,
  setToken,
} from '../store/actions';
import { AuthUtils } from '../utils/auth.utils';

interface AuthContextProps {
  children?: React.ReactNode;
}

export type AuthType = {
  isAuthenticated: boolean;
  logout: () => void;
  loginSuccess: (token: string) => void;
  loginWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  loginWithGoogle: () => Promise<void>;
  verifyAuthentication: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
};

const AuthContext = React.createContext({} as AuthType);

export const AuthProvider = ({ children }: AuthContextProps) => {
  const [isAuth, setIsAuth] = useState(false);
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectToken = useQuery().get(QUERY_PARAM_REDIRECT_TOKEN);
  const isSignUpQueryParam = useQuery().get(QUERY_PARAM_REDIRECT_TO_SIGN_UP);
  const isLoggedOutRef = useRef(false);

  const onLogout = useCallback(() => {
    setIsAuth(false);
    Cookies.remove('accessToken');
    dispatch(
      updateBusinessAndCurrentBusiness({
        businesses: undefined,
        currentBusiness: undefined,
      }),
    );
    isLoggedOutRef.current = true;
    if (
      !isNullOrUndefined(isSignUpQueryParam) &&
      isSignUpQueryParam === 'true'
    ) {
      navigate('/signup');
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const trackUser = useCallback(
    async (token: string) => {
      const personalDetailsResponse = await getPersonalDetails(token);
      dispatch(setPersonalDetails(personalDetailsResponse.message));

      if (personalDetailsResponse.code === 200) {
        const { newAccount, isSpecialDomain, id, testAccount } =
          personalDetailsResponse.message;
        const user = parseJwt(token);
        analytics.alias(id);

        if (!newAccount) {
          analytics.track('User Signed In', {
            user_id: user.user_id,
            is_special_domain: isSpecialDomain,
            is_test_user: testAccount,
          });
        }
      }
    },
    [dispatch, analytics],
  );

  const onLoginSuccess = useCallback(
    async (token: string) => {
      try {
        dispatch(setToken(token));
        await trackUser(token);
        setIsAuth(true);
      } catch (error) {
        onLogout();
      }
    },
    [dispatch, navigate, trackUser],
  );

  const onLoginWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      try {
        const response = await AuthUtils.onLoginWithEmail(email, password);
        const { user } = response;
        if (user) {
          const {
            emailVerified,
            uid,
            email: userEmail,
            displayName,
            stsTokenManager,
          } = JSON.parse(JSON.stringify(user));

          const identifyActions = {
            email: userEmail,
            name: displayName,
          };

          analytics.identify(uid, identifyActions);

          if (!emailVerified) {
            throw new Error('Email not verified');
          }
          Cookies.set('accessToken', stsTokenManager.accessToken);
          await onLoginSuccess(stsTokenManager.accessToken);
          dispatch(setApiSuccessMessage('Login Successful', true));
        }
      } catch (error) {
        dispatch(
          setApiError(
            // @ts-ignore
            error?.message ?? AuthUtils.getErrorMessage(error?.code || ''),
          ),
        );
      }
    },
    [analytics, onLoginSuccess],
  );

  const onLoginWithGoogle = useCallback(async () => {
    try {
      const response = await AuthUtils.loginWithGoogle();

      if (!response.user) {
        return;
      }

      const token = await response.user?.getIdToken();

      const identifyActions = {
        email: response.user.email,
        name: response.user.displayName,
      };

      analytics.identify(response.user.uid, identifyActions);
      Cookies.set('accessToken', token);
      await onLoginSuccess(token);
      dispatch(setApiSuccessMessage('Login Successful', true));
    } catch (error) {
      console.log(error);
    }
  }, [onLoginSuccess, analytics]);

  const onResetPassword = useCallback(async (email: string) => {
    try {
      await AuthUtils.resetPassword(email);

      dispatch(
        setApiSuccessMessage(
          'Please check your email to complete the password reset process',
          true,
        ),
      );
    } catch (error) {
      console.log(error);
      dispatch(setApiError('Reset password error. Please try again later.'));
    }
  }, []);

  const verifyAuthentication = useCallback(async () => {
    if (isAuth) {
      return;
    }

    const token = redirectToken || Cookies.get('accessToken');
    if (!token) {
      onLogout();
      return;
    }

    const user = parseJwt(token);
    const hasExpired = checkIfTokenHasExpired(user.exp);

    if (hasExpired) {
      onLogout();
      return;
    }
    Cookies.set('accessToken', token);
    await onLoginSuccess(token);
  }, [onLoginSuccess, redirectToken, dispatch, isAuth]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuth,
        loginSuccess: onLoginSuccess,
        logout: onLogout,
        loginWithEmailAndPassword: onLoginWithEmailAndPassword,
        loginWithGoogle: onLoginWithGoogle,
        verifyAuthentication,
        resetPassword: onResetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
