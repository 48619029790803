import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { EmailVerification } from './components/email-verification';
import { LoginForm } from './components/login-form';
import { Products } from './components/products';
import { SignupForm } from './components/signup-form';
import ResetPassword from './components/reset-password';
import Cookies from 'js-cookie';
import { EQUALLY_COOKIE_KEY } from '../../utils/cookie.utils';

export const AuthContainer = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    // eslint-disable-next-line camelcase
    const equally_attribution = JSON.parse(
      Cookies.get(EQUALLY_COOKIE_KEY) || '{}',
    );
    const tapfiliateCode = equally_attribution?.tapfiliate_ref_code;
    if (tapfiliateCode) {
      navigate(`${pathname}?ref=${tapfiliateCode}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <ProductsContainer>
        <Products />
      </ProductsContainer>
      <AuthContentContainer>
        <Routes>
          <Route element={<LoginForm />} path="login" />
          <Route path="signup" element={<SignupForm />} />
          <Route path="email-verification" element={<EmailVerification />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Routes>
      </AuthContentContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  //align-content: center;

  @media screen and (max-width: 700px) {
    flex-direction: column-reverse;
  }
`;

const ProductsContainer = styled(Box)`
  width: 100%;
  //max-width: 70%;
  flex-basis: 60%;
  @media (max-width: 1300px) {
    flex-basis: 50%;
  }
`;
const AuthContentContainer = styled(Box)`
  width: 100%;
  //max-width: 30%;
  flex-basis: 40%;
  @media (max-width: 1300px) {
    flex-basis: 50%;
  }
`;
