import React, { useMemo } from 'react';
import { getCriteriaValues } from '@equally-ai-front/common/src/lib/accessibility-report';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import {
  capitalizeFirstLetter,
  calculatePercentage,
} from '@equally-ai-front/common';
import styled from 'styled-components';
import { Chart } from 'react-google-charts';
import { useMediaQuery } from '@material-ui/core';

const formatWcagGuidelineAsReadable = (guideline: string): string => {
  const guidelineNum = guideline.slice(-3).replace('_', '.');
  return `Guideline ${guidelineNum}`;
};

const getIssuesWcagDistribution = (issues: Issue[]): Record<string, number> => {
  const wcagDistributionMap: Record<string, number> = {};
  const issuesCriteriaValues = issues.map((issue) =>
    getCriteriaValues(issue.code),
  );

  issuesCriteriaValues.forEach(({ guideline }) => {
    const readableGuideline = formatWcagGuidelineAsReadable(guideline);
    if (!wcagDistributionMap[readableGuideline]) {
      wcagDistributionMap[readableGuideline] = 1;
      return;
    }
    wcagDistributionMap[readableGuideline] += 1;
  });
  return wcagDistributionMap;
};

const colors = ['#454284', '#4A5284', '#596391', '#6B739D', '#7C7BA8'];
const options = {
  title: '',
  colors,
  pieSliceText: 'percentage',
  pieSliceTextStyle: {
    color: '#ffffff',
    fontSize: 8,
  },
  chartArea: {
    width: '70%',
    height: '70%',
  },
  sliceVisibilityThreshold: 0.0001,
};

interface WcagIssuesDistributionPieChartProps {
  issues: Issue[];
}

export const WcagIssuesDistributionPieChart = (
  props: WcagIssuesDistributionPieChartProps,
) => {
  const { issues } = props;
  const isLargeScreen = useMediaQuery('(min-width: 1300px)');

  const chartWidth = useMemo(
    () => (isLargeScreen ? 800 : 400),
    [isLargeScreen],
  );

  const pieChartDataForWcagIssuesDistribution = useMemo(() => {
    const wcagCriteria = getIssuesWcagDistribution(issues);
    const wcagIssuesGuideline = Object.entries(wcagCriteria);
    return [
      ['WCAG Guideline', 'Number of Issues'],
      ...wcagIssuesGuideline.map(([wcagGuidelineName, issuesCount]) => [
        `${capitalizeFirstLetter(wcagGuidelineName)} (${calculatePercentage(
          issuesCount,
          issues.length,
        )}%)`,
        issuesCount,
      ]),
    ];
  }, [issues]);

  return (
    <PieChartWrapper>
      <Chart
        key={chartWidth}
        chartType="PieChart"
        data={pieChartDataForWcagIssuesDistribution}
        options={options}
        width={chartWidth}
        height={280}
      />
    </PieChartWrapper>
  );
};

const PieChartWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 0;
  width: 100%;

  @media screen and (max-width: 1300px) and (min-width: 1030px) {
    max-width: 320px;
  }

  @media screen and (min-width: 1300px) {
    max-width: 610px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 25%;
  }
`;
