import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  InfoContent,
  MainHeader,
} from '../../../components/flowy-components/components/common';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { ScansTable } from './scan-table/scans-table';
import { Box, CircularProgress } from '@material-ui/core';
import { getPageScanHistory } from '../../../store/dashboard-slice/dashboard';
import { pageScansDataMap, rowData, rowMap } from '../const';
import { RootState } from '../../../store';
import { MainContentHeader } from './main-content-header';
import { NewScanInfo } from '@equally-ai-front/common/src/types/issues';

interface DomainScansProps {
  onAudit: (url: string, cb?: () => void, scanDetails?: NewScanInfo) => void;
}

export const DomainPageScans = (props: DomainScansProps) => {
  const { onAudit } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const searchParams = new URLSearchParams(location.search);
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );

  const { pageScans, isLoading } = useSelector((state: RootState) => ({
    pageScans: state.dashboard.pageScans,
    isLoading: state.dashboard.loading,
    scannedUrls: state.dashboard.scannedUrls,
  }));

  const latestScan = useMemo(() => {
    const sortedPageScans = [...pageScans].sort((a, b) => {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });
    return sortedPageScans[0];
  }, [pageScans]);
  const pageUrl = searchParams.get('page_url');
  const { domainId } = useParams();

  useEffect(() => {
    if (!pageUrl || !domainId || !currentBusiness) {
      navigate(-1);
      return;
    }

    const getScanHistory = async () => {
      const {
        payload: { data },
      }: any = await dispatch(
        getPageScanHistory({
          businessId: currentBusiness.id,
          websiteId: +domainId,
          pageUrl,
        }),
      );
      // if (data.length > 0) {
      //   dispatch(setCurrentScan(data[0]));
      // }
    };
    void getScanHistory();
  }, [navigate, pageUrl, currentBusiness, dispatch, domainId]);

  return (
    <div>
      <div>
        <MainHeader>Here are all {pageUrl} scans</MainHeader>
        <InfoContent>
          Manage your website&apos;s scan, all in one place
        </InfoContent>
        {/* {latestScan && pageUrl && ( */}
        {/*  <MainContentHeader */}
        {/*    performAudit={onAudit} */}
        {/*    currentScan={{ ...latestScan, url: pageUrl }} */}
        {/*  /> */}
        {/* )} */}
      </div>
      {isLoading && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '30vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box mt="20px" />
      {!isLoading && (
        <ScansTable
          headingData={rowData}
          headingMap={rowMap}
          data={pageScans}
          dataMap={pageScansDataMap}
        />
      )}
    </div>
  );
};
