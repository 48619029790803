import React from 'react';
import styled from 'styled-components';

interface ScanImageProps {
  scanImageUrl: string;
}

export const ScanImage = ({ scanImageUrl }: ScanImageProps) => {
  return (
    <ImageWrapper>
      <Image src={scanImageUrl} alt="scan image screenshot" />
    </ImageWrapper>
  );
};

const ImageWrapper = styled.div`
  border: 2px solid #e4e7eb;
  border-radius: 10px;
  flex-basis: 35%;

  @media (pointer: none), (pointer: coarse) {
    max-width: 100vw;
    margin-bottom: 10vh;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;
