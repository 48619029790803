import React, { useState } from 'react';
import styled from 'styled-components';
import { useAccessibilityAccordion } from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { styled as MuiStyled } from '@mui/material/styles';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { PrimaryButton } from '../redesign/Controls';
import { JiraTasks } from './jira-tasks';
import { JiraTaskFormFields } from './popup';
import { JiraTaskIssues } from './jira-task-issues';
import {
  TaskIssue,
  TaskAdditionalInfo,
} from '@equally-ai-front/common/src/types/business';

interface StepTwoFormFieldsProps {
  selectedIssues: TaskIssue[];
  selectedDevToolsIssues: DevtoolsSolution[];
  formFields: JiraTaskFormFields;
  handlePrevStep: () => void;
  tasksAdditionalInfo: TaskAdditionalInfo[];
  setTasksAdditionalInfo: React.Dispatch<
    React.SetStateAction<TaskAdditionalInfo[]>
  >;
}

export const StepTwoFormFields = (props: StepTwoFormFieldsProps) => {
  const {
    selectedIssues,
    selectedDevToolsIssues,
    formFields: { issueCreationType, columns },
    handlePrevStep,
    tasksAdditionalInfo,
    setTasksAdditionalInfo,
  } = props;

  const [isScanReportItemVisible, setIsScanReportItemVisible] = useState(false);
  const [activeItemId, setActiveItemId] = useState('');
  const { accordionState, handleAccordionToggle } = useAccessibilityAccordion();

  const toggleSectionItem = (id: string) => {
    if (isScanReportItemVisible && activeItemId === id) {
      setIsScanReportItemVisible(false);
    } else {
      setIsScanReportItemVisible(true);
      setActiveItemId(id);
    }
  };

  const handleToggleAccordion = (id: string) => {
    toggleSectionItem(id);
    handleAccordionToggle(id);
  };

  return (
    <Wrapper>
      <div>
        {issueCreationType === 'create_issues' ? (
          <JiraTaskIssues
            columns={columns}
            issueCreationType={issueCreationType}
            selectedIssues={selectedIssues}
            selectedDevToolsIssues={selectedDevToolsIssues}
            accordionState={accordionState}
            handleToggleAccordion={handleToggleAccordion}
            isGrouped={false}
            tasksAdditionalInfo={tasksAdditionalInfo}
            setTasksAdditionalInfo={setTasksAdditionalInfo}
          />
        ) : (
          issueCreationType === 'group_issues' && (
            <JiraTasks
              columns={columns}
              issueCreationType={issueCreationType}
              selectedIssues={selectedIssues}
              selectedDevToolsIssues={selectedDevToolsIssues}
              accordionState={accordionState}
              handleToggleAccordion={handleToggleAccordion}
              tasksAdditionalInfo={tasksAdditionalInfo}
              setTasksAdditionalInfo={setTasksAdditionalInfo}
            />
          )
        )}
      </div>

      <ButtonWrapper>
        <PreviousButton
          type="button"
          color="primary"
          variant="contained"
          onClick={handlePrevStep}
        >
          Previous
        </PreviousButton>
        <Button type="submit" color="primary" variant="contained">
          Submit
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

const Button = MuiStyled(PrimaryButton)`
  width: 100px;
  border-radius: 12px;
`;

const PreviousButton = styled(Button)`
  margin-right: 30px;
`;
