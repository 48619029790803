import React from 'react';
import styled from '@emotion/styled';
import { Box, useMediaQuery } from '@material-ui/core';

import { ReactComponent as BenefitsArrow } from '../../../img/benefit-arrow.svg';
import { TypographyH3 } from '../../../components/common/styled';
import CheckmarkListGrid from '../../../components/common/checkmark-grid';
import { CheckmarkItem } from '../../../components/common/checkmark-item';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PlanTemplateItem } from '@equally-ai/cart-checkout';

interface BenefitsProps {
  priceTemplates: PlanTemplateItem[];
}

const Benefits = ({ priceTemplates }: BenefitsProps) => {
  const isWide = useMediaQuery('(min-width: 600px)');

  const getBenefits = () => {
    const masterItem = priceTemplates.find((item) => {
      const itemFeatures = item.features;
      let hasMasterFeatures = false;
      try {
        const details = JSON.parse(itemFeatures[0].replaceAll("'", '"'));
        hasMasterFeatures = details?.is_features_master || false;
      } catch (error) {
        hasMasterFeatures = false;
      }

      return hasMasterFeatures;
    });

    const featArray = [...(masterItem?.features || [])];
    featArray.splice(0, 1);
    return featArray;
  };

  return (
    <Container>
      <ContentWrapper>
        <TypographyH3>
          <TitleArrow>
            All Customers Get
            {isWide && (
              <Arrow>
                <BenefitsArrow />
              </Arrow>
            )}
          </TitleArrow>
        </TypographyH3>
      </ContentWrapper>
      <Box mt="10px" />
      <CheckmarkListGrid>
        {getBenefits().map((benefit) => (
          <CheckmarkItem key={benefit}>{benefit}</CheckmarkItem>
        ))}
      </CheckmarkListGrid>
    </Container>
  );
};

export default Benefits;

/** Styled Components */

const Container = styled.div`
  padding-bottom: 16px;
  margin-bottom: 32px;
  padding: 0 24px;

  @media screen and (max-width: 600px) {
    margin-bottom: 16px;
    padding: 0;
  }
`;

const TitleArrow = styled.span`
  position: relative;
  font-size: 28px;
`;

const Arrow = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(100%);
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 806px;
  text-align: center;
`;
