import { useAnalytics } from '@equally-ai-front/common/src';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { FormikHelpers, useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PROMO_URL } from '../../../common/magicValues';
import { GenericInput } from '../../../components/accessibility-components/generic-input';
import { signupFormValidationSchema as validationSchema } from '../../../lib/validators';
import { setApiError } from '../../../store/actions';
import { AuthUtils } from '../../../utils/auth.utils';
import { GoogleAuthButton } from './google-auth-button';
import { PasswordChecks } from './password-checks';
import {
  ActionButton,
  BottomSection,
  Heading,
  ORText,
  PageContent,
  StyledLink,
  TermsCheckbox,
  Wrapper,
} from './styled';
import UserApi from '@equally-ai-front/common/src/api/users';
import Cookies from 'js-cookie';

type SignupFormValues = {
  email: string;
  password: string;
  phoneNumber: string;
  name: string;
  checked: boolean;
};

const INITIAL_VALUE = {
  email: '',
  password: '',
  phoneNumber: '',
  name: '',
  checked: false,
};

export const SignupForm = () => {
  const [isPasswordFocused, setPasswordFocus] = useState(false);
  const analytics = useAnalytics();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (
      values: SignupFormValues,
      { setSubmitting }: FormikHelpers<SignupFormValues>,
    ) => {
      setSubmitting(true);
      try {
        const { user } = await AuthUtils.register(
          values.email,
          values.password,
        );
        if (user) {
          await Promise.all([
            user.updateProfile({ displayName: values.name }),
            user.sendEmailVerification(),
          ]);
          setSubmitting(false);

          analytics.identify(user.uid, {
            email: user.email,
            name: user.displayName,
          });

          navigate(`../email-verification?email=${user.email}`);
        }
      } catch (error) {
        // @ts-ignore
        dispatch(setApiError(AuthUtils.getErrorMessage(error?.code || '')));
        setSubmitting(false);
      }
    },
    [analytics, dispatch, navigate],
  );

  const formik = useFormik({
    initialValues: INITIAL_VALUE,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const doPasswordIsFocused = () => {
    setPasswordFocus(true);
  };
  const doPasswordIsNotFocused = () => {
    setPasswordFocus(false);
  };

  const disabled =
    formik.isSubmitting || (formik.submitCount > 0 && !formik.isValid);

  const passwordError = formik.errors.password;

  const showPasswordChecker = !!passwordError && isPasswordFocused;

  return (
    <Wrapper>
      <PageContent>
        <Heading>Create an account</Heading>
        <Box mt="12px" />
        <GoogleAuthButton label="Sign up with Google" />
        <ORText>OR</ORText>

        <form>
          <GenericInput
            required
            aria-required
            InputLabelProps={{ shrink: true }}
            id="email"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            ariaProps={{}}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email ? formik.errors.email : ''}
            style={{ height: '32px', marginBottom: '10px' }}
          />
          <Box mt="24px" />
          <GenericInput
            required
            aria-required
            InputLabelProps={{ shrink: true }}
            id="name"
            name="name"
            label="Full Name"
            type="text"
            variant="outlined"
            fullWidth
            ariaProps={{}}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name ? formik.errors.name : ''}
            style={{ height: '32px', marginBottom: '10px' }}
          />
          <Box mt="24px" />
          <GenericInput
            InputLabelProps={{ shrink: true }}
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number (optional)"
            type="text"
            variant="outlined"
            fullWidth
            ariaProps={{}}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
            helperText={
              formik.touched.phoneNumber ? formik.errors.phoneNumber : ''
            }
            style={{ height: '32px', marginBottom: '10px' }}
          />
          <Box mt="24px" />
          <GenericInput
            required
            aria-required
            InputLabelProps={{ shrink: true }}
            id="password"
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
            fullWidth
            ariaProps={{}}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password ? formik.errors.password : ''}
            onFocus={doPasswordIsFocused}
            onBlur={doPasswordIsNotFocused}
            style={{ height: '32px', marginBottom: '10px' }}
          />
          {showPasswordChecker && (
            <>
              <Box mt="24px" />
              <PasswordChecks password={formik.values.password} />
            </>
          )}
          <Box mt="16px" />
          <FormControl
            error={formik.touched.checked && Boolean(formik.errors.checked)}
          >
            <FormControlLabel
              label={
                <BottomSection>
                  I’ve read and accept the&nbsp;
                  <StyledLink to={`${PROMO_URL}/terms`} target="_blank">
                    Terms & Conditions
                  </StyledLink>
                </BottomSection>
              }
              control={
                <TermsCheckbox
                  disableRipple
                  name="checked"
                  color="primary"
                  icon={
                    <CheckBoxOutlineBlankIcon
                      fontSize="large"
                      style={{ color: '#E4E7EB' }}
                    />
                  }
                  checkedIcon={<CheckBoxIcon fontSize="large" />}
                  checked={formik.values.checked}
                  onChange={formik.handleChange}
                />
              }
            />
            {formik.touched.checked && formik.errors.checked && (
              <FormHelperText>
                {formik.touched.checked && formik.errors.checked}
              </FormHelperText>
            )}
          </FormControl>
        </form>

        <Box mt="16px" />

        <ActionButton onClick={formik.submitForm} disabled={disabled}>
          Create an account with email
        </ActionButton>
        <Box mt="16px" />
        <BottomSection>
          Already have an account?&nbsp;
          <StyledLink to="../login">Login</StyledLink>
        </BottomSection>
      </PageContent>
    </Wrapper>
  );
};
