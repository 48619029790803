import React, { useCallback, useEffect } from 'react';

import { extractVerificationCodeFromUrl } from '../../common/buildURL';
import { getErrorMessage } from '../../common/errorHelpers';
import { verifyEmail } from '../../common/firebase';
import { QUERY_PARAM_VERIFICATION_MODE } from '../../common/magicValues';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useQuery } from '../../hooks/useQuery';
import LoadingView from '../common/LoadingView';
import { PrimaryButton } from './Controls';
import { DrawerPanel } from './DrawerPanel';
import { ModalPanel } from './ModalPanel';
import { ResetPasswordModal } from './ResetPasswordModal';
import { useNavigate } from 'react-router-dom';

export const EmailVerified = () => {
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const [verificationStatus, setVerificationStatus] = React.useState('');
  const navigate = useNavigate();
  const [isResetPasswordLoading, setisResetPasswordLoading] =
    React.useState(true);
  const [resetPasswordModal, setResetPasswordModal] = React.useState(false);

  const verificationMode = useQuery().get(QUERY_PARAM_VERIFICATION_MODE);

  const verifyUserEmail = useCallback(() => {
    verifyEmail(extractVerificationCodeFromUrl())
      .then(() => {
        setVerificationStatus(
          'Your email was verified successfully.Thank you!',
        );
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setVerificationStatus(message);
      });
  }, []);

  useEffect(() => {
    if (verificationMode.toLowerCase() === 'resetpassword') {
      setResetPasswordModal(true);
    } else {
      verifyUserEmail();
    }

    setisResetPasswordLoading(false);
  }, [verificationMode, verifyUserEmail]);

  const closeResetPasswordModal = (message) => {
    setResetPasswordModal(false);
    setVerificationStatus(message);
  };

  const handleCloseModal = () => {
    setResetPasswordModal(false);
    setVerificationStatus('Password reset not completed');
  };

  if (isResetPasswordLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <div className="content-page">
        {verificationStatus && (
          <div className="content-page-container">
            <div className="header-page">
              <div className="header-page-text">Registration</div>
            </div>
            <div className="content-page-form">
              <div className="website-card" style={{ width: '100%' }}>
                <div className="website-card-header" style={{ width: '100%' }}>
                  <div
                    className="website-card-header-text"
                    style={{ width: '100%' }}
                  >
                    {verificationStatus}
                  </div>
                </div>
                <PrimaryButton
                  color="primary"
                  style={{ width: 204, fontSize: isMobile ? '14px' : '16px' }}
                  variant="contained"
                  onClick={() => navigate('/login')}
                >
                  Proceed to Log in
                </PrimaryButton>
              </div>
            </div>
          </div>
        )}
      </div>
      {!isMobile && (
        <ModalPanel
          title="Reset Password"
          open={resetPasswordModal}
          handleClose={handleCloseModal}
        >
          <ResetPasswordModal closeModal={closeResetPasswordModal} />
        </ModalPanel>
      )}
      {isMobile && (
        <DrawerPanel
          title="Reset Password"
          open={resetPasswordModal}
          handleClose={handleCloseModal}
        >
          <ResetPasswordModal closeModal={closeResetPasswordModal} />
        </DrawerPanel>
      )}
    </>
  );
};
