import React from 'react';
import styled from 'styled-components';
import { ReactComponent as FullyCompliantSVG } from '../../assets/fully-compliant.svg';
import Typography from '@mui/material/Typography';
import DesktopSkeleton from '../../assets/desktop-audit-view-skeleton.svg';
import MobileSkeleton from '../../assets/mobile-audit-view-skeleton.svg';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import {
  Container,
  IssueSummaryWrapper,
  IssueSummary,
  IssueSummaryContent,
  SkeletonImage,
  SkeletonImageWrapper,
  SvgWrapper,
  TypographyContainer,
  IssueSummaryContentWrapper,
  PdfDownloadButtonContainer,
} from './styles';
import DownloadIcon from '../../assets/download.svg';
import { styleConsts } from './partially-compliant';
import { ScanImage } from './scan-image';
import { PdfDownloadButton } from '../PdfDownloadButton';

interface FullyCompliantProps {
  loading: boolean;
  issues: DevtoolsSolution[];
  scanImageUrl: string;
}

export const FullyCompliant = ({
  loading,
  issues,
  scanImageUrl,
}: FullyCompliantProps) => {
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  return (
    <Container>
      {loading && (
        <SkeletonImageWrapper className="skeleton skeleton-text">
          <SkeletonImage
            src={isMobile ? MobileSkeleton : DesktopSkeleton}
            alt=""
          />
        </SkeletonImageWrapper>
      )}

      {issues.length > 0 && !loading && (
        <IssueSummaryWrapper>
          <IssueSummary>
            <ScanImage scanImageUrl={scanImageUrl} />

            <IssueSummaryContentWrapper>
              <IssueSummaryContent>
                <SvgWrapper>
                  <FullyCompliantSVG />
                </SvgWrapper>
                <TypographyContainerCompliant>
                  <Typography variant="h4" style={{ fontWeight: 500 }}>
                    Compliant
                  </Typography>
                  <Typography
                    style={{ margin: '0.3rem 0 2.5rem', fontSize: '0.95rem' }}
                  >
                    Well done! Your site is now accessible, reflecting your
                    commitment to inclusivity and user experience.
                  </Typography>
                </TypographyContainerCompliant>
              </IssueSummaryContent>

              <PdfDownloadButtonContainer>
                <PdfDownloadButton
                  pdfReport={issues}
                  websiteUrl={issues[0].domain_url}
                  styleConsts={styleConsts}
                  iconSrc={DownloadIcon}
                  iconAltText="Download icon"
                  backgroundColor="#454284"
                  borderStyle="none"
                  buttonText="Get the Report"
                />
              </PdfDownloadButtonContainer>
            </IssueSummaryContentWrapper>
          </IssueSummary>
        </IssueSummaryWrapper>
      )}
    </Container>
  );
};

const TypographyContainerCompliant = styled(TypographyContainer)`
  @media screen and (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;
