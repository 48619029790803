import styled from '@emotion/styled';
import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import { Grid, GridSize, Typography } from '@material-ui/core';
import React from 'react';
import { ArrowDownward } from '@material-ui/icons';

export type Variant = 'heading' | 'sub-heading' | 'dashboard-sub-heading';

interface GridItemProps {
  label: string | React.ReactNode;
  size: {
    xs: GridSize;
    sm: GridSize;
    md: GridSize;
  };
  variant: Variant;
  align?: 'left' | 'center' | 'right';
  hideOnMobile?: boolean;
  onClick?: (key: string) => void;
  itemKey?: string;
  icon?: React.ReactNode;
}

export const GridItem = ({
  label,
  size,
  variant,
  align,
  hideOnMobile,
  onClick,
  itemKey,
  icon,
}: GridItemProps) => {
  const isMobile = useMediaQuery(`(max-width: 600px)`);

  const display = isMobile && hideOnMobile ? 'none' : 'flex';

  return (
    <StyledGrid
      item
      xs={size.xs}
      sm={size.sm}
      md={size.md}
      style={{
        textAlign: align || 'left',
        display,
        cursor: onClick ? 'pointer' : 'auto',
      }}
      onClick={() => {
        if (onClick && itemKey) {
          onClick(itemKey);
        }
      }}
      role={onClick ? 'button' : ''}
    >
      <StyledTypography type={variant}>{label}</StyledTypography>
      {icon && icon}
    </StyledGrid>
  );
};

const StyledTypography = styled(Typography)<{ type: Variant }>`
  text-transform: ${(props) =>
    props.type === 'heading' ? 'uppercase' : 'initial'};
  font-weight: ${(props) => (props.type === 'heading' ? 700 : 400)};
  font-size: 14px;
  color: ${(props) => (props.type === 'heading' ? '#898ca6' : '#000a14')};
  width: 100%;
`;

const StyledGrid = styled(Grid)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover .grid-sort-icon {
    display: inline-block !important;
  }
`;
