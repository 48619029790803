import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import styled from '@emotion/styled';
import { PrimaryMobileButton } from '../../../../components/redesign/Controls';
import { DashboardExportPopUp } from '../dashboard-export-popup';
import { Scan } from '@equally-ai-front/common/src/types/issues';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

interface ScanTableActionsProps {
  domainScan: Scan;
}

export const ScanTableActions = (props: ScanTableActionsProps) => {
  const { domainScan } = props;
  const { devDomain } = useSelector((state: RootState) => ({
    devDomain: state.dashboard.currentDevDomain,
  }));
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? `${domainScan.id}-popover` : undefined;
  const [openExportPopUp, setOpenExportPopUp] = useState(false);

  const handleOpenExportPopUp = (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    evt.stopPropagation();
    setOpenExportPopUp(true);
    setAnchorEl(null);
  };

  const handleCloseExportPopUp = (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    evt.stopPropagation();
    setOpenExportPopUp(false);
  };

  const handleOpenOptions = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    evt.stopPropagation();
    setAnchorEl(evt.currentTarget);
  };

  const handleCloseOptions = (evt: MouseEvent) => {
    evt.stopPropagation();
    setAnchorEl(null);
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLDivElement>) => {
    evt.stopPropagation();
    // @ts-ignore
    const targetElement: HTMLElement = evt.target;
    if (evt.code === 'Space' || evt.code === 'Enter') {
      targetElement.click();
    }

    if (evt.code === 'Tab' && targetElement.hasAttribute('data-last-item')) {
      setAnchorEl(null);
    }
  };

  return (
    <ButtonContainer>
      <EllipsisButton
        aria-label="Open scan table actions"
        onClick={handleOpenOptions}
        aria-expanded={Boolean(anchorEl)}
      >
        <EllipsisButtonText>...</EllipsisButtonText>
      </EllipsisButton>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseOptions}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverContainer>
          <PopverItem
            onClick={handleOpenExportPopUp}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
            data-last-item="true"
          >
            Export
          </PopverItem>
        </PopoverContainer>
      </Popover>
      <DashboardExportPopUp
        openExportPopUp={openExportPopUp}
        handleCloseExportPopUp={handleCloseExportPopUp}
        currentBusinessId={domainScan.business_id}
        currentDevDomainId={domainScan.website_id}
        pdfReport={[]}
        hostCurrentFullUrl={domainScan.url || domainScan.domain}
        scanId={domainScan.id}
        devDomainName={devDomain?.name || ''}
      />
    </ButtonContainer>
  );
};

const ButtonContainer = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '90%',
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  },
}))(Box);

const EllipsisButton = styled(PrimaryMobileButton)({
  backgroundColor: 'transparent',
  padding: 0,
  maxWidth: 60,
  minWidth: 30,

  '@media (max-width: 600px)': {
    display: 'none',
  },
});

const EllipsisButtonText = styled.span`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

const PopoverContainer = styled.div`
  margin: 10px 15px;
`;

const PopverItem = styled.div`
  padding: 5px;
  cursor: pointer;
`;
