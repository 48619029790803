import { FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, useFormikContext } from 'formik';
import { Select as MuiSelect } from 'formik-material-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles(() => ({
  error: {
    color: '#f44336',
    margin: '8px 14px 0',
    fontSize: '0.75rem',
    minHeight: '1em',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '1em',
    letterSpacing: '0.03333e',
  },
}));

const Select = ({ children, label, required, name, ...rest }) => {
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  const classes = useStyles();
  const { touched, errors } = useFormikContext();
  const error = touched[name] && errors[name];
  return (
    <FormControl
      error={!!error}
      variant="outlined"
      style={{ width: '100%', textAlign: 'left' }}
    >
      <InputLabel ref={inputLabel} htmlFor={`${label}_id`}>
        {label}
      </InputLabel>
      <Field
        component={MuiSelect}
        required={required}
        labelWidth={labelWidth}
        name={name}
        {...rest}
      >
        {children}
      </Field>
      {!!error && <p className={classes.error}>{error}</p>}
    </FormControl>
  );
};

Select.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export default Select;
