import { Button, TextField } from '@material-ui/core';
import React from 'react';

export const Password = () => {
  return (
    <div className="password-main-container">
      <div className="password-container">
        <div className="my-plan-tab-panel-header-text">Reset Password</div>
        <TextField required label="Email" defaultValue="" variant="outlined" />
        <TextField
          className="password-input"
          required
          label="New password"
          type="password"
          variant="outlined"
        />
        <TextField
          required
          className="password-input"
          label="Confirm wew password"
          type="password"
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          style={{ width: '182px', height: '54px' }}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};
