import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export const LoadingView = () => {
  return (
    <LoadingViewWrapper>
      <CircularProgress style={{ width: 60, height: 60 }} />
    </LoadingViewWrapper>
  );
};

const LoadingViewWrapper = styled.div`
  width: 600px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
