import { CustomAccordion, CustomAccordionDetails, Header } from './common';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import React, { ReactNode } from 'react';
import { ReactComponent as ExpandIcon } from '../../../../img/ChevronDown.svg';
import { withStyles } from '@material-ui/core/styles';

export interface CustomAccordionLayoutProps {
  header: string;
  image: ReactNode;
  Component: ReactNode;
  isExpanded: boolean;
  type: string;
  onChange: (type: string) => void;
}

export const CustomAccordionLayout = (props: CustomAccordionLayoutProps) => {
  const { image, Component, header, isExpanded, onChange, type } = props;

  return (
    <CustomAccordion expanded={isExpanded} onChange={() => onChange(type)}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Header>
          {image}
          <span className="website-card-controls-btn-text">{header}</span>
        </Header>
      </AccordionSummary>
      <CustomAccordionDetails>
        <div style={{ width: '100%' }}>{Component}</div>
      </CustomAccordionDetails>
    </CustomAccordion>
  );
};

export interface EmptyCustomAccordionLayoutProps {
  header: string;
  image: ReactNode;
  ctaButton?: ReactNode;
}

export const EmptyAccordionLayout = (
  props: EmptyCustomAccordionLayoutProps,
) => {
  const { image, header, ctaButton } = props;

  return (
    <CustomAccordion expanded={false} style={{ width: '100%' }}>
      <EmptyAccordionSummary>
        <Header>
          {image}
          <span className="website-card-controls-btn-text">{header}</span>
        </Header>
        {ctaButton}
      </EmptyAccordionSummary>
    </CustomAccordion>
  );
};

const EmptyAccordionSummary = withStyles((theme) => {
  return {
    content: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    },
  };
})(AccordionSummary);
