import {
  useCart as useCartCheckout,
  usePlanTemplates,
  PriceTemplateState,
  ProductType,
  CartStore,
  useCartType,
  CartAPI,
} from '@equally-ai/cart-checkout';

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useAuth } from './auth-context';
import Cookies from 'js-cookie';
import { deleteFromCookies, EQUALLY_COOKIE_KEY } from '../utils/cookie.utils';

type OmittedInterface = Omit<useCartType, 'isLoading'>;

interface CartContextActions extends OmittedInterface {
  fetchPrices: (type: ProductType) => Promise<void>;
  updateCartState: (updatedCart: CartStore | null) => void;
}

type CartContextType = {
  actions: CartContextActions;
  store: { cart: CartStore | null; isLoading: boolean };
  priceTemplates: PriceTemplateState;
};

export const CartContext = createContext<CartContextType>(
  {} as CartContextType,
);

interface CartContextProviderProps {
  children: React.ReactNode;
}

const CartContextProvider: React.FC<CartContextProviderProps> = ({
  children,
}) => {
  const [store, setStore] = useState<CartStore | null>(null);
  const { isAuthenticated } = useAuth();

  const updateCartStateAndLocalStorage = (updatedCart: CartStore | null) => {
    setStore(updatedCart);
    if (!isAuthenticated) {
      localStorage.setItem('equally-ca', JSON.stringify(updatedCart));
    }

    const localCart = localStorage.getItem('equally-ca');
    if (isAuthenticated && localCart) {
      localStorage.removeItem('equally-ca');
    }
  };

  const { priceTemplates, fetchPrices } = usePlanTemplates();

  const getGuestCartAndResolveCart = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }

    const equally_attribution = JSON.parse(
      Cookies.get(EQUALLY_COOKIE_KEY) || '{}',
    );

    if (equally_attribution.cart_id) {
      const { data: cart, isSuccess } = await CartAPI.getCart(
        equally_attribution.cart_id,
      );

      if (isSuccess && cart) {
        const cartItems = cart.cart_items.map((item) => ({
          quantity: item.quantity,
          plan_template_id: item.plan_template_id,
        }));

        const { data } = await CartAPI.resolveCart(cartItems, cart.id);

        updateCartStateAndLocalStorage(data);
      }

      deleteFromCookies('cart_id', true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    void getGuestCartAndResolveCart();
  }, [getGuestCartAndResolveCart]);

  const {
    handleCartUpdate,
    resolveCartOnCheckout,
    copyCartOnExitCheckout,
    addToCart,
    removeFromCart,
    clearCart,
    increaseQuantity,
    decreaseQuantity,
    setItemQuantity,
    isLoading,
    onPaymentApproved,
  } = useCartCheckout({
    cart: store,
    updateCart: updateCartStateAndLocalStorage,
    isAuthenticated,
    priceTemplates: priceTemplates.prices,
  });

  const cleartCurrentCart = async () => {
    await clearCart();
    setStore(null);
    localStorage.removeItem('equally-ca');
  };

  const actions = {
    addToCart,
    removeFromCart,
    increaseQuantity,
    decreaseQuantity,
    clearCart: cleartCurrentCart,
    resolveCartOnCheckout,
    copyCartOnExitCheckout,
    fetchPrices,
    setItemQuantity,
    handleCartUpdate,
    onPaymentApproved,
    updateCartState: updateCartStateAndLocalStorage,
  };

  return (
    <CartContext.Provider
      value={{ actions, store: { cart: store, isLoading }, priceTemplates }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;

export const useCart = () => useContext(CartContext);
