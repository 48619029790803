import qs from 'query-string';
import { QUERY_PARAM_AUDIT } from './magicValues';
import { WcagReportListT } from './devToolsTypeConfig';

export * from './media.utils';
export const parseJwt = (token: string) => {
  const accessToken = token.split('.');
  const base64Url = accessToken[1];

  if (!base64Url) {
    return;
  }

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    // eslint-disable-next-line no-undef
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const checkIfTokenHasExpired = (expiryTime: number): boolean => {
  const expiryDate = new Date(expiryTime * 1000);
  const currentDate = new Date();

  return currentDate > expiryDate ? true : false;
};

export const addDomainToUrl = (url: string): string => {
  let finalUrl: string;

  if (
    url.toLowerCase().includes('http') ||
    url.toLowerCase().includes('https')
  ) {
    finalUrl = url;
  } else {
    finalUrl = `https://${url}`;
  }

  return finalUrl;
};

export const getURLQuery = () => qs.parse(window.location.search);

export const buildURL = ({
  url,
  searchParams = {},
}: {
  url: string;
  searchParams: any;
}) => {
  return qs.stringifyUrl({
    url,
    query: searchParams,
  });
};

export const buildAuditURL = (auditURL: string) => {
  const parsedQuery = getURLQuery();
  return buildURL({
    url: '/audit',
    searchParams: {
      ...parsedQuery,
      [QUERY_PARAM_AUDIT]: auditURL,
    },
  });
};

// export const extractVerificationCodeFromUrl = () => {
//   const parsedQuery = getURLQuery();
//   return parsedQuery.oobCode;
// };

export const parseHtmlEntityFromString = (htmlEntity: string) => {
  const result = new DOMParser().parseFromString(htmlEntity, 'text/html').body
    .textContent;

  return result;
};

export const combineWebsiteUrlAndPath = (
  websiteUrl: string,
  websitePath: string,
): string => {
  let result: string = websiteUrl;

  if (websitePath !== '/') {
    result += websitePath;
  }

  return result;
};

export const buildUnknownUrl = (url: string): URL => {
  let newUrlString: string = url;

  if (!url.includes('http://') && !url.includes('https://')) {
    newUrlString = `https://${newUrlString}`;
  }
  return new URL(newUrlString);
};

export const getDomainNameAndSuffix = (hostname: string): string[] => {
  const domainParts = hostname.split('.');
  const [domainName, suffix] = domainParts.slice(domainParts.length - 2);

  return [domainName, suffix];
};

export const parseJwtToUserInfo = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    // eslint-disable-next-line no-undef
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const getIssuesCount = (issues: WcagReportListT): number => {
  let count = 0;
  issues.forEach((issue) => {
    const issueLength = issue[1].length;
    count += issueLength;
  });

  return count;
};

export const getUrlAsDomainAndPath = (url: string): string => {
  const pattern = /^(?:https?:\/\/)?(?:www\.)?([^/?#]+)(.*)/;
  const match = url.match(pattern);

  if (match) {
    const domain = match[1];
    const path = match[2];
    return domain + path;
  } else {
    console.error('Invalid URL');
    return url;
  }
};

export const getPathname = (url: string): string => {
  if (url === '') {
    return url;
  }
  try {
    // Try to create a new URL object
    const urlObj = new URL(url);
    // If successful, return the pathname
    return urlObj.pathname;
  } catch (e) {
    // If an error occurs (invalid URL), manually handle it
    if (url.startsWith('/')) {
      return url; // If it starts with a '/', it's a valid path
    } else {
      return '/' + url; // Otherwise, prepend '/' to make it a valid path
    }
  }
};

export const extractDomainAndPath = (
  url: string,
): { domain: string; path: string } => {
  // Create a new URL object with the given URL
  const urlObject = new URL(url);

  // Extract domain and path properties from the URL object
  const domain = urlObject.hostname;
  const path = urlObject.pathname;

  // Return an object containing domain and path
  return { domain, path };
};
