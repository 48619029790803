import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { TypographyH1, TypographyH2 } from '../../common/styled';

const MainHeadersContainer = styled(Box)`
  margin: 2vh 0;
`;

const FormContainer = styled(Box)`
  width: 100%;
  margin: 3vh 0;
`;
const FormContent = styled(Box)`
  //max-width: 750px;
  width: 100%;
`;
export const FormContentContainer = styled(Box)`
  max-width: 750px;
`;

const InfoContent = styled(TypographyH2)`
  font-weight: 300;
  font-size: 18px;
  color: rgba(0, 10, 20, 0.65);
`;
const MainHeader = styled(TypographyH1)`
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 10, 20, 1);
  margin-bottom: 5px;
`;

export {
  MainHeader,
  MainHeadersContainer,
  FormContainer,
  FormContent,
  InfoContent,
};
