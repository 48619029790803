import { ArrowDownward } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

interface GridSortIconProps {
  isActive: boolean;
  type: 'asc' | 'desc';
}

export const GridSortIcon = ({ isActive, type }: GridSortIconProps) => {
  return (
    <IconContainer>
      <ArrowDownward
        style={{
          display: isActive ? 'inline-block' : 'none',
          transform: type === 'asc' ? 'rotate(180deg)' : 'initial',
          height: '15px',
          width: '15px',
          fill: '#aaa',
        }}
        className="grid-sort-icon"
      />
    </IconContainer>
  );
};

const IconContainer = styled.span`
  margin-left: 5px;
  display: flex;
  align-items: center;
`;
