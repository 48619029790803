import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { addTransaction } from '../store/actions';

const PayPalButton = window.paypal?.Buttons.driver('react', {
  React,
  ReactDOM,
});

export const PayPalButtons = ({
  domain,
  planType,
  planPrice,
  planDuration,
  planName,
  closeModal,
  submitForm,
  isValid,
}) => {
  const dispatch = useDispatch();

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: planPrice,
          },
        },
      ],
    });
  };

  // TODO: find the way to disable buttons
  console.log(isValid);

  // const onInit = (data, actions) => {
  //   // Disable the buttons
  //   actions.disable();
  //   if (isValid) {
  //     actions.enable();
  //   } else {
  //     actions.disable();
  //   }
  // };

  const onApprove = async (data, actions) => {
    const details = await actions.order.capture();
    dispatch(
      addTransaction({
        transaction_id: details.id,
        domain,
        type: planType,
        price: planPrice,
        duration: planDuration,
        planName,
      }),
    );

    submitForm();
    closeModal();
  };

  return (
    <PayPalButton
      disable
      createOrder={createOrder}
      onApprove={onApprove}
      // onInit={onInit}
    />
  );
};

PayPalButtons.propTypes = {
  domain: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  planType: PropTypes.string,
  planPrice: PropTypes.number,
  planDuration: PropTypes.string,
  planName: PropTypes.string,
};

PayPalButtons.defaultProps = {
  planType: '',
  planPrice: 0,
  planDuration: '',
  planName: '',
};
