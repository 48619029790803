const mapErrorToMessage = {
  'auth/invalid-action-code':
    'Invalid verification code. Incase you signed up with a Google account, click Login with Google.',
  'auth/expired-action-code':
    'The link has expired. Incase you signed up with a Google account, click Login with Google.',
};

export const getErrorMessage = (error) => {
  const message = mapErrorToMessage[error.code];
  return message || 'Verification code is invalid or expired!';
};
