/* eslint-disable camelcase,react/jsx-curly-newline,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Tab, Tabs, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveSubs, getInactiveSubs } from '../store/actions';
import { Subscription } from './Subscription';
import { SubscriptionModal } from './SubscriptionModal';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export const MyPlan = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveSubs());
    dispatch(getInactiveSubs());
  }, [dispatch]);

  const subscriptions = useSelector((state) => state.subscriptions);

  const { activeSubscriptions, inactiveSubscriptions } = subscriptions;

  const [value, setValue] = React.useState(0);
  const [subscriptionToEdit, setSubscriptionToEdit] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSubscriptionToEdit(null);
  };

  const editSubHandler = (subToEdit) => {
    setSubscriptionToEdit(subToEdit);
    handleOpen();
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <SubscriptionModal
          open={open}
          handleClose={handleClose}
          subscriptionToEdit={subscriptionToEdit}
        />
        <div className="my-plan-header-panel">
          <Tabs
            variant="fullWidth"
            value={value}
            textColor="primary"
            indicatorColor="primary"
            onChange={handleChange}
            style={{ width: '410px' }}
            aria-label="nav tabs example"
          >
            <LinkTab label="Active Subscriptions" {...a11yProps(0)} />
            <LinkTab label="Inactive Subscriptions" {...a11yProps(1)} />
          </Tabs>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            style={{ margin: '8px 8px 8px 0px' }}
            aria-haspopup="dialog"
          >
            + Add New Website
          </Button>
        </div>
        <TabPanel value={value} index={0}>
          {activeSubscriptions.map((activeSub, index) => (
            <Subscription
              key={activeSub.subscriptionID}
              sub={activeSub}
              isActive
              index={index}
              editSub={editSubHandler}
            />
          ))}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {inactiveSubscriptions.map((inactiveSub, index) => (
            <Subscription
              key={inactiveSub.subscriptionID}
              sub={inactiveSub}
              isActive={false}
              index={index}
              editSub={editSubHandler}
            />
          ))}
        </TabPanel>
      </div>
    </MuiPickersUtilsProvider>
  );
};
