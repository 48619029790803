import { Divider, Stack } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Title } from '../../components/css-components';
import { WebsiteGroup } from '../overview-page/website-group';
import IssuesStack from './issues-stack';

export const IssuesPageMobile = () => {
  return (
    <>
      <CustomStack direction="row" spacing={2} marginY={2}>
        <HeaderWrapper>
          <CustomTitle>Issues</CustomTitle>
          <WebsiteGroup />
        </HeaderWrapper>
      </CustomStack>
      <Divider orientation="horizontal" flexItem />
      <IssuesStack />
    </>
  );
};

export default IssuesPageMobile;

const CustomStack = styled(Stack)`
  @media (pointer: none), (pointer: coarse) {
    margin-top: 10px;
    margin-left: 40px;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
`;

const CustomTitle = styled(Title)`
  margin: 0 20px 0 0;
`;
