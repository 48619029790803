import styled from '@emotion/styled';
import {
  AccessibilityAccordion,
  AccordionState,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { isRecommendationEmpty } from '@equally-ai-front/common/src/lib';
import { GetSavedGptSuggestionsArgType } from '@equally-ai-front/common/src/redux/developer-slice/types';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import {
  DevtoolsSolution,
  IssueType,
  ReportData,
} from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import React, { useMemo } from 'react';
import { Box, Checkbox, Divider as MuiDivider } from '@mui/material';
import { ReactComponent as Check } from '../../assets/recommendation-check.svg';
import { ScanReportIssueDetails } from './scan-report-issue-details';

export interface ScanReportItemDetailsProps {
  activeItemId: string;
  issueId: string;
  devToolsSolution: DevtoolsSolution;
  wcagReport: ReportData;
  issueDescriptionId: string;
  gptButtonsId: string;
  copyButtonId: string;
  dismissButtonId: string;
  issueType: IssueType;
  devDomain?: DevDomain;
}

interface ScanReportSectionItemProps {
  title: string;
  level: string;
  sectionItemId: string;
  activeItemId: string;
  sectionItem: DevtoolsSolution;
  identifier: string;
  wcagReport: ReportData;
  issueType: IssueType;
  devDomain?: DevDomain;
  // doToggle: (
  //   id: string,
  //   sectionItem: DevtoolsSolution,
  //   payloadToGetSavedGptSuggestion: GetSavedGptSuggestionsArgType,
  // ) => void;
  issueIndex: number;
  toggleSectionItem: (
    id: string,
    payloadToGetSavedGptSuggestion: GetSavedGptSuggestionsArgType,
  ) => void;
  accordionState: AccordionState;
  handleAccordionToggle: (accordionId: string) => void;
  accordionId: string;
  scanReportItemDetails?: React.ComponentType<ScanReportItemDetailsProps>;
  setSuccessMessage: (message: string) => void;
  selectedIssuesId: Record<number, boolean>;
  setSelectedIssuesId: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
}

interface StatusColors {
  bgColor: string;
  color: string;
}

const IssueSeverityColors: { [x: string]: StatusColors } = {
  error: {
    bgColor: '#FF9999',
    color: '#661F20',
  },
  warning: {
    bgColor: '#f2df87',
    color: '#6f321b',
  },
  notice: {
    bgColor: '#AEEAD3',
    color: '#1E5643',
  },
};

const extractWCAGLevel = (input: string) => {
  const match = input.match(/WCAG2([A-Z]+)/);
  return match ? match[1] : '';
};

export const ScanReportSectionItem = ({
  title,
  activeItemId,
  sectionItemId,
  sectionItem,
  wcagReport,
  issueType,
  devDomain,
  // doToggle,
  issueIndex,
  toggleSectionItem,
  accordionId,
  accordionState,
  handleAccordionToggle,
  scanReportItemDetails: ScanReportItemDetails,
  setSuccessMessage,
  selectedIssuesId,
  setSelectedIssuesId,
}: ScanReportSectionItemProps) => {
  const payloadToGetSavedGptSuggestion: GetSavedGptSuggestionsArgType = useMemo(
    () => ({
      pageSelector: sectionItem.selector,
      pageName: wcagReport.websitePath,
      domain: `https://${wcagReport.websiteUrl}`,
    }),
    [sectionItem.selector, wcagReport.websitePath, wcagReport.websiteUrl],
  );

  // const getStatusColors = (status: string): StatusColors => {
  //   let statusBg: string;
  //   let statusColor: string;

  //   if (status === "failure") {
  //     statusBg = StyleConstants.COLOR_ERROR_1;
  //     statusColor = StyleConstants.COLOR_ERROR_2;
  //   } else if (status === "warning") {
  //     statusBg = StyleConstants.COLOR_WARNING_1;
  //     statusColor = StyleConstants.COLOR_WARNING_2;
  //   } else {
  //     statusBg = StyleConstants.COLOR_SUCCESS_1;
  //     statusColor = StyleConstants.COLOR_SUCCESS_2;
  //   }

  //   return {
  //     bgColor: statusBg,
  //     color: statusColor,
  //   };
  // };

  const handleToggleAccordion = () => {
    toggleSectionItem(sectionItemId, payloadToGetSavedGptSuggestion);
    handleAccordionToggle(accordionId);
  };

  const handleSelectIssue = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedIssuesId({
      ...selectedIssuesId,
      [sectionItem.id]: evt.target.checked,
    });
  };

  return (
    <Container>
      <AccessibilityAccordion
        title={title}
        accordionId={accordionId}
        accordionState={accordionState}
        handleToggleAccordion={handleToggleAccordion}
        accordionHeader={
          <HeaderWrapper>
            <TitleAndIcon>
              <Box display="flex">
                <ArrowBox>
                  <ArrowIcon
                    src="/chevron-down-black.svg"
                    alt="arrow down icon"
                    shouldRotate={accordionState[accordionId]}
                  />
                </ArrowBox>
                {sectionItem.website_id !== 0 && (
                  <Box display="flex" alignItems="center" marginLeft="5px">
                    <Checkbox
                      aria-label="Select Issue"
                      onChange={handleSelectIssue}
                      onClick={(evt) => evt.stopPropagation()}
                      checked={!!selectedIssuesId[sectionItem.id]}
                      sx={{ width: '24px', height: '24px' }}
                    />
                  </Box>
                )}
              </Box>
              <HeaderText>{title}</HeaderText>
            </TitleAndIcon>
            <RecommendationAndLevel>
              {!isRecommendationEmpty(sectionItem.recommendation) && (
                <Recommendation>
                  <Check /> <span>Recommendation</span>
                </Recommendation>
              )}
              <PillNoRadius
                bgColor={IssueSeverityColors[sectionItem.type].bgColor}
                color={IssueSeverityColors[sectionItem.type].color}
              >
                Level &nbsp;
                {extractWCAGLevel(sectionItem.code)}
              </PillNoRadius>
            </RecommendationAndLevel>
          </HeaderWrapper>
        }
      >
        <Divider />
        {ScanReportItemDetails ? (
          <ScanReportItemDetails
            activeItemId={activeItemId}
            issueId={sectionItem.id.toString()}
            devToolsSolution={sectionItem}
            wcagReport={wcagReport}
            issueType={issueType}
            issueDescriptionId={
              issueIndex === 0 ? 'firstIssueDescription' : issueIndex.toString()
            }
            gptButtonsId={
              issueIndex === 0 ? 'firstGptButton' : issueIndex.toString()
            }
            copyButtonId={
              issueIndex === 0 ? 'firstCopyButton' : issueIndex.toString()
            }
            dismissButtonId={
              issueIndex === 0 ? 'firstDismissButton' : issueIndex.toString()
            }
            devDomain={devDomain}
          />
        ) : (
          <ScanReportIssueDetails
            issueId={sectionItem.id.toString()}
            activeItemId={activeItemId}
            devToolsSolution={sectionItem}
            setSuccessMessage={setSuccessMessage}
            showSelectorBox={false}
          />
        )}
      </AccessibilityAccordion>
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
    align-items: initial;
  }
`;

const TitleAndIcon = styled.div`
  display: flex;
  width: 100%;
  max-width: 50%;
  align-items: center;

  @media (pointer: none), (pointer: coarse) {
    max-width: 100%;
    margin-bottom: 1.5vh;
  }
`;

const RecommendationAndLevel = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 45%;
  flex-wrap: wrap;

  @media (pointer: none), (pointer: coarse) {
    max-width: 100%;
  }
`;

const Recommendation = styled.p`
  margin-right: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;

  & svg {
    fill: #00afef;
    margin-right: 5px;
  }
`;

const HeaderText = styled.p`
  font-weight: 500;
  font-size: 14px;
  width: 80%;
  margin-left: 12px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (pointer: none), (pointer: coarse) {
    font-size: 12px;
    margin-left: 16px;
  }
`;

const ArrowBox = styled.div`
  border: 2px solid #e4e7eb;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 410px) {
    width: 37px;
  }
`;

interface ArrowIconProps {
  shouldRotate: boolean;
}

const ArrowIcon = styled.img<ArrowIconProps>`
  transform: ${(props) => (props.shouldRotate ? 'rotate(180deg)' : 'none')};
  transition: transform 100ms;
`;

interface PillProps {
  bgColor: string;
  color: string;
  hidePill?: boolean;
}

export const PillNoRadius = styled.span<PillProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  width: fit-content;
  height: 26px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border-radius: 5px;
  text-transform: capitalize;
  margin-left: auto;

  @media screen and (max-width: 610px) {
    font-size: 11px;
    height: 22px;
  }

  @media (pointer: none), (pointer: coarse) {
    margin-left: 0;
  }
`;

export const Divider = styled(MuiDivider)`
  height: 1px;
`;
