import {
  Backdrop,
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Field, Formik } from 'formik';
import { Checkbox, TextField } from 'formik-material-ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { addSub, getPrices, updateSub } from '../store/actions';
import { PayPalButtons } from './PayPalButtons';
import Select from './Select';

const validationSchema = Yup.object().shape({
  website: Yup.string()
    .trim()
    .max(2048, 'Length should not exceed 2048 symbols')
    .required('Website is required'),
  level: Yup.string()
    .trim()
    .max(2048, 'Length should not exceed 2048 symbols')
    .required('Level is required'),
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
  },
  field: {
    width: '100%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '800px',
    position: 'absolute',
    top: '25vh',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export const SubscriptionModal = ({
  open,
  handleClose,
  subscriptionToEdit,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getPrices());
  }, [dispatch]);
  const prices = useSelector((state) => state.prices.prices);
  prices.sort(
    (a, b) =>
      Number(a.duration) - Number(b.duration) ||
      a.price - b.price ||
      a.planName.localeCompare(b.planName),
  );

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      if (subscriptionToEdit && subscriptionToEdit.website) {
        dispatch(updateSub(values));
      } else {
        dispatch(addSub(values));
      }
      handleClose();
    } catch (error) {
      setSubmitting(false);
    }
  };

  const getPlanNameOptions = () => {
    return prices.map((price) => {
      // TODO: change when backend is ready
      let durationLabel = '';
      if (price.duration === '1') {
        durationLabel = '1 Year';
      } else if (price.duration === '2') {
        durationLabel = '2 Years';
      } else if (price.duration === '14') {
        durationLabel = '14 Days';
      }
      return (
        <MenuItem value={price.type} key={price.id}>
          {`${price.planName.toUpperCase()} - ${durationLabel}`}
        </MenuItem>
      );
    });
  };

  const getCurrentPlan = (level) => {
    return prices.find((price) => price.type === level);
  };

  const getCurrentAmount = (level) => {
    const plan = getCurrentPlan(level);
    if (plan) {
      return plan.price;
    }
    return 0;
  };

  const getExpirationDate = (level) => {
    if (level === 'trial_package') {
      return moment().add(14, 'days').toDate();
    }

    const plan = prices.find((price) => price.type === level);
    let duration = '1';
    if (plan) {
      duration = plan.duration;
    }
    switch (duration) {
      case '2':
        return moment().add(2, 'year').toDate();
      default:
        return moment().add(1, 'year').toDate();
    }
  };

  const initialValues = subscriptionToEdit || {
    website: '',
    active: true,
    level: '',
    expiration: moment().add(1, 'year').toDate(),
    renew_date: moment().add(1, 'year').toDate(),
    renew_active: true,
    payment_amount: 0,
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <h2 id="transition-modal-title">
              {subscriptionToEdit && subscriptionToEdit.website
                ? 'Edit Subscription'
                : 'Add New Subscription'}
            </h2>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({ submitForm, values, setFieldValue, isValid }) => {
                const currentPlan = getCurrentPlan(values.level) || {};

                return (
                  <Grid container spacing={1} justify="center">
                    <Grid item xs={12}>
                      <Field
                        className={classes.field}
                        component={TextField}
                        required
                        label="Website URL"
                        name="website"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        className={classes.field}
                        control={
                          <Field
                            component={Checkbox}
                            type="checkbox"
                            name="active"
                          />
                        }
                        label="Active"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        name="level"
                        label="Level"
                        required
                        inputProps={{
                          id: 'level',
                          onChange: (event) => {
                            setFieldValue(
                              'payment_amount',
                              getCurrentAmount(event.target.value),
                            );
                          },
                        }}
                      >
                        {getPlanNameOptions()}
                      </Select>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        className={classes.field}
                        component={KeyboardDatePicker}
                        value={getExpirationDate(values.level)}
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        label="Expiration"
                        disabled
                        name="expiration"
                        KeyboardButtonProps={{
                          'aria-label': 'expiration',
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12}> */}
                    {/*  <Field */}
                    {/*    className={classes.field} */}
                    {/*    component={KeyboardDatePicker} */}
                    {/*    value={getExpirationDate(values.level)} */}
                    {/*    variant="inline" */}
                    {/*    format="MM/dd/yyyy" */}
                    {/*    margin="normal" */}
                    {/*    label="Renew Date" */}
                    {/*    disabled */}
                    {/*    name="renew_date" */}
                    {/*    KeyboardButtonProps={{ */}
                    {/*      "aria-label": "renew date" */}
                    {/*    }} */}
                    {/*  /> */}
                    {/* </Grid> */}
                    {/* <Grid item xs={12}> */}
                    {/*  <FormControlLabel */}
                    {/*    control={ */}
                    {/*      <Field */}
                    {/*        component={Checkbox} */}
                    {/*        type="checkbox" */}
                    {/*        name="renew_active" */}
                    {/*      /> */}
                    {/*    } */}
                    {/*    label="Renewal Active" */}
                    {/*  /> */}
                    {/* </Grid> */}
                    <Grid item xs={12}>
                      <FormControl variant="outlined" className={classes.field}>
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Amount
                        </InputLabel>
                        <Field
                          component={OutlinedInput}
                          id="outlined-adornment-amount"
                          type="number"
                          disabled
                          name="payment_amount"
                          value={values.payment_amount}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="start">
                              year
                            </InputAdornment>
                          }
                          labelWidth={60}
                        />
                      </FormControl>
                    </Grid>
                    {values.level !== 'trial_package' ? (
                      <Grid container justify="center">
                        <Grid item xs={6}>
                          <PayPalButtons
                            domain={values.website}
                            planType={currentPlan.type}
                            planPrice={Number(currentPlan.price)}
                            planDuration={currentPlan.duration}
                            planName={currentPlan.planName}
                            closeModal={handleClose}
                            submitForm={submitForm}
                            isValid={isValid}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={submitForm}
                        style={{
                          width: '182px',
                          height: '54px',
                          marginRight: '8px',
                        }}
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClose}
                      style={{ width: '182px', height: '54px' }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                );
              }}
            </Formik>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

SubscriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  subscriptionToEdit: PropTypes.object,
};

SubscriptionModal.defaultProps = {
  subscriptionToEdit: null,
};
