import styled from '@emotion/styled';
import { useAccessibilityAccordion } from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { Box, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { IssueReport } from '../issue-report/issue-report';
import { ScanReportLayout } from '../scan-report/scan-report-layout';
import { ScanReportSection } from '../scan-report/scan-report-section';
import { useNotification } from '../../context/notifications-context';

interface AuditReportViewProps {
  auditReport: ReportData;
  url: string;
}

export const AuditReportView = ({ auditReport, url }: AuditReportViewProps) => {
  const isMD = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const [activeAuditGroup] = useState(1);
  const [isScanReportItemVisible, setIsScanReportItemVisible] = useState(false);
  const [activeItemId, setActiveItemId] = useState('');
  const toggleSectionItem = (id: string) => {
    if (isScanReportItemVisible && activeItemId === id) {
      setIsScanReportItemVisible(false);
    } else {
      setIsScanReportItemVisible(true);
      setActiveItemId(id);
    }
  };

  const { accordionState, handleAccordionToggle } = useAccessibilityAccordion();
  const { setSuccessMessage } = useNotification();

  const getUpdatedTitles = (title: string): string => {
    switch (title) {
      case 'tables':
        return 'Table Data: Cells and Columns';
      case 'clickables':
        return 'Clickable Elements: Links and Buttons';
      case 'title':
        return 'Headings: Structured and Visual Headings.';
      case 'graphics':
        return 'Graphics: Icons, Images, and Backgrounds';
      default:
        return title;
    }
  };

  return (
    <Container>
      <Box mt={isMD ? '32px' : '24px'} />
      {activeAuditGroup === 1 ? (
        <ScanReportLayout>
          {auditReport.reportGroups.all.map(
            ([groupTitle, currentReportIssues]) =>
              currentReportIssues.length > 0 && (
                <ScanReportSection
                  key={groupTitle}
                  accordionState={accordionState}
                  handleAccordionToggle={handleAccordionToggle}
                  accordionId={groupTitle}
                  reportSectionTitle={getUpdatedTitles(groupTitle)}
                  scanItems={currentReportIssues}
                  devDomain={undefined}
                  wcagReport={auditReport}
                  issueType={'all'}
                  activeItemId={activeItemId}
                  toggleSectionItem={toggleSectionItem}
                  setSuccessMessage={setSuccessMessage}
                  selectedIssuesId={{}}
                  setSelectedIssuesId={() => {}}
                  hideHowToFix
                />
              ),
          )}
        </ScanReportLayout>
      ) : (
        <IssueReport
          wcagReport={auditReport}
          list={auditReport.reportList}
          devDomain={undefined}
          setSuccessMessage={setSuccessMessage}
          selectedIssuesId={{}}
          setSelectedIssuesId={() => {}}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 85%;
`;
