import { Pagination } from '@equally-ai-front/common/src/components/pagination';
import { usePagination } from '@equally-ai-front/common/src/hooks/use-pagination';
import {
  ExtendedScanData,
  getWebsiteScanTotals,
} from '@equally-ai-front/common/src/lib/accessibility-report';
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { tableContainerClasses } from '@mui/material/TableContainer';
import MUITable from '@mui/material/Table';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { /* MouseEvent, */ useMemo, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../../hooks/use-app-dispach-selector';
// import ActionsButton from '../../components/actions-button';
import { HeaderTitle } from '../../components/css-components';
import { MuiPopover, PopoverOption } from '../../components/mui-popover';
import { TableActionPopover } from '../../components/table-action-popover';
import { TABLE_PAGE_LIMIT } from '../../utils/constants';

interface ReportProgressDataProps {
  page: string;
  critical: number;
  warnings: number;
  usability: number;
  manual: number;
  // owner: string;
  lastAudit: string;
  id: number;
}

const formatScanData = (scan: ExtendedScanData) => ({
  page: scan.url,
  critical: scan.error,
  warnings: scan.warning,
  usability: scan.notice,
  manual: scan.manualScans,
  // owner: 'Not Set',
  lastAudit: moment(scan.updatedAt).format('DD MMM YYYY h:mma'),
  id: scan.id,
});

export const ProgressReportTableSection = () => {
  const { totalScansData, websiteIds } = useAppSelector(
    (state) => state.overview,
  );

  const reportProgressData: ReportProgressDataProps[] = useMemo(() => {
    if (!totalScansData) return [];
    const websitesScansTotals = getWebsiteScanTotals(
      totalScansData.scans,
      websiteIds.length > 1,
    );

    const data = [];

    for (const websiteId of websiteIds) {
      if (websitesScansTotals[websiteId]) {
        const { scansDataMap } = websitesScansTotals[websiteId];

        for (const url of Object.keys(scansDataMap)) {
          const scanData = scansDataMap[url];
          data.push(formatScanData(scanData));
        }
      }
    }

    return data;
  }, [totalScansData, websiteIds]);

  const { pageCount, currentData, handlePageChange } = usePagination(
    reportProgressData,
    TABLE_PAGE_LIMIT,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  // const handleOpenActionPopover = (event: MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleCloseActionPopover = () => {
    setAnchorEl(null);
  };

  const [anchorElTr, setAnchorElTr] = useState<HTMLButtonElement | null>(null);

  // const handleOpenTrActionPopover = (event: MouseEvent<HTMLButtonElement>) => {
  //   setAnchorElTr(event.currentTarget);
  // };

  const handleCloseTrActionPopover = () => {
    setAnchorElTr(null);
  };

  const editWidget = () => {
    handleCloseActionPopover();
  };

  const deleteWidget = () => {
    handleCloseActionPopover();
  };

  const popoverOptions: PopoverOption[] = [
    {
      id: nanoid(),
      handleOptionClick: editWidget,
      children: <span>Edit Widget</span>,
    },
    {
      id: nanoid(),
      handleOptionClick: deleteWidget,
      children: <span>Delete Widget</span>,
    },
  ];

  return (
    <Wrapper>
      <HeaderWrapper>
        <CustomHeaderTitle>Progress Report</CustomHeaderTitle>
        {/* <ActionsButton handleClick={handleOpenActionPopover} /> */}
        <MuiPopover
          anchorEl={anchorEl}
          handleClose={handleCloseActionPopover}
          options={popoverOptions}
          popoverWidth="150px"
        />
      </HeaderWrapper>
      <TableContainer>
        <MUITable
          className="table"
          sx={{ minWidth: 650 }}
          aria-label="issues table"
        >
          <TableHead>
            <TableRow>
              <TableCell className="th">
                {websiteIds.length > 1 ? 'Websites' : 'Pages'}
              </TableCell>
              <TableCell className="th">Critical</TableCell>
              <TableCell className="th">Potential</TableCell>
              <TableCell className="th">Best Practice</TableCell>
              <TableCell className="th">Manual</TableCell>
              {/* <TableCell className="th">Owner</TableCell> */}
              <TableCell className="th">Last Audit</TableCell>
              {/* <TableCell className="th">Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.length > 0 ? (
              currentData.map((row) => {
                return (
                  <TableRow key={row.id}>
                    <TableCell className="td" scope="row">
                      {row.page}
                    </TableCell>
                    <TableCell className="td">{row.critical}</TableCell>
                    <TableCell className="td">{row.warnings}</TableCell>
                    <TableCell className="td">{row.usability}</TableCell>
                    <TableCell className="td">{row.manual}</TableCell>
                    {/* <TableCell className="td">{row.owner}</TableCell> */}
                    <TableCell className="td">{row.lastAudit}</TableCell>
                    {/* <TableCell className="td">
                    <ActionsButton handleClick={handleOpenTrActionPopover} />
                  </TableCell> */}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={7}
                  style={{ textAlign: 'center' }}
                  className="td"
                >
                  No Data
                </TableCell>
              </TableRow>
            )}
            <TableActionPopover
              anchorEl={anchorElTr}
              handleClose={handleCloseTrActionPopover}
            />
          </TableBody>
        </MUITable>
      </TableContainer>
      <Pagination pageCount={pageCount} handlePageChange={handlePageChange} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 30px;
  box-shadow: 0px 4px 17px 0px #08256417;
  border-radius: 10px;
  padding: 20px 25px;
  height: 300px;
  overflow: hidden auto;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &:hover {
    scrollbar-width: auto;
    -ms-overflow-style: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  .${tableContainerClasses.root} {
    overflow: auto hidden;
    height: 235px;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &:hover {
      scrollbar-width: auto;
      -ms-overflow-style: auto;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
  }

  tr:last-child {
    border-bottom: 1px solid #e4e7eb;
  }

  .th {
    color: #898ca6;
    font-weight: 500;
  }

  .td {
    font-weight: 500;
    color: #000a14;
    border-right: 1px solid #e4e7eb;

    &:last-child {
      border-right: none;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomHeaderTitle = styled(HeaderTitle)`
  margin-bottom: 10px;
`;
