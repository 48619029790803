import { Filters } from './filters';
import { RecommendationBanner } from './recommendation-banner';
import { ScanReport } from '../../../components/flowy-components/scan-report/scan-report';
import React, { useEffect, useMemo, useState } from 'react';
import {
  filterReportByRecommendation,
  isRecommendationEmpty,
  onSortByRecommendation,
} from '@equally-ai-front/common/src/lib';
import {
  setCurrentScan,
  setIssueSeverityFilter,
  setIssueStatuses,
  setReportType,
} from '../../../store/dashboard-slice/dashboard';
import { setApiError } from '../../../store/actions';
import { scanHistory } from '@equally-ai-front/common/src/redux/historySlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useRecommendations } from '@equally-ai-front/common/src/hooks/use-recommendations';
import { useParams } from 'react-router-dom';
import { Issue } from '@equally-ai-front/common/src/types/issues';
import { buildDevtoolsReport } from '@equally-ai-front/common/src/helpers/templates';
import { scanRecommendationsActions } from '@equally-ai-front/common/src/redux/developer-slice';
import { ComplianceService } from '@equally-ai-front/common';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { IssueReport } from '@equally-ai/audit-ui/src/components/issue-report/issue-report';
import { DashboardHeader } from './dashboard-header';
import { useQuery } from '../../../hooks/useQuery';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { JiraSelectDropdown } from '../../../components/create-jira-task/jira-select-dropdown';
import { CreateJiraTaskPopup } from '../../../components/create-jira-task/popup';
import { isUserPermitted } from '../../../lib/permissions';

enum DROPDOWN_OPTIONS {
  AddRecommendations = 'Add recommendations',
  CreateJiraIssues = 'Create Jira Issues',
}

interface IssuesContainerProps {
  successMessage: string;
  setSuccessMessage: (message: string) => void;
}
export const IssuesContainer = (props: IssuesContainerProps) => {
  const dispatch = useDispatch();
  const { successMessage, setSuccessMessage } = props;
  const { domainId, scanId } = useParams();
  const queryParams = useQuery();
  const component = queryParams.get('component');
  const isFetchingRef = React.useRef(false);
  const [selectedOption, setSelectedOption] = useState<string>(
    DROPDOWN_OPTIONS.CreateJiraIssues,
  );
  const [selectedIssuesId, setSelectedIssuesId] = useState<
    Record<number, boolean>
  >({});
  const [showCreateJiraIssuesPopup, setShowCreateJiraIssuesPopup] =
    useState(false);
  const handleToggleCreateJiraIssuesPopup = () => {
    setShowCreateJiraIssuesPopup(!showCreateJiraIssuesPopup);
  };

  const OPTIONS = [
    {
      label: DROPDOWN_OPTIONS.CreateJiraIssues,
      icon: (
        <CancelPresentationIcon
          sx={{ height: '18px', width: '18px', marginRight: '5px' }}
        />
      ),
      handleClickAction: () => {
        if (Object.entries(selectedIssuesId).length === 0) {
          dispatch(setApiError('Select an issue before proceeding!'));
          return;
        }
        handleToggleCreateJiraIssuesPopup();
      },
    },
    {
      label: DROPDOWN_OPTIONS.AddRecommendations,
      icon: (
        <DriveFileRenameOutlineRoundedIcon
          sx={{ height: '18px', width: '18px', marginRight: '5px' }}
        />
      ),
      handleClickAction: () => {},
    },
  ];

  const {
    currentReportUrl,
    severityFilter,
    reportType,
    currentIssueStatus,
    currentBusiness,
    devDomain,
  } = useSelector((state: any) => ({
    currentReportUrl: state.dashboard.currentReportUrl,
    severityFilter: state.dashboard.severityFilter,
    currentIssueStatus: state.dashboard.currentIssueStatus,
    currentBusiness: state.business.currentBusiness,
    reportType: state.dashboard.reportType,
    devDomain: state.dashboard.currentDevDomain,
  }));
  const { history, issues } = useSelector((state: RootState) => ({
    history: state.scanHistory.history,
    issues: state.scanHistory.issues,
  }));

  const currentScan = useMemo(() => {
    if (!scanId) {
      return undefined;
    }
    return history.find((scan) => scan.id === +scanId);
  }, [history, scanId]);

  const { scanRecommendationsResult } = useRecommendations({
    scan: currentScan,
    issues: issues,
    handleRecommendationCallback: (newIssues) =>
      dispatch(scanHistory.setIssues(newIssues)),
  });
  const personalDetails = useSelector(
    (state: any) => state.personalDetails.personalDetails,
  );

  const numberOfRecommendations = useMemo(
    () =>
      issues.filter((issue) => !isRecommendationEmpty(issue.recommendation))
        .length,
    [issues],
  );
  const handleReviewButtonClick = () => {
    dispatch(setIssueStatuses('all'));
    dispatch(setIssueSeverityFilter('all'));
    dispatch(setReportType('recommendation'));
  };
  const visibleWcagReport = useMemo(() => {
    if (issues.length === 0) {
      return {
        reportGroups: {
          basic: [],
          advanced: [],
          potential: [],
          all: [],
        },
        list: {
          basic: [],
          advanced: [],
          potential: [],
          all: [],
        },
        reportList: [],
        pdfReport: [],
        websiteUrl: '',
        websitePath: '',
        percentages: {
          basic: 0,
          advanced: 0,
          potential: 0,
        },
      };
    }

    const filterByStatus = (allIssues: Issue[]): Issue[] => {
      if (currentIssueStatus === 'all') {
        return allIssues;
      }
      if (currentIssueStatus === 'pending') {
        return allIssues.filter(
          (issue) => issue.status === 'pending' || issue.status === '',
        );
      }
      return allIssues.filter((issue) => issue.status === currentIssueStatus);
    };

    const filteredIssues = filterByStatus(
      filterReportByRecommendation(issues, reportType),
    );

    const data = [...filteredIssues].sort(onSortByRecommendation);

    return buildDevtoolsReport(currentReportUrl || devDomain.name, data);
  }, [
    issues,
    currentReportUrl,
    devDomain.name,
    currentIssueStatus,
    reportType,
  ]);

  useEffect(() => {
    if (isFetchingRef.current || (!scanId && !component)) {
      return;
    }
    const get = async () => {
      dispatch(scanRecommendationsActions.setLoading(true));
      const currentScanId = scanId ? +scanId : undefined;
      const currentComponent = component || undefined;
      isFetchingRef.current = true;
      const {
        isSuccess,
        error,
        data: scanData,
      } = await ComplianceService.export(
        Number(domainId),
        currentBusiness?.id,
        undefined,
        currentScanId,
        undefined,
        undefined,
        currentComponent,
      );

      if (!isSuccess || !scanData) {
        dispatch(setApiError(error));
        dispatch(scanRecommendationsActions.setLoading(false));
        dispatch(scanHistory.setIssues([]));
        dispatch(setCurrentScan(undefined));
        isFetchingRef.current = false;
        return;
      }
      const newCurrentScan = history.filter(
        (scan) => scan.id === currentScanId,
      )[0];
      dispatch(setCurrentScan(newCurrentScan));
      dispatch(scanRecommendationsActions.setLoading(false));
      dispatch(scanHistory.setIssues(scanData));
      isFetchingRef.current = false;
    };
    void get();
  }, [domainId, scanId, currentBusiness, dispatch, history, component]);

  return (
    <Section>
      <>
        <DashboardInfoContent>
          <DashboardCurrentScan>
            {currentScan && (
              <DashboardHeader
                currentScan={currentScan}
                pdfReport={visibleWcagReport.list.all}
                websiteUrl={visibleWcagReport.websiteUrl}
                currentBusiness={currentBusiness}
                currentDevDomain={devDomain}
              />
            )}
          </DashboardCurrentScan>
        </DashboardInfoContent>

        <FiltersContainer>
          <Filters
            currentIssueStatus={currentIssueStatus}
            activeFilter={currentIssueStatus}
            severity={severityFilter}
            reportType={reportType}
            disableFilterSelect={false}
          />
        </FiltersContainer>
      </>
      {isUserPermitted(
        currentBusiness?.id,
        ['view.jira_settings', 'write.jira_settings'],
        personalDetails,
      ) && (
        <Section>
          <JiraSelectDropdownWrapper>
            <JiraSelectDropdown
              isDisabled={visibleWcagReport.list.all.length === 0}
              options={OPTIONS}
              handleSelectOption={(opt) => setSelectedOption(opt)}
              selectedOption={selectedOption}
            />
          </JiraSelectDropdownWrapper>
        </Section>
      )}
      {scanRecommendationsResult && (
        <Section>
          <RecommendationBanner
            numberOfRecommendations={numberOfRecommendations}
            onReviewClick={handleReviewButtonClick}
            scanRecommendationsResult={scanRecommendationsResult}
          />
        </Section>
      )}
      {(reportType === 'html' || reportType === 'recommendation') && (
        <ScanReport
          wcagReport={visibleWcagReport}
          devDomain={devDomain}
          issueType={severityFilter}
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          selectedIssuesId={selectedIssuesId}
          setSelectedIssuesId={setSelectedIssuesId}
        />
      )}
      {reportType === 'wcag' && (
        <IssueReport
          list={visibleWcagReport.reportList}
          wcagReport={visibleWcagReport}
          devDomain={devDomain}
          setSuccessMessage={setSuccessMessage}
          selectedIssuesId={selectedIssuesId}
          setSelectedIssuesId={setSelectedIssuesId}
        />
      )}
      {showCreateJiraIssuesPopup && (
        <CreateJiraTaskPopup
          isOpen={showCreateJiraIssuesPopup}
          handleClose={handleToggleCreateJiraIssuesPopup}
          issues={issues}
          issuesDevToolsSolution={visibleWcagReport.list.all}
          selectedIssuesId={selectedIssuesId}
        />
      )}
    </Section>
  );
};

const Section = styled.section`
  margin-top: 3%;
  padding-bottom: 5px;
`;
const FiltersContainer = styled(Box)`
  margin: 20px 0;
`;

const DashboardCurrentScan = styled.div`
  background-color: #f5f7fa;
  border-radius: 16px;
  padding: 9px 20px;
  width: 100%;

  @media (pointer: none), (pointer: coarse) {
    padding: 10px 15px;
  }
`;

const DashboardInfoContent = styled.div`
  //width: 85%;
  //margin: 10px auto;
`;

const JiraSelectDropdownWrapper = styled.div`
  padding: 7px 20px;
`;
