import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { PrimaryButton } from './Controls';

const useStyles = makeStyles(() => ({
  input: {
    height: '48px',
    marginBottom: '28px',
    '& .MuiInputLabel-formControl': {
      top: '-4px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '14.5px 14px',
    },
    '& .PrivateNotchedOutline-root-62': {
      top: '2px',
    },
  },
}));

export const EmailAddress = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  return (
    <div className="content-page">
      <div className="content-page-container">
        <div className="header-page">
          <div className="header-page-text">Email address</div>
        </div>
        <div className="content-page-form">
          <TextField
            className={classes.input}
            label="New email address"
            defaultValue=""
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <TextField
            className={classes.input}
            label="Password"
            type="password"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <PrimaryButton
            color="primary"
            style={{ width: 204, fontSize: isMobile ? '14px' : '16px' }}
            variant="contained"
          >
            Update Email Address
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};
