import SearchIcon from '@equally-ai-front/common/src/assets/svg/search-symbol-200.svg';
import { Business } from '@equally-ai-front/common/src/types/business';
import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface BusinessMenuSearchProps {
  handleBusinessCallback: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  businesses: Business[];
}

export const BusinessMenuSearch = (props: BusinessMenuSearchProps) => {
  const { businesses, handleBusinessCallback } = props;

  return (
    <TextFieldWrapper>
      <TextField
        size="small"
        placeholder="Search"
        aria-label="Search"
        hiddenLabel
        onChange={handleBusinessCallback}
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: '10px' }}>
              <img
                src={SearchIcon}
                alt="Search maginifier"
                width={18}
                height={18}
              />
            </InputAdornment>
          ),
        }}
      />
    </TextFieldWrapper>
  );
};

const TextFieldWrapper = styled.div`
  padding: 11px 11px 0 11px;
  margin-bottom: 12px;

  .MuiTextField-root {
    width: 100%;

    input {
      padding-right: 35px;
    }
  }
`;
