import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';

const useStyles = makeStyles(() => ({
  table: {
    background: '#FFFFFF',
    border: '2px solid #E4E7EB',
    boxSizing: 'border-box',
    borderRadius: '12px',
  },
  tableRow: {
    height: '80px',
    borderBottom: '2px solid #E4E7EB',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  tableHeaderCell: {
    padding: '27px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '150%',
    color: 'rgba(0, 10, 20, 0.85)',
  },
  tableCell: {
    padding: '27px',
  },
  tableCellBtn: {
    padding: '16px 26px',
    width: '120px',
  },
}));

export const Invoices = () => {
  const classes = useStyles();

  const invoices = [
    {
      isActive: true,
      name: 'John Doe',
      date: '03/02/2021',
      ref: '#1234567890',
      type: 'visa',
    },
    {
      isActive: false,
      name: 'John Doe',
      date: '03/02/2021',
      ref: '#1234567890',
      type: 'master',
    },
    {
      isActive: true,
      name: 'John Doe',
      date: '03/02/2021',
      ref: '#1234567890',
      type: 'paypal',
    },
  ];

  const FormHeader = () => (
    <>
      <Grid item xs={1}>
        <div className={classes.tableHeaderCell}>Status</div>
      </Grid>
      <Grid item xs={3}>
        <div className={classes.tableHeaderCell}>Name</div>
      </Grid>
      <Grid item xs={2}>
        <div className={classes.tableHeaderCell}>Date Added</div>
      </Grid>
      <Grid item xs={2}>
        <div className={classes.tableHeaderCell}>Reference Number</div>
      </Grid>
      <Grid item xs={3}>
        <div className={classes.tableHeaderCell}>Payment Method</div>
      </Grid>
      <Grid item xs={1}>
        <div className={classes.tableHeaderCell} />
      </Grid>
    </>
  );

  const Visa = () => (
    <div className="invoices-payment-method-container">
      <img className="visa-logo" alt="visa" src="visa.png" />
      <div>Visa</div>
    </div>
  );

  const MasterCard = () => (
    <div className="invoices-payment-method-container">
      <img className="master-card-logo" alt="visa" src="mc.png" />
      <div>Mastercard</div>
    </div>
  );

  const PayPal = () => (
    <div className="invoices-payment-method-container">
      <img className="pay-pal-logo" alt="visa" src="pp.png" />
      <div>PayPal</div>
    </div>
  );

  const FormRow = ({ invoice }) => (
    <>
      <Grid item xs={1}>
        <div className={classes.tableCell}>
          <div
            className={`website-card-tag ${
              invoice.isActive
                ? 'website-card-tag-active'
                : 'website-card-tag-inactive'
            }`}
          >
            {invoice.isActive ? 'Active' : 'Inactive'}
          </div>
        </div>
      </Grid>
      <Grid item xs={3}>
        <div className={classes.tableCell}>
          <div className="invoices-name-container">{invoice.name}</div>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className={classes.tableCell}>
          <div className="invoices-date-container">{invoice.date}</div>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className={classes.tableCell}>
          {' '}
          <div className="invoices-ref-container">{invoice.ref}</div>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className={classes.tableCell}>
          {invoice.type === 'visa' && <Visa />}
          {invoice.type === 'master' && <MasterCard />}
          {invoice.type === 'paypal' && <PayPal />}
        </div>
      </Grid>
      <Grid container xs={2} direction="row" justify="flex-end">
        <div className={classes.tableCellBtn}>
          <div className="invoices-download-btn">Download</div>
        </div>
      </Grid>
    </>
  );

  FormRow.propTypes = {
    invoice: PropTypes.object.isRequired,
  };

  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');

  return (
    <>
      <div className="header-manage-websites">
        <div className="header-manage-websites-text">Invoices</div>
      </div>
      <div className="invoices-container">
        {!isMobile && (
          <>
            <Grid container>
              <Grid container item xs={12}>
                <FormHeader />
              </Grid>
            </Grid>
            <Grid container className={classes.table}>
              {invoices.map((invoice) => (
                <Grid container item xs={12} className={classes.tableRow}>
                  <FormRow invoice={invoice} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {isMobile &&
          invoices.map((invoice) => (
            <div className="invoices-item">
              <div
                className={`website-card-tag invoices-item-field ${
                  invoice.isActive
                    ? 'website-card-tag-active'
                    : 'website-card-tag-inactive'
                }`}
              >
                {invoice.isActive ? 'Active' : 'Inactive'}
              </div>
              <div
                className="invoices-item-field"
                style={{ fontWeight: '500' }}
              >
                {invoice.name}
              </div>
              <div className="invoices-item-field">{invoice.date}</div>
              <div className="invoices-item-field">{invoice.ref}</div>
              <div
                className={`${classes.tableCell} invoices-item-field`}
                style={{ padding: '0px' }}
              >
                {invoice.type === 'visa' && <Visa />}
                {invoice.type === 'master' && <MasterCard />}
                {invoice.type === 'paypal' && <PayPal />}
              </div>
              <div
                className={`${classes.tableCellBtn} invoices-item-field`}
                style={{ padding: '0px' }}
              >
                <div className="invoices-download-btn">Download</div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
