import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react';
import PlansService from '@equally-ai-front/common/src/api/plans-service';

interface PlanContextType {
  allPlans: any[];
  activePlans: any[];
  getActivePlans: (type: string) => Promise<any[]>;
}

const PlanContext = createContext<PlanContextType>({
  allPlans: [],
  activePlans: [],
  getActivePlans: async () => [],
});

interface PlanProviderProps {
  children: React.ReactNode;
  businessId?: number;
}

const PlansProvider = ({ children, businessId }: PlanProviderProps) => {
  const [allPlans, setPlans] = useState<any[]>([]);
  const [activePlans, setActivePlans] = useState<any[]>([]);

  const getActivePlans = useCallback(
    async (type: string): Promise<any[]> => {
      if (!businessId) {
        return [];
      }
      const { data, error, isSuccess } = await PlansService.getPlans(
        businessId,
        type,
      );

      if (!isSuccess) {
        console.error('Failed to fetch plans', error);
      }
      setActivePlans(data);
      return data;
    },
    [businessId],
  );

  useEffect(() => {
    if (!businessId) {
      return;
    }
    void getActivePlans('');
  }, [getActivePlans, businessId]);

  return (
    <PlanContext.Provider value={{ allPlans, activePlans, getActivePlans }}>
      {children}
    </PlanContext.Provider>
  );
};

const usePlans = () => {
  const context = useContext(PlanContext);
  if (context === undefined) {
    throw new Error('usePlans must be used within a PlanProvider');
  }
  return context;
};

export { usePlans, PlansProvider };
