import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import React from 'react';
import { useAppSelector } from '../../../../hooks/use-app-dispach-selector';
import useWindowSize from '../../hooks/useWindowSize';
import { MOBILE_PX } from '../../utils/constants';
import IssuesPageMobile from './issues-page-mobile';
import IssuesTable from './issues-table';

export const IssuesPage = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < MOBILE_PX;
  const { loading } = useAppSelector((state: any) => state.issues);
  const { loading: loadingScans } = useAppSelector(
    (state: any) => state.overview,
  );

  return (
    <>
      {isMobile ? <IssuesPageMobile /> : <IssuesTable />}
      <LoadingView
        open={loading || loadingScans}
        loadingText="Fetching all issues..."
        zIndex="9000"
      />
    </>
  );
};

export default IssuesPage;
