import React, { useState } from 'react';

import styled from '@emotion/styled';
import { isNullOrUndefined } from '@equally-ai-front/common/src';
import { Box, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import {
  TypographyH1,
  TypographyH2,
  TypographyH3,
} from '../../../components/common/styled';
import { PrimaryButton } from '../../../components/redesign/Controls';

interface SelectProductProps {
  onSubmitType: (type: string) => void;
}
const COLUMNS = [
  {
    name: 'flowy',
    label: 'Flowy',
    description:
      'Connect everyone in your team to test, monitor and ship better accessibility from start to finish. Perfect for diverse teams and users that would like to own their end-to-end accessibility flow, without the need for extensive coding knowledge.',
  },
  {
    name: 'widget',
    label: 'Effortless Accessibility Widget',
    description: `Our approach goes beyond other accessibility widgets. All plans include our litigation support
    suite 🛡️`,
  },
];

export const SelectProductType = ({ onSubmitType }: SelectProductProps) => {
  const [selectedProductOption, setSelectedProductOption] = useState('');

  const handleSelectProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedProductOption(value);
  };
  const columns = COLUMNS.map((column, index) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <BoxContainer key={`box-container-${column.name}-${index}`}>
        <StyledControlLabel
          key={column.name}
          value={column.name}
          control={<StyledRadio inputProps={{ tabIndex: -1 }} />}
          tabIndex={0}
          label={
            <Box
              display="flex"
              flexWrap="wrap"
              flexDirection={'column'}
              gridGap="3px"
            >
              <BoxHeader>{column.label}</BoxHeader>
              <BoxSubHeader>{column.description}</BoxSubHeader>
            </Box>
          }
        />
      </BoxContainer>
    );
  });

  return (
    <StyledContainer>
      <StyledHeader>
        <MainHeader>Choose your accessibility solution</MainHeader>
      </StyledHeader>
      <ContentContainer>
        <StyledRadioGroup
          aria-label="select a product"
          name="product type"
          value={selectedProductOption}
          onChange={handleSelectProduct}
        >
          {columns}
        </StyledRadioGroup>
      </ContentContainer>

      <StyledButton
        onClick={() => onSubmitType(selectedProductOption)}
        disabled={isNullOrUndefined(selectedProductOption)}
      >
        Next
      </StyledButton>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 10px 15px;
    max-height: 55%;
    justify-content: center;
  }
`;

const StyledHeader = styled.div`
  text-align: center;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  max-width: 550px;
  align-items: center;

  @media screen and (max-width: 600px) {
    margin: 0 auto 20px;
    flex-wrap: nowrap;
    min-height: auto;
  }
`;
const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
`;
const StyledControlLabel = styled(StyledFormControlLabel)`
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid #eee;
  padding: 15px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const StyledRadio = styled(Radio)`
  padding: 3px 0 0;
  margin-right: 9px;
  & input:checked + div .MuiSvgIcon-root {
    fill: #454284;
  }
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: auto;
  width: 90%;
  text-align: center;
  font-size: 17px;

  &:not(:disabled) {
    background: #454284;
    color: #fff;
  }

  &:disabled {
    color: #000a14;
  }

  @media screen and (max-width: 600px) {
    margin-top: 10px;
  }
`;

const MainHeader = styled(TypographyH1)`
  font-size: 24px;
`;
const BoxHeader = styled(TypographyH2)`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.15px;
`;
const BoxSubHeader = styled(TypographyH3)`
  font-weight: 300;
  font-size: 16px;
  color: #282828;
`;

const ContentContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const BoxContainer = styled(Box)`
  margin: 5px 0;
  width: 100%;
  max-width: 90%;
  display: flex;
  justify-content: center;
`;
