export const AUDIT_STATUS_SUCCESS = 'success';
export const AUDIT_STATUS_WARNING = 'warning';
export const AUDIT_STATUS_ERROR = 'error';

export const AUDIT_STATUS_ALL = [
  AUDIT_STATUS_SUCCESS,
  AUDIT_STATUS_WARNING,
  AUDIT_STATUS_ERROR,
];

export const TYPE_CODE_ERROR = 1;
export const TYPE_CODE_NOTICE = 3;
export const TYPE_CODE_WARNING = 2;

export const QUERY_PARAM_AUDIT = 'audit';
export const FIELD_AUDIT_URL = 'audit';

export const ACCESSIBILITY_MAX_SCORE = 90;
