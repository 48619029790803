import axios from 'axios';
import Cookies from 'js-cookie';

export function getAccessToken() {
  return Cookies.get('accessToken');
}

export function isAuthenticated() {
  const token = getAccessToken();

  if (!token) {
    return false;
  }

  if (token.split('.').length !== 3) {
    return false;
  }
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const { exp } = JSON.parse(window.atob(base64));

    if (exp) {
      const isExpired = Math.round(new Date().getTime() / 1000) >= exp;
      return !isExpired;
    }
  } catch (e) {
    return false;
  }

  return true;
}

export const interceptors = {
  requestInterceptor: null,
  responseInterceptor: null,
  isIntercepting: false,
};

export function interceptRequestsAndResponses(redirectToLogin) {
  if (!interceptors.isIntercepting) {
    interceptors.requestInterceptor = axios.interceptors.request.use(
      (config) => {
        // Re-checking the authentication on every ajax request in case the token expired
        if (!isAuthenticated()) {
          redirectToLogin && redirectToLogin();
          throw new axios.Cancel('User is not permitted for this action');
        }

        // s3 requires only one way of authentication and its not using Authorization header,
        // so we must remove it when we upload thumbnails.
        if (
          config.method !== 'OPTIONS' &&
          !config.url.includes('s3.amazonaws.com') &&
          !config.url.includes('segment.io') &&
          !config.url.includes('graph.facebook.com') &&
          !config.url.includes('connect.facebook.net') &&
          !config.url.includes('/graphql')
        ) {
          // eslint-disable-next-line no-param-reassign
          config.headers = {
            Authorization: `Bearer ${getAccessToken()}`,
          };
          // eslint-disable-next-line no-param-reassign
          config.requestStartTime = Date.now();
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    interceptors.responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (axios.isCancel(error)) {
          return Promise.reject();
        }

        // if (error.response) {
        //   if (error.response.status === 403) {
        //     store.dispatch(handleUnAuthorized());
        //   }
        //   if (error.response.status === 401) {
        //     handleUnAuthenticated && store.dispatch(handleUnAuthenticated());
        //   }
        //   if (error.response.status === 500) {
        //     store.dispatch(updateSessionDirty());
        //   }
        // }

        return Promise.reject(error);
      },
    );

    interceptors.isIntercepting = true;
  }
}
