import styled from '@emotion/styled';
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';

import { Issue } from '@equally-ai-front/common/src/types/issues';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import CloseIcon from '@material-ui/icons/Close';
import { CenterBlock, IconButton } from '../../../common/styled';
import {
  AUDIT_STATUS_ERROR,
  AUDIT_STATUS_SUCCESS,
  AUDIT_STATUS_WARNING,
  TYPE_CODE_ERROR,
  TYPE_CODE_WARNING,
} from '../../contants';
import { buildAuditHistoryList } from '../../helpers';
import AuditHistoryStatusView from './AuditHistoryStatusView';
import AuditInputForm from './AuditInputForm';
import AuditListPanel from './AuditListPanel';
import AuditMobilePanel from './AuditMobilePanel';
import ReportView from './ReportView';

const LIST_SHADOW_HEIGHT = '24px';
const LIST_SECTION_HEIGHT = '456px';

interface AuditViewProps {
  handleAudit: (
    url: string,
    cb?: (url: string, issues: Issue[]) => void,
  ) => void;
  loading: boolean;
  report: ReportData;
}

const AuditView = (props: AuditViewProps) => {
  const { handleAudit, loading, report } = props;
  const isMD = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const [showMobilePages, setShowMobulePages] = useState(false);
  const openDialog = useCallback(() => setShowMobulePages(true), []);
  const closeDialog = useCallback(() => setShowMobulePages(false), []);
  const [auditURLStatus, setAuditURLStatus] = useState({});
  const addVisitedAuditStatus = useCallback((url: string, issues: Issue[]) => {
    const checkStatus = (issueList: Issue[]) => {
      let reportStatus = AUDIT_STATUS_SUCCESS;

      for (let i = 0; i < issueList.length; i += 1) {
        const issue = issueList[i];

        if (issue.type_code === TYPE_CODE_ERROR) {
          reportStatus = AUDIT_STATUS_ERROR;
          break;
        }

        if (issue.type_code === TYPE_CODE_WARNING) {
          reportStatus = AUDIT_STATUS_WARNING;
        }
      }

      return reportStatus;
    };
    const status = checkStatus(issues);
    setAuditURLStatus((data) => ({
      ...data,
      [url]: status,
    }));
  }, []);

  const onAudit = async (url: string) => {
    await handleAudit(url, addVisitedAuditStatus);
    closeDialog();
  };

  const URLlistObject = useMemo(() => {
    if (!auditURLStatus) {
      return [];
    }

    const list = buildAuditHistoryList(auditURLStatus);
    return list;
  }, [auditURLStatus]);

  return (
    <>
      <Grid
        container
        spacing={3}
        wrap="nowrap"
        style={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {/* @ts-ignore */}
        <Grid item sm={12} md style={{ width: '100%', overflow: 'hidden' }}>
          <LayoutMain>
            <AuditInputForm onAudit={onAudit} label={'Url'} />
            <Box mt={isMD ? '32px' : '24px'} />
            {loading && (
              <CenterBlock>
                <CircularProgress />
              </CenterBlock>
            )}
            {!loading && report ? (
              <ReportView report={report} url={report.websiteUrl || ''} />
            ) : (
              <CenterBlock>
                {loading ? 'Loading' : `No report data`}
              </CenterBlock>
            )}
          </LayoutMain>
        </Grid>
      </Grid>
    </>
  );
};

export default AuditView;

/** Styled Components */

const LayoutAside = styled.div`
  width: 288px;
`;

const LayoutMain = styled.div``;

const ListWrapper = styled.div`
  position: relative;
  margin: 12px;
  margin-bottom: 0;
  overflow: hidden;
  display: flex;
`;

const ListWrapperPanel = styled(ListWrapper)`
  height: ${LIST_SECTION_HEIGHT};
`;

const InputWrapper = styled.div`
  background: #f5f7fa;
  padding: 24px;
`;

const Shadow = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
`;

const ShadowBox = styled(Shadow)`
  position: absolute;

  bottom: 0;
  left: 0;
  right: 0;

  height: ${LIST_SHADOW_HEIGHT};
`;

const CloseButtonBox = styled.div`
  position: absolute;
  z-index: 10;
  top: 24px;
  right: 30px;
`;

const DialogContent = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CloseButton = styled(IconButton)`
  font-size: 20px;

  border: 2px solid #c2f4d7;
  box-sizing: border-box;
  border-radius: 8px;

  width: 40px;
  height: 40px;
`;
