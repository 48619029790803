import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import styled from 'styled-components';

type DropdownOptions = {
  label: string;
  handleClickAction: () => void;
  icon?: React.ReactNode;
};

interface JiraTaskSelectDropdownProps {
  isDisabled: boolean;
  handleSelectOption: (selectedOption: string) => void;
  options: DropdownOptions[];
  selectedOption: string;
  bgColor?: string;
  iconColor?: string;
}

export const JiraSelectDropdown = (props: JiraTaskSelectDropdownProps) => {
  const {
    isDisabled,
    handleSelectOption,
    options,
    selectedOption,
    bgColor,
    iconColor,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (opt: string) => {
    handleSelectOption(opt);
    setAnchorEl(null);
  };

  const menuItems = options.map((option) => {
    const { label, icon } = option;
    return (
      <MenuItem
        key={label}
        onClick={() => handleClose(label)}
        disableRipple
        disabled={selectedOption === label}
        sx={{ borderRadius: '4px' }}
      >
        {icon && icon}
        <span>{label}</span>
      </MenuItem>
    );
  });

  const getSelectedOptionIcon = () =>
    options.find((opt) => opt.label === selectedOption)?.icon || null;

  const getHandleClickAction = () =>
    options.find((opt) => opt.label === selectedOption)?.handleClickAction ||
    undefined;

  return (
    <DropdownContainer>
      <StyledSelectButton
        disabled={isDisabled}
        onClick={getHandleClickAction()}
      >
        {getSelectedOptionIcon()} {selectedOption}
      </StyledSelectButton>
      <Divider orientation="vertical" flexItem />
      <div>
        <StyledButton
          id="create-jira-tasks-menu-options"
          aria-controls={open ? 'jira-tasks-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          disabled={isDisabled}
          bgColor={bgColor}
        >
          <KeyboardArrowDownIcon
            sx={{ fill: `${iconColor || '#000a14'} !important` }}
          />
        </StyledButton>
        <StyledMenu
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id="jira-tasks-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'create-jira-tasks-menu-options',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          {menuItems}
        </StyledMenu>
      </div>
    </DropdownContainer>
  );
};

interface ButtonProps {
  bgColor?: string;
}

const StyledButton = styled(Button)<ButtonProps>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f7fa')};
  border-radius: 0;
  min-width: 36px;
  padding: 6px;
  font-size: 14px;

  &:disabled {
    opacity: 0.4;
  }
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    padding: 4px;
    box-shadow: 1px 2px 2px #aaa;
  }
`;

const StyledSelectButton = styled(Button)<ButtonProps>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f7fa')};
  border-radius: 0;
  padding: 4px 6px;
  text-transform: capitalize;
  color: #000a14;
  font-size: 14px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  &:hover {
    background-color: #3b3871;
    color: #fff;
  }

  &:disabled {
    background-color: #f5f7fa;
    opacity: 0.4;
    color: #000a14;

    svg {
      fill: #000 !important;
    }
  }
`;

const DropdownContainer = styled(Box)`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  justify-content: flex-end;
  margin-right: 6px;
  margin-bottom: 10px;
  border: 1.5px solid #cbd2d9;
  width: fit-content;
  margin-left: auto;
`;
