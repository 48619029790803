import React from 'react';

import styled from '@emotion/styled';

import { Paper, Grid, useMediaQuery } from '@material-ui/core';

interface CheckmarkListGridProps {
  children: React.ReactNode;
}

const CheckmarkListGrid = ({ children }: CheckmarkListGridProps) => {
  const isWide = useMediaQuery('(min-width: 600px)');

  return (
    <Card>
      <CardContent>
        <Grid container spacing={isWide ? 3 : 2}>
          {React.Children.map(children, (child) => (
            <Grid item xs={12} md={12}>
              {child}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CheckmarkListGrid;

/** Styled Components */

const Card = styled(Paper)`
  background: #f5f7fa;
  color: rgba(0, 10, 20, 0.85);
  border-radius: 16px;
  box-shadow: none;

  @media screen and (max-width: 600px) {
    margin-bottom: 50px;
  }
`;

const CardContent = styled.div`
  padding: 24px;

  @media screen and (max-width: 600px) {
    padding: 16px;
  }
`;
