import React from 'react';
import styled from '@emotion/styled';
import {
  AccessibilityAccordion,
  AccordionState,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { DevtoolsSolution } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { Divider as MuiDivider } from '@material-ui/core';
import { Box } from '@mui/material';
import { ColumnOption, IssueCreationType } from './popup';
import { JiraTaskIssues } from './jira-task-issues';
import { generateUniqueId } from '@equally-ai-front/common/src/lib';
import { AdditionalInfoFields } from './additional-info-fields';
import {
  TaskIssue,
  TaskAdditionalInfo,
} from '@equally-ai-front/common/src/types/business';

interface JiraTasksProps {
  columns: ColumnOption[];
  issueCreationType: IssueCreationType;
  selectedIssues: TaskIssue[];
  selectedDevToolsIssues: DevtoolsSolution[];
  accordionState: AccordionState;
  handleToggleAccordion: (id: string) => void;
  tasksAdditionalInfo: TaskAdditionalInfo[];
  setTasksAdditionalInfo: React.Dispatch<
    React.SetStateAction<TaskAdditionalInfo[]>
  >;
}

const TASK_ID = generateUniqueId();
export const JiraTasks = (props: JiraTasksProps) => {
  const {
    columns,
    issueCreationType,
    selectedIssues,
    selectedDevToolsIssues,
    accordionState,
    handleToggleAccordion,
    tasksAdditionalInfo,
    setTasksAdditionalInfo,
  } = props;

  return (
    <Wrapper>
      <Container>
        <AccessibilityAccordion
          title="Task 1"
          accordionId={TASK_ID}
          accordionState={accordionState}
          handleToggleAccordion={() => handleToggleAccordion(TASK_ID)}
          accordionHeader={
            <HeaderWrapper>
              <TitleAndIcon>
                <ArrowIconWrapper>
                  <ArrowIcon
                    src="/chevron-down-black.svg"
                    alt="arrow down icon"
                    shouldrotate={accordionState[TASK_ID]}
                  />
                </ArrowIconWrapper>
                <Box ml={'12px'} />
                <HeaderText>Task 1</HeaderText>
              </TitleAndIcon>
            </HeaderWrapper>
          }
        >
          <Divider />
          <JiraTaskIssues
            columns={columns}
            issueCreationType={issueCreationType}
            selectedIssues={selectedIssues}
            selectedDevToolsIssues={selectedDevToolsIssues}
            accordionState={accordionState}
            handleToggleAccordion={handleToggleAccordion}
            isGrouped
            tasksAdditionalInfo={tasksAdditionalInfo}
            setTasksAdditionalInfo={setTasksAdditionalInfo}
          />
          <Divider />
          <AdditionalInfoFieldsWrapper>
            <AdditionalInfoFields
              index={0}
              issueCreationType={issueCreationType}
              tasksAdditionalInfo={tasksAdditionalInfo}
              setTasksAdditionalInfo={setTasksAdditionalInfo}
            />
          </AdditionalInfoFieldsWrapper>
        </AccessibilityAccordion>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 20px;
`;

const Container = styled.div`
  overflow: hidden;
  border: 2px solid #d2d7df;
  border-radius: 8px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  > div > h3 {
    padding: 15px;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
    align-items: initial;
  }
`;

export const HeaderText = styled.p`
  font-weight: 600;
  font-size: 15px;
  margin-left: 12px;

  @media (pointer: none), (pointer: coarse) {
    font-size: 12px;
    margin-left: 16px;
  }
`;

export const ArrowIconWrapper = styled.div`
  border: 2px solid #e4e7eb;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 410px) {
    width: 37px;
  }
`;

interface ArrowIconProps {
  shouldrotate: boolean;
}

const ArrowIcon = styled.img<ArrowIconProps>`
  transform: ${(props) => (props.shouldrotate ? 'rotate(180deg)' : 'none')};
  transition: transform 100ms;
`;

export const Divider = styled(MuiDivider)`
  height: 1px;
`;

const TitleAndIcon = styled.div`
  display: flex;
  align-items: center;
`;

const AdditionalInfoFieldsWrapper = styled.div``;
