type PermissionNames =
  | 'feature.view_business'
  | 'view.business_settings'
  | 'view.compliance_page'
  | 'view.compliance_settings'
  | 'view.flowy_page'
  | 'write.business_settings'
  | 'write.compliance_page'
  | 'write.flowy_page'
  | 'view.accessibility_score'
  | 'write.developer_domain'
  | 'view.jira_settings'
  | 'write.jira_settings';

export const isUserPermitted = (
  businessId: number,
  permissionNames: PermissionNames[],
  personalDetails: any,
): boolean => {
  const usersBusinessPermissions =
    personalDetails?.permissions_map?.[businessId];

  if (!usersBusinessPermissions) {
    return false;
  }

  return permissionNames.every(
    (permission) => usersBusinessPermissions[permission],
  );
};
