import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IssuesPage from '../components/compliance-manager/containers/issues-page/issues-page';
import { OverViewPage } from '../components/compliance-manager/containers/overview-page/overview-page';
import { fetchTotalScans } from '../components/compliance-manager/store/slices/overview-slice';
// import ProgressPage from '../components/compliance-manager/containers/progress-page/progress-page';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { ScanData } from '@equally-ai-front/common/src/types/scans';
import {
  fetchAllIssues,
  resetIssues,
} from '../components/compliance-manager/store/slices/issues-slice';
import {
  useAppDispatch,
  useAppSelector,
} from '../hooks/use-app-dispach-selector';
import { getDevDomains, setApiError } from '../store/actions';
import { BodyContainer } from '../components/flowy-components/body-container';
import styled from 'styled-components';
import { ComplianceManagerNav } from '../components/compliance-manager/components/compliance-manager-nav';

export const ComplianceManagerRedirect = () => {
  const dispatch = useAppDispatch();
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const { devDomains } = useAppSelector((state) => state.domains);
  const { totalScansData } = useAppSelector((state) => state.overview);
  const [loading, setLoading] = useState<boolean>(true);

  const getTotalScans = useCallback(async () => {
    setLoading(true);
    const websiteIds: number[] = [devDomains[0].id];
    const response: any = (
      await dispatch(
        fetchTotalScans({ businessId: currentBusiness?.id, websiteIds }),
      )
    ).payload;
    if (!response.isSuccess) {
      dispatch(setApiError(response.error));
    }
    setLoading(false);
  }, [currentBusiness?.id, devDomains, dispatch]);

  const fetchIssues = useCallback(async () => {
    if (!currentBusiness) return;
    if (!totalScansData) return;
    setLoading(true);

    const scanIds = totalScansData.scans.map((scan: ScanData) => scan.id);
    const response: any = (
      await dispatch(
        fetchAllIssues({
          businessId: currentBusiness.id,
          scanIds,
        }),
      )
    ).payload;

    if (!response.isSuccess) {
      dispatch(setApiError(response.error));
    }
    setLoading(false);
  }, [currentBusiness, dispatch, totalScansData]);

  useEffect(() => {
    if (devDomains.length > 0) {
      getTotalScans();
    } else {
      setLoading(false);
    }
  }, [devDomains, getTotalScans]);

  useEffect(() => {
    if (!currentBusiness) {
      return;
    }

    dispatch(getDevDomains(currentBusiness.id));
    setLoading(true);
  }, [currentBusiness, dispatch]);

  useEffect(() => {
    if (!totalScansData) {
      dispatch(resetIssues());
      return;
    }

    if (totalScansData.scans.length > 0) {
      fetchIssues();
    }
  }, [totalScansData, fetchIssues, dispatch]);

  if (loading) {
    return <LoadingView open={loading} zIndex="9000" />;
  }

  return (
    <>
      <BodyContainer>
        <Content>
          <ComplianceManagerNav />
          <LoadingViewWrapper isLoading={false}>
            <LoadingView open={false} />
          </LoadingViewWrapper>
        </Content>
      </BodyContainer>
    </>
  );
};

const Content = styled.div`
  position: relative;
  display: flex;
`;

type LoadingViewWrapperProps = { isLoading: boolean };
const LoadingViewWrapper = styled.div<LoadingViewWrapperProps>`
  height: 100vh;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 90;
  display: ${(p) => (!p.isLoading ? 'none' : '')};
`;
