import firebase from 'firebase/app';
import { firebaseAuth } from '../common/firebase';

const codeToMessageMap: { [key: string]: string } = {
  'auth/too-many-requests':
    'Too many failed login attempts. Please try again later.',
  'auth/user-disabled': 'Account is disabled. Please contact support.',
  'custom-auth/email-not-verified': 'Your email is not verified!',
  'auth/user-not-found':
    "Couldn't find your Equally AI account. If you signed up with a Google account, click Login with Google.",
  'auth/email-already-in-use': 'Email address already in use.',
};

export class AuthUtils {
  static async register(email: string, password: string) {
    return firebaseAuth.createUserWithEmailAndPassword(email, password);
  }

  static async onLoginWithEmail(email: string, password: string) {
    return firebaseAuth.signInWithEmailAndPassword(email, password);
  }

  static async loginWithGoogle() {
    return firebaseAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  static async resendVerificationEmail() {
    if (!firebaseAuth.currentUser) {
      return false;
    }
    let isSuccess = false;
    try {
      await firebaseAuth.currentUser.sendEmailVerification();
      isSuccess = true;
    } catch (error) {
      await firebaseAuth.currentUser.sendEmailVerification();
      isSuccess = false;
    }
    return isSuccess;
  }

  static async resetPassword(email: string) {
    return firebaseAuth.sendPasswordResetEmail(email);
  }

  static getErrorMessage(code: string) {
    return codeToMessageMap[code] || 'Oops, something went wrong';
  }
}
