import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import React from 'react';

import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';

import { HMAC } from '../utils/helpers';
import {
  AliasParams,
  EventParams,
  GroupParams,
  IdentifyParams,
  PageParams,
} from '@segment/analytics-next/dist/types/core/arguments-resolver';

const initialAnalytics = {
  identify: () => {},
  track: () => {},
  page: () => {},
  group: () => {},
  alias: () => {},
};

interface AnalyticsProviderProps {
  children: any;
  intercomWebIdentificationKey: string | undefined;
  segmentWriteKey: string;
}

export type CustomAnalytics = {
  identify: (...args: IdentifyParams) => void;
  page: (...args: PageParams) => void;
  track: (...args: EventParams) => void;
  group: (...args: GroupParams) => void;
  alias: (...args: AliasParams) => void;
};
export type AnalyticsContextValue = CustomAnalytics;
export const AnalyticsContext =
  createContext<AnalyticsContextValue>(initialAnalytics);

export const AnalyticsProvider = (props: AnalyticsProviderProps) => {
  const { children, intercomWebIdentificationKey, segmentWriteKey } = props;
  const analyticsRef = useRef<Analytics | null>();
  const bufferCallsRef = useRef<Array<any>>([]);
  const hmacRef = useRef<string | null>(null);

  const getIntercomHash = useCallback(async (userId: string) => {
    if (userId && !hmacRef.current) {
      hmacRef.current = await HMAC(intercomWebIdentificationKey || '', userId);
    }

    return hmacRef.current;
  }, []);

  const identify = useCallback(
    async (...args: IdentifyParams) => {
      const analytics = analyticsRef.current;
      const bufferCalls = bufferCallsRef.current;
      const [id] = args;
      let hmac;

      if (typeof id === 'string') {
        hmac = await getIntercomHash(id);
      }
      const intercomSettings = { user_hash: hmac };

      args.push({
        context: { Intercom: intercomSettings },
      });

      if (analytics) {
        await analytics.identify(...args);
      } else {
        bufferCalls.push({ type: 'identify', args });
      }
    },
    [getIntercomHash],
  );

  const track = useCallback(async (...args: EventParams) => {
    const analytics = analyticsRef.current;
    const bufferCalls = bufferCallsRef.current;

    if (analytics) {
      await analytics.track(...args);
    } else {
      bufferCalls.push({ type: 'track', args });
    }
  }, []);

  const page = useCallback(async (...args: PageParams) => {
    const analytics = analyticsRef.current;
    const bufferCalls = bufferCallsRef.current;

    if (analytics) {
      await analytics.page(...args);
    } else {
      bufferCalls.push({ type: 'page', args });
    }
  }, []);

  const group = useCallback(async (...args: GroupParams) => {
    const analytics = analyticsRef.current;
    const bufferCalls = bufferCallsRef.current;

    if (analytics) {
      await analytics.group(...args);
    } else {
      bufferCalls.push({ type: 'group', args });
    }
  }, []);
  const alias = useCallback(async (...args: AliasParams) => {
    const analytics = analyticsRef.current;
    const bufferCalls = bufferCallsRef.current;

    if (analytics) {
      await analytics.alias(...args);
    } else {
      bufferCalls.push({ type: 'alias', args });
    }
  }, []);
  useEffect(() => {
    const loadAnalytics = async () => {
      const [analytics] = await AnalyticsBrowser.load({
        writeKey: segmentWriteKey,
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const call of bufferCallsRef.current) {
        // @ts-ignore
        await analytics[call.type](...call.args);
      }

      analyticsRef.current = analytics;
      bufferCallsRef.current = [];
    };
    void loadAnalytics();
  }, []);

  const customAnalytics = useMemo(
    (): CustomAnalytics => ({ identify, page, track, group, alias }),
    [identify, page, track, group, alias],
  );
  return (
    <AnalyticsContext.Provider value={customAnalytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => useContext(AnalyticsContext);
