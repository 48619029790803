import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import { DomainSummary } from '@equally-ai-front/common/src/types/domains';
import { Box, Divider } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { ActionButtons } from '../../../components/common/Tables/action-buttons';
import { GridAccordionRow } from '../../../components/generic-grid/grid-accordion-row';
import {
  FieldMap,
  GridRow,
  Size,
} from '../../../components/generic-grid/grid-row';
import { formatExpiration } from '../../../lib/date';
import { isAttachPlanEligible, isDetachPlanEligible } from '../../../lib/plans';
import { usePlans } from '../../../providers/plans-context';

interface WidgetTableBodyProps {
  data: any[];
  handleManageModal: (
    event: React.MouseEvent<HTMLElement>,
    domain: any,
  ) => void;
  launchFlowy: (id: number, name: string) => void;
  currentPage: number;
}

const eclipseWord = (word: string, maxLength: number) => {
  if (word.length > maxLength) {
    return `${word.substring(0, maxLength)}...`;
  }
  return word;
};

const accordionMap: FieldMap[] = [
  {
    key: 'index',
    size: {
      sm: 1,
      xs: 1,
      md: 1,
    },
    hideOnMobile: true,
  },
  {
    key: 'name',
    size: {
      sm: 2,
      xs: 6,
      md: 2,
    },
    format: (row) => eclipseWord(String(row.name), 20),
  },
];

const detailsMap: FieldMap[] = [
  {
    key: 'expiration',
    size: {
      sm: 2,
      xs: 6,
      md: 2,
    },
    format: (row) => formatExpiration(row.expiration),
  },
  {
    key: 'active',
    size: {
      sm: 1,
      xs: 2,
      md: 1,
    },
    hideOnMobile: true,
    format: (row) => <strong>{row.active ? 'Active' : 'Inactive'}</strong>,
  },
  {
    key: 'plan_id',
    size: {
      sm: 2,
      xs: 3,
      md: 1,
    },
    hideOnMobile: true,
    format: (row) => (
      <strong style={{ fontWeight: 500 }}>
        {row.plan_id ? `Plan` : 'Free Trial'}
      </strong>
    ),
  },
  {
    key: 'type',
    size: {
      sm: 2,
      xs: 6,
      md: 2,
    },
    format: (row: any) => (
      <strong style={{ fontWeight: 500, textTransform: 'capitalize' }}>
        {row.type === 'developer' ? 'dashboard' : row.type}
      </strong>
    ),
  },
];

const emptyRow: Size = {
  sm: 3,
  xs: 6,
  md: 3,
};

export const WidgetTableBody = ({
  data,
  handleManageModal,
  launchFlowy,
  currentPage,
}: WidgetTableBodyProps) => {
  const isMobile = useMediaQuery(`(max-width: 600px)`);

  const getDetailsData = (domainSummary: DomainSummary) => {
    const detailsData = [];

    const {
      domain,
      dev_domain: devDomain,
      dev_domain_id: devDomainId,
    } = domainSummary;
    if (domain) {
      detailsData.push({
        ...domain,
        type: 'widget',
        dev_domain_id: devDomainId,
      });
    }

    if (domain?.allow_user_integration) {
      return [{ ...detailsData[0], type: 'flowy', dev_domain_id: devDomainId }];
    }

    if (devDomain) {
      detailsData.push({
        ...devDomain,
        type: 'developer',
        dev_domain_id: devDomainId,
      });
    }

    return detailsData;
  };

  const { activePlans: plansData } = usePlans();

  const shouldHideActionButton = (tableRowData: any) => {
    const domainData = data.find(
      (domain: any) => domain.id === tableRowData.id,
    );

    if (tableRowData.id !== 0 && domainData && domainData.domain) {
      return false;
    }

    return true;
  };

  const formatActionButton = (row: any) => {
    const buttonProps = {
      showQuickActions: true,
      hideActionButton: shouldHideActionButton,
      handleSelectPlan: handleManageModal,
      actionLabel: 'Manage',
      allowAttach: isAttachPlanEligible(plansData, row.type),
      allowDetach: isDetachPlanEligible(plansData),
      flowyAction: (e: any, domain: any) =>
        launchFlowy(domain.dev_domain_id, domain.name),
      showFlowyAction: (domain: any) =>
        domain.type === 'developer' || domain.type === 'flowy',
    };

    return <ActionButtons actionButtonProp={buttonProps} row={row} />;
  };

  const actionFormatMap: FieldMap = {
    key: 'actions',
    size: {
      sm: 2,
      xs: 6,
      md: 3,
    },
    format: formatActionButton,
  };

  if (isMobile) {
    detailsMap[0].align = 'right';
  }

  const padding = isMobile ? '5px 15px' : '0 10px';

  return (
    <>
      <Box mt="10px" />
      {data.map((domain, idx) => {
        const detailsData: any[] = getDetailsData(domain);
        const pageNumber = currentPage + 1;
        const rowIndex = `${currentPage > 0 ? pageNumber : ''}${idx + 1}`;
        return (
          <React.Fragment key={`${domain.name}-main`}>
            {detailsData.length > 1 ? (
              <GridAccordionRow
                accordionMap={[...accordionMap, ...detailsMap, actionFormatMap]}
                rowData={{ ...detailsData[0], index: rowIndex }}
                variant="sub-heading"
                rowPadding={padding}
                key={`${detailsData[0].id}-sub-heading`}
              >
                {detailsData.toSpliced(0, 1).map((domainItem) => (
                  <React.Fragment key={`${domainItem.id}-${domainItem.name}`}>
                    <Divider />
                    <GridRow
                      disableborder
                      emptyRow={emptyRow}
                      rowData={domainItem}
                      rowMap={[...detailsMap, actionFormatMap]}
                      rowPadding={padding}
                      variant="sub-heading"
                    />
                  </React.Fragment>
                ))}
              </GridAccordionRow>
            ) : (
              <React.Fragment
                key={`${detailsData[0].id}-${detailsData[0].name}`}
              >
                <GridRow
                  rowData={{ ...detailsData[0], index: rowIndex }}
                  rowMap={[...accordionMap, ...detailsMap, actionFormatMap]}
                  variant="sub-heading"
                  rowPadding={padding}
                />
                <Box mb="10px" />
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
